import React, { useState } from "react";
import { useEffect } from "react";
import { apiCallGet } from "../../../api/RestAPICalls";
import { BASE_URL } from "../../../utils/variables";
import ErrorTroubleshooting from "./ErrorTroubleshooting";
import ProductSpecification from "./ProductSpecification";
import "./userguide.css";

const UserGuide = () => {
  const [isProuctSpecification, setIsProuctSpecification] = useState(false);
  const [userGuideData, setUserGuideData] = useState([]);
  const [state, setState] = useState({
    loading: true,
    error: "",
  });

  const fetchUserGuideData = () => {
    setState({ ...state, loading: true });
    apiCallGet(
      BASE_URL + "user-guide",
      (res) => {
        if (res?.error) {
          setState({
            ...state,
            loading: false,
            error: res.error,
          });
        } else {
          setUserGuideData(res);
          setState({ ...state, loading: false, error: "" });
        }
      },
      (err) => {
        setState({
          ...state,
          loading: false,
          error: "Oops, something went wrong!",
        });
      }
    );
  };

  useEffect(() => {
    fetchUserGuideData();
  }, []);

  return (
    <div
      style={{ height: "200px" }}
      className="w-full bg-ai-primary-text-teal relative"
    >
      <div className="py-4 flex gap-2 justify-center items-center">
        <button
          className={`py-2 px-4 border font-semibold rounded-full shadow-sm text-sm sm:text-lg ${
            isProuctSpecification
              ? "bg-white text-ai-primary-text-dark "
              : "text-white"
          }`}
          onClick={() => setIsProuctSpecification(true)}
        >
          Product Specification
        </button>{" "}
        <button
          onClick={() => setIsProuctSpecification(false)}
          className={`py-2 px-4 border font-semibold rounded-full shadow-sm  text-sm sm:text-lg ${
            !isProuctSpecification
              ? "bg-white text-ai-primary-text-dark"
              : "text-white"
          }`}
        >
          Error Troubleshooting
        </button>
      </div>

      <div className="bg-white shadow-md my-2 rounded-md h-screen mx-3 sm:mx-12 ">
        {isProuctSpecification ? (
          <ProductSpecification />
        ) : (
          <ErrorTroubleshooting
            userGuideData={userGuideData}
            loading={state.loading}
            error={state.error}
            fetchData={fetchUserGuideData}
          />
        )}
      </div>
    </div>
  );
};

export default UserGuide;
