import React, { useEffect, useState } from "react";

export default function SideoverComponent(props) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(true);
    }, []);

    const onClose = () => {
        //  console.log("Close");
        setOpen(!open);
        setTimeout(() => {
            props.close();
        }, 800);
    };

    return (
        <div
            className="z-50 -mt-2 fixed inset-0 overflow-hidden"
            aria-labelledby="slide-over-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="absolute inset-0 overflow-hidden">
                <div
                    onClick={onClose}
                    className={
                        open
                            ? "opacity-100 ease-in-out duration-500 absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                            : "ease-in-out opacity-0 duration-500 absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    }
                    aria-hidden="true"
                ></div>
                <div className="fixed inset-y-0 right-0 pl-10 max-w-3xl flex">
                    <div
                        className={
                            open
                                ? "translate-x-0 transform transition ease-in-out duration-500 sm:duration-700 relative w-screen max-w-2xl"
                                : "translate-x-full transform transition ease-in-out duration-500 sm:duration-700 relative w-screen max-w-2xl"
                        }
                    >
                        <div
                            className={
                                open
                                    ? "opacity-100 ease-in-out duration-500 absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                                    : "opacity-0 ease-in-out duration-500 absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                            }
                        >
                            <button
                                type="button"
                                onClick={onClose}
                                className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            >
                                <span className="sr-only">Close panel</span>
                                {/* <!-- Heroicon name: outline/x --> */}
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}
