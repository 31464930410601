import React, { Fragment, useState, useEffect } from "react";
import ToolTipText from "../../../components/Tooltips";

function DropdownSelect({
  label,
  name,
  value,
  values,
  onChange,
  isTooltip,
  ToolTipDescription,
  disabled
}) {
  const [selected, setSelected] = useState(
    value === undefined ? values[0] : value
  );
  useEffect(() => { }, []);
  return (
    <Fragment>
      <div className="flex">
        <label htmlFor={name} className="flex pb-1 text-sm font-semibold text-ai-primary-dark"
        //Was block text-sm font-medium text-gray-700
        >
          {label}
        </label>
        {isTooltip && <ToolTipText text={ToolTipDescription} />}
      </div>

      <select
        onChange={(e) => {
          setSelected(e.target.value);
          onChange(e.target.value);
        }}
        //onChange={onChange}
        value={selected}
        id={name}
        name={name}
        className={`shadow-sm  placeholder-ai-primary-dark placeholder-opacity-50 focus:border-green-400 focus:outline-none focus:ring-1 focus:ring-sa-secondary block w-full sm:text-sm border-gray-300 rounded-md ${disabled && `cursor-not-allowed disabled:opacity-75`} `}
        // className={
        //   selected === "InActive"
        //     ? "mt-1 block w-44 py-2 px-3 border border-red-300 bg-red-100 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
        //     : "mt-1 block w-44 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-200 focus:border-gray-200 sm:text-sm"
        // }
        disabled={disabled}
      >
        {values.map((value, k) => (
          <option value={value} key={k}>
            {value}
          </option>
        ))}
      </select>
    </Fragment>
  );
}

export default DropdownSelect;
