import { actionTypes } from "../store/types";

export const initial = {
  userName: null,
  token: null,
};

const userReducer = (state = initial, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTHDATA:
      return Object.assign({}, state, {
        token: action.payload.token,
        email: action.payload.email,
        name: action.payload.name,
        isLoggedIn: true,
      });

    case actionTypes.CLEAR_DATA:
      return Object.assign({}, state, {
        userName: null,
        isLoggedIn: false,
        token: null,
      });

    default:
      return Object.assign({}, state);
  }
};

export default userReducer;
