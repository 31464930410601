import React, { useState } from "react";

import DropdownSelect from "../../../../components/Inputs/Select";
import { useParams } from "react-router-dom";
import { FaCopy } from "react-icons/fa";

const CreateImport = () => {
  let { type } = useParams();
  const [inputs, setInputs] = useState({
    importName: "",
    frequency: "",
    connectionType: type,
    reportName: "",
    importDestination: "",
    attachmentType: "Attachment",
  });
  const [copyColor, setCopyColor] = useState(false);

  return (
    <div className="p-4" id="create">
      {type.toLowerCase() === "email" ? (
        // <!-- Email connection -->
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div>
                <h3 className="text-2xl leading-6 font-bold text-gray-900">
                  Create Email Import
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {/* Use a permanent address where you can receive mail. */}
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <div>
                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Import Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}

                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, frequency_type: e })
                        }
                        label="Frequency"
                        name="frequency_type"
                        value="-- Select --"
                        values={[
                          { label: "Monthly", value: "Monthly" },
                          { label: "Weekly", value: "Weekly" },
                          { label: "Daily", value: "Daily" },
                        ]}
                      />
                    </div>

                    {/*  */}
                    {inputs.frequency_type === "Monthly" && (
                      <div className="mt-1">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Frequency Month
                        </label>
                        <input
                          type="date"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    )}

                    {/*  */}
                    {inputs.frequency_type === "Weekly" && (
                      <div className="mt-1">
                        <DropdownSelect
                          onChange={(e) =>
                            setInputs({ ...inputs, frequency_week: e })
                          }
                          label="Select Day"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ]}
                        />
                      </div>
                    )}

                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Frequency Time
                      </label>
                      <div className="mt-1">
                        <input
                          type="time"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Mail Folder
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}

                    <div className="my-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Input Email Subject
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Input Attachment Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="my-2">
                    {/*  */}
                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, dataPushType: e })
                        }
                        label="Data Push Type"
                        name="dataPushType"
                        values={["-- Select --", "Replace", "Append"]}
                      />
                    </div>
                    {/*  */}
                    {inputs.dataPushType === "Append" && (
                      <div className="my-2">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Primary Key
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                    {/*  */}
                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, importDestination: e })
                        }
                        label="Import Destination"
                        name="importDestination"
                        values={["-- Select --", "BQ Table", "Gsheet"]}
                      />
                    </div>
                    {/*  */}
                    {inputs.importDestination === "BQ Table" && (
                      <div className="my-2">
                        <div>
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            BQ Table Path
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="mt-5">
                            <span className="ml-2 text-black text-sm">
                              Service account to give access -
                            </span>
                          </div>
                          <div className="flex">
                            <span className="ml-2 text-black text-sm font-bold">
                              ingestion-projects@databeat-internal.iam.gserviceaccount.com
                            </span>
                            <FaCopy
                              className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                              style={{ color: copyColor ? "blue" : "black" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                                );
                                setCopyColor(true);
                              }}
                            />
                            {copyColor && (
                              <span className="ml-2 text-blue-500 text-sm font-bold">
                                Copied!
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {inputs.importDestination === "Gsheet" && (
                      <div>
                        <div className="my-2">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Google Sheet URL
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Data push Tab Name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <span className="ml-2 text-black text-sm">
                            Service account to give access -
                          </span>
                        </div>
                        <div className="flex">
                          <span className="ml-2 text-black text-sm font-bold">
                            ingestion-projects@databeat-internal.iam.gserviceaccount.com
                          </span>
                          <FaCopy
                            className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                            style={{ color: copyColor ? "blue" : "black" }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                              );
                              setCopyColor(true);
                            }}
                          />
                          {copyColor && (
                            <span className="ml-2 text-blue-500 text-sm font-bold">
                              Copied!
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Rows to Skip (from Row 1)
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Rows to Skip (end of the report)
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}
                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, attachmentType: e })
                        }
                        label="Attachment Type"
                        name="attachmentType"
                        values={["-- Select --", "Attachment", "Link"]}
                      />
                    </div>
                    {/*  */}
                    {inputs.attachmentType === "Attachment" && <div></div>}
                    {/*  */}
                    {inputs.attachmentType === "Link" && (
                      <div>
                        <label className="inline-flex items-center mt-8">
                          <input
                            type="checkbox"
                            className="appearance-none checked:bg-blue-600 checked:border-transparent  "
                          />
                          <span className="ml-2 text-gray-700">
                            Zip File in link
                          </span>
                        </label>
                      </div>
                    )}

                    {/*  */}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      ) : (
        // <!-- GAM API connection -->

        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div>
                <h3 className="text-2xl leading-6 font-bold text-gray-900">
                  Create GAM API Import
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {/* Use a permanent address where you can receive mail. */}
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <div>
                    {/*  */}
                    <div className="my-2">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Import Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    {/*  */}

                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, frequency_type: e })
                        }
                        label="Frequency"
                        name="frequency_type"
                        value="-- Select --"
                        values={[
                          { label: "Monthly", value: "Monthly" },
                          { label: "Weekly", value: "Weekly" },
                          { label: "Daily", value: "Daily" },
                        ]}
                      />
                    </div>

                    {/*  */}
                    {inputs.frequency_type === "Monthly" && (
                      <div className="mt-1">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Frequency Month
                        </label>
                        <input
                          type="date"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    )}

                    {/*  */}
                    {inputs.frequency_type === "Weekly" && (
                      <div className="mt-1">
                        <DropdownSelect
                          onChange={(e) =>
                            setInputs({ ...inputs, frequency_week: e })
                          }
                          label="Select Day"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ]}
                        />
                      </div>
                    )}
                    {inputs.frequency_type !== "Hourly" && (
                      <div className="my-2">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Frequency Time
                        </label>
                        <div className="mt-1">
                          <input
                            type="time"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}

                    {/*  */}
                  </div>
                </div>

                <div className="mt-1">
                  <DropdownSelect
                    onChange={(e) =>
                      setInputs({ ...inputs, frequency_week: e })
                    }
                    label="Select Data Date Range"
                    name="frequency_week"
                    values={[
                      "-- Select --",
                      "Today",
                      "Yesterday",
                      "Last-3 Days",
                      "Last-7 Days",
                    ]}
                  />
                </div>

                <div className="sm:col-span-3">
                  <div className="my-2">
                    {/*  */}
                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, dataPushType: e })
                        }
                        label="Data Processing Type/ Data Import Type"
                        name="dataPushType"
                        values={["-- Select --", "Replace", "Append"]}
                      />
                    </div>
                    {/*  */}
                    {inputs.dataPushType === "Append" && (
                      <div className="my-2">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Overlapping column name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}

                    {/*  */}
                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, importDestination: e })
                        }
                        label="Import Destination"
                        name="importDestination"
                        values={["-- Select --", "BQ Table", "Gsheet"]}
                      />
                    </div>
                    {/*  */}
                    {inputs.importDestination === "BQ Table" && (
                      <div>
                        <div className="my-2">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            BQ Table Path
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="mt-5">
                            <span className="ml-2 text-black text-sm">
                              Service account to give access -
                            </span>
                          </div>
                          <div className="flex">
                            <span className="ml-2 text-black text-sm font-bold">
                              ingestion-projects@databeat-internal.iam.gserviceaccount.com
                            </span>
                            <FaCopy
                              className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                              style={{ color: copyColor ? "blue" : "black" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                                );
                                setCopyColor(true);
                              }}
                            />
                            {copyColor && (
                              <span className="ml-2 text-blue-500 text-sm font-bold">
                                Copied!
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/*  */}
                    {inputs.importDestination === "Gsheet" && (
                      <div>
                        <div className="my-2">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Google Sheet URL
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Data push Tab Name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="my-2">
                      <DropdownSelect
                        onChange={(e) =>
                          setInputs({ ...inputs, reportName: e })
                        }
                        label="Report Name"
                        name="reportName"
                        values={[
                          "-- Select --",
                          "ReportName-1",
                          "ReportName-2",
                          "ReportName-3",
                        ]}
                      />
                    </div>

                    {/*  */}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateImport;
