import React, { useState, useRef, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

const ImportDataCard = ({ k, usecase, actions, onUploadFile }) => {
  /* { actions, usecase, k })*/
  // console.log("Actions", actions);
  // console.log("k", k)
  // console.log("usecase",usecase)
  // console.log("usecase", usecase)
  // console.log("k===>",k)


  const [state, setState] = useState({});
  const _renderActionItem = (usecase, k) => {
    const _handleActionClick = (action) => {
      if (action.label === "Upload a File") {
        const fileInput = document.getElementById(`file_upload_${k}`);
        fileInput.click();
      }
      else if (action.label === "Activate" || action.label === "De-Activate") {
        const status = usecase.Status.value === "Active" ? false : true;
        action.action(status, usecase.Import_id.value)
        // console.log("actionusecase", usecase);
      }
      else {
        action.action(usecase, k)
      }
    }
    return (
      <div className="absolute z-50 top-15 right-0 mr-6 w-32 bg-white border rounded shadow-lg">
        <div className="flex flex-col">
          {actions.map((action, k) => (
            <button
              key={k}
              className={`p-2 cursor-pointer text-right hover:text-green-400`}
              onClick={() => {
                _handleActionClick(action)
              }}
              title={
                usecase["connection_name"]?.value === "GAM" &&
                  action.label === "Issue Password"
                  ? "GAM doesn't have a Password"
                  : ""
              }
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        key={k}
        className={`relative shadow rounded-lg overflow-hidden ${usecase.Status.value === "Active" ? "bg-white" : "bg-[#f9fafb]"}`}
      >
        <div
          className={` font-semibold py-1 px-4 text-center rounded-br-2xl rounded-tl-md max-w-max ${usecase["Status"].value === "InActive"
            ? "bg-[#D8DADC]"
            : "bg-[#cdf5f4]"

            }  `}
        >
          <label
            for={""}
            className="text-sm font-semibold px-2 text-ai-primary-text-dark"
          >
            {usecase["Frequency"]?.value}
          </label>
        </div>

        <button
          onClick={() => {
            setState({
              ...state,
              dropdown: !state.dropdown,
              selected: usecase.id?.value,
            });
          }}
          onBlur={() => {
            setTimeout(() => {
              if (state.dropdown && state.selected === usecase.id?.value) {
                setState({
                  ...state,
                  dropdown: false,
                  selected: null,
                });
              }
            }, 300);
          }}
          className="text-gray-600 cursor-pointer absolute right-0 top-2 font-semibold pb-1 px-4"
        >
          {state.dropdown &&
            state.selected === usecase.id?.value &&
            _renderActionItem(usecase, k)}{" "}
          <BsThreeDotsVertical className="w-6 h-6 text-gray-600 cursor-pointer z-0" />
        </button>

        <div className={`relative  py-4 px-4 sm:px-6 overflow-hidden `}>
          <input
            name={"file_upload_" + k}
            id={"file_upload_" + k}
            onChange={(e) => {
              // console.log("Printing file here===>");
              // console.log("File", e.target.files[0]);
              onUploadFile(e, usecase);
            }}
            type="file"
            accept=".json"
            className="sr-only"
            title="Upload a File"
          />
          <div className="flex justify-between">
            {/* whitespace-nowrap */}
            <p
              className="text-xl font-bold text-ai-primary-text-dark"
              title={usecase["Import Name"]?.value}
            >
              {usecase["Import Name"]?.value}
            </p>
          </div>
          <div class="flex flex-wrap mt-2 overflow-hidden rounded-xl border-2 font-bold border-sa-secondary items-center">

            <div class="flex-1 my-1 h-12 flex justify-center items-center">
              <p class="text-lg text-ai-primary-text-dark flex justify-center items-center font-bold"
              >
                {usecase["Time"].value}
              </p>
            </div>
            <div class="flex-1 h-12 ">
              <p class="mt-3 mb-2 text-sm text-ai-primary-text-dark opacity-80 font-semibold">

                Time
              </p>
            </div>
            <div class="flex-1 border-l-2 my-1 border-slate-400 h-12 flex justify-center items-center">
              <p class="text-lg text-ai-primary-text-dark flex justify-center items-center font-bold">
                {usecase["Latest Run Status"].value}
              </p>
            </div>

            <div class="flex-1 h-12">
              <p class="mt-3 mb-2 overflow-text-sm text-ai-primary-text-dark opacity-80 font-semibold ">
                Latest Run
              </p>
            </div>
          </div>

          <div className="flex flex-wrap my-2 ">
            <div className="flex-1 ">
              <p className="mt-2 text-sm text-ai-primary-text-dark opacity-80 font-semibold ">
                Latest Runtime
              </p>
              <span className=" text-sm font-bold text-ai-primary-text-dark">
                {usecase["Latest Run Time"].value}
              </span>
            </div>
            <div className="flex-1">
              {usecase["Frequency"]?.value === "Monthly" &&
                <div className="flex-1">
                  <p className="mt-2 text-sm text-ai-primary-text-dark opacity-80 font-semibold ">
                    Date
                  </p>
                  <span className="text-sm font-bold text-ai-primary-text-dark">
                    {usecase["Day"].value}
                  </span>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportDataCard;
