import React, { Fragment, useEffect, useState } from "react";
import { IoIosArrowRoundUp } from "react-icons/io";
import { HiFilter } from "react-icons/hi";
import { DateRangePicker } from "rsuite";

import moment from "moment";

export default function FilterTable(props) {
  const [data, setData] = useState(props.data);
  const [columns, setColumns] = useState(props.columns);

  const [state, setState] = useState({
    loading: false,
    selectedColumn: props.columns[0],
    ascending: true,
    page: 0,
    totalPages: [],
    filterBy: "Select",
    filterValue_importName: ["All"],
    defaultImportNames: [
      ...new Set(props.data.map((item) => item["Import Name"].value)),
    ],
    filterValue_status: ["All"],
    filterValue_date: "",
    raw_data: props.data,
    dateRange: ["", ""],
  });

  useEffect(() => {
    sortBy();
  }, []);

  const sortBy = (col) => {
    // setState({ ...state, loading: true });
    let column = col ? col : state.selectedColumn,
      ascending = state.ascending,
      newData = data;

    if (state.selectedColumn === col) {
      ascending = !ascending;
    }

    if (ascending) {
      newData.sort((a, b) =>
        a[column?.value]?.value > b[column?.value]?.value ? 1 : -1
      );
    } else {
      newData.sort((a, b) =>
        a[column?.value]?.value < b[column?.value]?.value ? 1 : -1
      );
    }
    setData(newData);
    let impNames = props.data.map((item) => item["Import Name"].value);

    setState({
      ...state,
      ascending,
      loading: false,
      selectedColumn: col,
    });
  };

  const filterByType = () => {
    let new_data = state.raw_data.filter((item) => {
      return (
        (state.filterValue_importName.includes("All")
          ? true
          : state.filterValue_importName.includes(item["Import Name"].value)) &&
        (state.filterValue_status.includes("All")
          ? true
          : state.filterValue_status.includes(item["Run Status"].value)) &&
        (state.dateRange === null ||
          (state.dateRange[0] === "" && state.dateRange[1] === "")
          ? true
          : item["End Time"].value >=
          moment(state.dateRange[0]).format("YYYY-MM-DD") &&
          item["End Time"].value <=
          moment(state.dateRange[1]).format("YYYY-MM-DD"))
      );
    });
    setData(new_data);
  };

  return (
    <Fragment>
      <div className="flex justify-between mb-6">
        <div className="text-2xl font-bold mt-auto mb-2">Data Import Logs </div>
        <div className="p-4 pr-1 pb-1 flex justify-end">
          {/* <p className="text-xl items-center flex">Filter by</p> */}

          <DateRangePicker
            placeholder="Filter by Date Range"
            className="pr-2 "
            style={{ width: 270, borderColor: "black", color: "black" }}
            size="lg"
            placement="auto"
            ranges={[]}
            onChange={(e) => {
              if (e) {
                setState({
                  ...state,
                  dateRange: [e[0], e[1]],
                });
              } else {
                setState({
                  ...state,
                  dateRange: ["", ""],
                });
              }
            }}
            value={state.dateRange}
          />

          <MultiSelectCheckbox
            label={"Filter by Import Name"}
            options={state.defaultImportNames}
            onSelectChange={(e) => {
              setState({
                ...state,
                filterby_status: "Import Name",
                filterValue_importName: e,
              });
            }}
          />

          <MultiSelectCheckbox
            label={"Filter by Status"}
            options={["Success", "Failure"]}
            onSelectChange={(e) => {
              setState({
                ...state,
                filterby_status: "Run Status",
                filterValue_status: e,
              });
            }}
          />

          <button
            className=" text-white bg-black font-semibold py-3 px-3 ml-3 rounded mb-auto"
            onClick={() => filterByType()}
          >
            <HiFilter className="text-lg my-auto" />
          </button>
        </div>{" "}
      </div>
      <div
        className="overflow-x-auto shadow-xl"
        style={{ minHeight: "200px", maxHeight: "550px" }}
      >
        <div className="align-middle inline-block min-w-full">
          <div className="align-middle inline-block min-w-full">
            {state.loading ? (
              <div className="p-2">Loading...</div>
            ) : (
              <table
                style={{
                  backgroundColor: props.tableCSS?.headerColor,
                  color: props.tableCSS?.color,
                }}
                // className="w-full divide-y divide-gray-200 sticky top-0"
                className=" sticky   min-w-full divide-y divide-gray-200"
              >
                <thead
                  style={{
                    backgroundColor: props.tableCSS?.headerColor,
                    color: props.tableCSS?.color,
                  }}
                  className="sticky top-0 mx-auto"
                >
                  <tr className="sticky top-0 mx-auto">
                    {columns.map((column, k) => (
                      <th
                        key={k}
                        scope="col"
                        className="sticky ml-3 top-0 px-4 py-3 text-left text-sm font-semibold text-ai-primary-text-dark capitalize tracking-wider whitespace-nowrap"
                      >
                        <span
                          className={
                            column.align === "left"
                              ? "flex justify-start cursor-pointer"
                              : "flex justify-end cursor-pointer"
                          }
                          onClick={() => sortBy(column)}
                        >
                          {column.value}
                          {column === state.selectedColumn && (
                            <IoIosArrowRoundUp
                              className={
                                state.ascending
                                  ? "text-lg transform duration-200 ml-2"
                                  : "text-lg transform rotate-180 duration-200 ml-2"
                              }
                            />
                          )}
                        </span>
                      </th>
                    ))}

                    {props.actions.length > 0 && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-xs text-black font-bold uppercase tracking-wider"
                      >
                        Actions
                        <span className="sr-only">Edit</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.slice(
                    props.paginationLimit
                      ? props.paginationLimit * state.page
                      : 0,
                    props.paginationLimit
                      ? props.paginationLimit * (state.page + 1)
                      : data.length
                  ).length === 0 && (
                      <tr>
                        <td
                          colSpan={8}
                          className={
                            "px-4 mx-3 max-w-sm overflow-hidden py-4 text-center whitespace-nowrap text-sm font-semibold text-black"
                          }
                        >
                          <div>No logs available</div>
                        </td>
                      </tr>
                    )}
                  {data
                    .slice(
                      props.paginationLimit
                        ? props.paginationLimit * state.page
                        : 0,
                      props.paginationLimit
                        ? props.paginationLimit * (state.page + 1)
                        : data.length
                    )
                    .map((row, k) => (
                      <tr key={k}>
                        {columns.map((column, l) => (
                          <td
                            key={l}
                            className={
                              row[column.value].align === "left"
                                ? "px-4 mx-3 max-w-sm overflow-hidden py-4 text-left whitespace-nowrap text-sm font-semibold text-black"
                                : "px-4 mx-3 max-w-sm overflow-hidden py-4 text-right whitespace-nowrap text-sm font-semibold text-black"
                            }
                            title={row[column.value].value}
                          >
                            {row[column.value].type === "text"
                              ? row[column.value].value
                              : new Intl.NumberFormat().format(
                                row[column.value].value
                              )}
                          </td>
                        ))}

                        {props.actions.length > 0 && (
                          <td className="px-6 flex py-4 whitespace-nowrap justify-center text-sm font-semibold">
                            {props.actions.map((action) => (
                              <div
                                onClick={() => action.action(row, k)}
                                className={
                                  "p-1 text-md rounded-md ml-3 cursor-pointer " +
                                  action.style
                                }
                              >
                                {action.label}
                              </div>
                            ))}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
          </div>
        </div>
      </div>
      {props.paginationLimit && (
        <div class="bg-white pl-4 py-3 flex items-center justify-between border-t border-gray-200">
          <div class="flex-1 flex justify-between sm:hidden">
            <a
              href="#next"
              class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </a>
            <a
              href="#next"
              class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </a>
          </div>
          <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">
                  {" "}
                  {state.page * props.paginationLimit}{" "}
                </span>
                to{" "}
                <span class="font-medium">
                  {" "}
                  {(state.page + 1) * props.paginationLimit}{" "}
                </span>
                of <span class="font-medium">{data.length} </span>
                results
              </p>
            </div>
            <div>
              <nav
                class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <a
                  href="#next"
                  onClick={() => {
                    if (state.page > 0)
                      setState({ ...state, page: state.page - 1 });
                  }}
                  class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span class="sr-only">Previous</span>
                  {/* <!-- Heroicon name: solid/chevron-left --> */}
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                {/* <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" --> */}

                <a
                  href="#next"
                  onClick={() => {
                    if (state.page < data.length / props.paginationLimit)
                      setState({ ...state, page: state.page + 1 });
                  }}
                  class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span class="sr-only">Next</span>
                  {/* <!-- Heroicon name: solid/chevron-right --> */}
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </nav>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const MultiSelectCheckbox = (props) => {
  let default_options = ["All", ...props.options];
  const [showOptions, setShowOptions] = useState(false);
  const [searched, setSearched] = useState("");
  const [options, setOptions] = useState([...default_options]);
  const [selectedOptions, setSelectedOptions] = useState(default_options);

  const onSearchChange = (e) => {
    if (e.target.value === "") {
      setOptions(default_options);
    }
    let newOptions = default_options.filter((item) =>
      item.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setOptions(newOptions);
    setSearched(e.target.value);
  };

  const onSelectedOne = (name, val) => {
    let newOptions;
    if (name === "All") {
      if (val) {
        newOptions = [...default_options];
        setSelectedOptions([...default_options]);
      } else {
        newOptions = [];
        setSelectedOptions([]);
      }
    } else {
      if (val === true) {
        newOptions = [...selectedOptions, name];
      } else {
        newOptions = selectedOptions.filter((item) => item !== name);
        if (newOptions.includes("All")) {
          newOptions = newOptions.filter((item) => item !== "All");
        }
      }
      setSelectedOptions(newOptions);
    }
    props.onSelectChange(newOptions);
  };

  return (
    <div class="relative inline-block text-left z-1 mr-2 ">
      {showOptions && (
        <div
          onClick={() => setShowOptions(false)}
          style={{ zIndex: 40, height: "100vh", width: "100vw" }}
          className="opacity-25 fixed top-0 left-0 duration-150"
        ></div>
      )}
      <div>
        <button
          onClick={() => setShowOptions(!showOptions)}
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-ai-primary-teal focus:ring-ai-primary-teal"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <input
            type="text"
            placeholder={props.label}
            value={searched}
            onChange={onSearchChange}
            className="text-left no-outline sticky top-0 w-full px-1 border-none focus:outline-none focus-within:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-gray-100 focus:ring-teal-500 rounded-md"
          />
          {/* <!-- Heroicon name: solid/chevron-down --> */}
          <svg
            class="-mr-1 ml-2 my-auto h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      {showOptions && (
        <div
          class={
            showOptions
              ? "transform z-50 opacity-100 scale-100 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              : "transform z-50 opacity-0 scale-95 transition ease-out duration-100 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          }
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div
            class="py-1 mx-1 overflow-y-scroll h-80 overflow-x-hidden"
            role="none"
          >
            <span className="">
              {options.map((option, k) => (
                <label
                  key={k}
                  htmlFor={option}
                  className="text-gray-700 flex px-2 py-2 cursor-pointer hover:bg-gray-100 duration-100 text-sm"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  title={option}
                >
                  <span className="mr-2">
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      onChange={(e) => {
                        onSelectedOne(e.target.name, e.target.checked);
                      }}
                      checked={selectedOptions.includes(option)}
                      id={option}
                      name={option}
                      className="rounded-sm text-ai-primary-checkbox cursor-pointer outline-none ring-0 focus:ring-0"
                    />
                  </span>{" "}
                  <label htmlFor={option} className="my-auto whitespace-nowrap">
                    {" "}
                    {option}
                  </label>
                </label>
              ))}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
