import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { apiCallPost } from "../../api/RestAPICalls";
import { BASE_URL } from "../../utils/variables";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DropdownSelect from "../../components/Inputs/Select";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import Input from "../../components/Inputs/Input";

const ForgotPasswordPage = () => {
  const [state, setState] = useState({
    email: "",
    otp_sent: false,
    isadmin: false,
  });
  const [loading, setLoading] = useState(false);

  const [toast, setTaost] = useState({
    type: false,
    message: "",
  });

  const data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Ingestions",
        data: [12, 19, 15, 10, 12, 8],
        backgroundColor: "rgb(137,190,75)",
      },
      {
        label: "# of Failed Ingestions",
        data: [2, 3, 11, 5, 1, 4],
        backgroundColor: "white",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          maxBarThickness: 50,
        },
      ],
      xAxes: [
        {
          stacked: true,
          maxBarThickness: 50,
        },
      ],
    },
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const _loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    let loginUrl = BASE_URL + "forgot-password";
    let bodyParams = {
      type: state.otp_sent ? "otp_check" : "otp_generate",
      email: state.email,
      otp: state.otp_sent ? state.otp_sent : "",
    };
    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyParams),
      });
      const data = await response.json();
      // console.log(data);
      if (data.error) {
        setTaost({ type: true, message: data.error });
      } else {
        if (state.otp_sent) {
          setTaost({ type: false, message: data.response });
          setTimeout(() => {
            window.location.replace("/");
          }, 3000);
        } else {
          setTaost({ type: false, message: data.response });
        }
      }
      setState({ ...state, otp_sent: "" });
      setLoading(false);
    } catch (error) {
      setState({ ...state, otp_sent: false, error: error.error });
      setTaost({
        type: true,
        message: error.error,
      });
      setLoading(false);
    }
  };

  if (state.isLoggedIn && state.isadmin) {
    return <Redirect to="/dashboard/super" />;
  } else if (state.isLoggedIn && !state.isadmin) {
    return <Redirect to="/dashboard" />;
  } else {
  }

  return (
    <div className="min-h-screen flex flex-col justify-center bg-ai-primary-dark ">
      {/*Was bg-main-800*/}
      <div className=" max-w-full mx-auto">
        <div className="rounded-lg bg-main-800 overflow-hidden shadow divide-y divide-main-800 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none flex flex-col justify-center relative group bg-main-800 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
            <div>
              <span>
                {/* <!-- Heroicon name: outline/clock --> */}
                <img
                  src="https://databeat.io/wp-content/uploads/2021/06/databeatlogo_transprant_new.png"
                  alt="logo"
                />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium text-white">
                {/* <!-- Extend touch target to entire panel --> */}
                <span className="absolute inset-0" aria-hidden="true"></span>
              </h3>
              <p className="mt-2 text-sm text-gray-100"></p>
            </div>
            <div className="grid grid-cols-4">
              <div className="col-span-3">
                <Bar data={data} options={options} />
              </div>
              <div className="col-span-1"></div>
            </div>
          </div>

          {/* ----------------------------- sign in section (right)------------------------------------- */}

          <div className=" ">
            <div className="min-h-screen bg-main-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <p className="text-white text-3xl mb-6">
                  Welcome to DataBeat Ingestion{" "}
                </p>

                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <p className="text-black text-2xl font-bold mx-auto text-center mb-2">
                    Forgot Password{" "}
                  </p>

                  <form
                    className="space-y-6"
                    onSubmit={(e) => {
                      _loginUser(e);
                    }}
                  >
                    <div>
                      <label
                        htmlhtmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={state.email}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>

                    {state.otp_sent !== false && (
                      <div>
                        <label
                          htmlhtmlFor="opt"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Enter Passcode
                        </label>
                        <div className="mt-1">
                          <Input
                            id="opt"
                            name="otp_sent"
                            type="number"
                            required
                            value={state.otp_sent}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* ------------------------Error Msg------------------ */}
                    {toast.message && (
                      <ToastMsg
                        msg={toast.message}
                        isError={toast.type}
                        setToast={setTaost}
                      />
                    )}

                    {/* ------------------------Error Msg------------------ */}

                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black  hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        style={{ backgroundColor: "#6bd6d4" }}
                      >
                        {loading ? (
                          <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                            <AiOutlineLoading3Quarters
                              className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                              size={40}
                            />
                          </div>
                        ) : state.otp_sent !== false ? (
                          "Submit"
                        ) : (
                          "Generate Passcode"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
