import { React, useState } from "react";

// const MultiSelectChips = ({ tenants, onTenantsSelected }) => {
//     const [selectedTenants, setSelectedTenants] = useState([]);

//     const handleTenantClick = (tenantId) => {
//         const index = selectedTenants.indexOf(tenantId);
//         if (index === -1) {
//             setSelectedTenants([...selectedTenants, tenantId]);
//         } else {
//             const updatedTenants = [...selectedTenants];
//             updatedTenants.splice(index, 1);
//             setSelectedTenants(updatedTenants);
//         }
//     };

//     return (
//         <div className="tenant-chips-box">
//             {tenants.map((tenant) => (
//                 <div
//                     key={tenant.tenant_id}
//                     className={`tenant-chip ${selectedTenants.includes(tenant.tenant_id) ? 'selected' : ''}`}
//                     onClick={() => handleTenantClick(tenant.tenant_id)}
//                 >
//                     {tenant.tenant_name}
//                 </div>
//             ))}
//             <button onClick={() => onTenantsSelected(selectedTenants)}>Save</button>
//         </div>
//     );
// }

const MultiSelectChips = ({
  label,
  onChange,
  name,
  values,
  value,
  placeholder,
}) => {
  const [items, setItems] = useState(values);

  const [selectedItems, setSelectedItems] = useState(value ? value : []);

  const handleItemClick = (itemValue) => {
    if (selectedItems.includes(itemValue)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemValue));
      onChange(selectedItems.filter((item) => item !== itemValue));
    } else {
      setSelectedItems([...selectedItems, itemValue]);
      onChange([...selectedItems, itemValue]);
    }
  };

  return (
    <div className="autoComplete-wrapper">
      <div className="autoComplete">
        <div className="w-full flex flex-col items-center mx-auto">
          <div className="w-full">
            <div className="flex flex-col items-center relative">
              <div className="w-full">
                <label
                  htmlFor={name}
                  className="flex text-sm font-semibold text-gray-700"
                >
                  {label}
                </label>
                <div className="my-2 p-1 flex border border-gray-200 bg-white rounded">
                  <div className="flex flex-wrap">
                    {items.map((item, index) => (
                      <div className="px-1 py-1">
                        <span
                          key={index}
                          //   className={`inline-block py-1 px-2 rounded-full bg-gray-100 hover:bg-gray-200 hover:cursor-pointer ${
                          className={`inline-block py-1 px-2 rounded-full  border-2 border-sa-secondary hover:bg-gray-200 hover:cursor-pointer ${
                            selectedItems.includes(item.value)
                              ? "bg-ai-primary-teal text-white"
                              : ""
                          }`}
                          onClick={() => handleItemClick(item.value)}
                        >
                          {item.value}
                          {/* {console.log("Item", items)} */}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectChips;
