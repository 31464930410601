import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { apiCallPost } from "../../../../api/RestAPICalls";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL } from "../../../../utils/variables";
import { IoIosCloseCircle } from "react-icons/io";
const ModalItemTenant = ({ modalRefTenant }) => {
  const [inputs, setInputs] = useState({
    newTenantName: "",
  });

  const [error, setError] = useState({
    newTenantNameError: "",
  });

  const [state, setState] = useState({
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const _createTenant = () => {
    // api call for Create new tenant
    if (inputs.newTenantName.trim()) {
      setState({ success: false, error: false, loading: true });
      let createTenantUrl = BASE_URL + "tenants/create";
      let bodyParams = {
        tenant_name: inputs.newTenantName,
        tenant_email: "databeat@databeat.io",
        tenant_password: "null",
      };
      apiCallPost(
        createTenantUrl,
        bodyParams,
        _onSuccessCreateTenant,
        _onFailureCreateTenant
      );
    } else {
      setError((revError) => {
        return {
          ...revError,
          newTenantNameError: "New Tenant name is required",
        };
      });
    }
  };
  const _onSuccessCreateTenant = async (responseJson) => {
    setState({ ...state, loading: false });
    setToast({ type: false, message: "Tenant Created successfully." });
    modalRefTenant.current.closeModal();
    window.location.reload(true);
  };

  const _onFailureCreateTenant = async (responseJson) => {
    setState({ ...state, loading: false });
    setToast({ type: true, message: "Something went wrong." });
  };
  const handleChangeNewTenant = (e) => {
    setInputs({ ...inputs, newTenantName: e.target.value });
    setError({ newTenantNameError: "" });
  };
  return (
    <Modal ref={modalRefTenant}>
      {/* <div className="z-50 p-4 sm:p-6">
        <div className="">
          <h3
            className="text-lg leading-6 font-bold text-gray-900"
            id="modal-title"
          >
            Create New Tenant
          </h3>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="mt-5 md:mt-0 md:col-span-3">
                <div>
                  <div className="overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <>
                          <div className="col-span-6 sm:col-span-6">
                            <label
                              htmlFor="tenantname"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Tenant Name
                            </label>
                            <Input
                              type="text"
                              name="tenantname"
                              id="tenantname"
                              onChange={handleChangeNewTenant}
                              value={inputs.newTenantName}
                            />
                            <span className="text-red-600">
                              {error.newTenantNameError}
                            </span>
                          </div>
                          {toast.message && (
                            <ToastMsg
                              msg={toast.message}
                              isError={toast.type}
                              setToast={setToast}
                            />
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-ai-primary-teal-btn  hover:bg-ai-primary-teal-btn-hover hover:bg text-base font-medium text-white  sm:col-start-2 sm:text-sm"
            onClick={() => {
              _createTenant();
            }}
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Create"
            )}
          </button>
          <button
            type="button"
            onClick={() => {
              modalRefTenant.current.closeModal();
              setError({
                newTenantNameError: "",
              });
            }}
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          >
            Close
          </button>
        </div>
      </div> */}
      <div>
        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Create New Tenant
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRefTenant.current.closeModal();
              setError({
                newTenantNameError: "",
              });
            }}
          />
        </div>
        <div className="p-4 ">
          <Input
            label="Tenant Name"
            type="text"
            name="tenantname"
            id="tenantname"
            onChange={handleChangeNewTenant}
            value={inputs.newTenantName}
            error={error.newTenantNameError}
          />
          {toast.message && (
            <ToastMsg
              msg={toast.message}
              isError={toast.type}
              setToast={setToast}
            />
          )}
          <button
            className=" max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none"
            onClick={_createTenant}
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalItemTenant;
