import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Doughnut } from "react-chartjs-2";
import OverAllTenantSelection from "../../components/OverAllTenantSelection/index";
import { BASE_URL } from "../../utils/variables";
import moment from "moment";
import { DataConvert } from "../../utils/converters";
import SimpleTable from "../../components/Table/simple";
import FilterTable from "../../components/Table/FilterTable";
import ImportLogs from "../../components/ImportLogs";

export default function ProductsSuperAdmin() {
  const [data, setData] = useState({
    loading: true,
    headers: [
      { align: "left", value: "Client Name" },
      { align: "left", value: "# of Connections" },
      { align: "left", value: "# Active Imports" },
      { align: "left", value: "Latest run failed imports" },
      { align: "left", value: "Rows processed (24 hrs)" },
      { align: "left", value: "Data Processed (Last 24 hrs)" },
    ],
    auditLogs: [],
    all_users: [],
    pieChart_EmailCount: 0,
    pieChart_GAMCount: 0,
    pieChart_TAMCount: 0,
    destination_chart: [0, 0],
  });
  //console.log(pieChartData)
  const pieChartData = {
    labels: ["EMAIL", "GAM", "TAM"],
    datasets: [
      {
        label: "Imports by Connection Type",
        data: [data.pieChart_EmailCount, data.pieChart_GAMCount, data.pieChart_TAMCount],
        backgroundColor: ["rgb(54, 162, 235)", "rgb(255, 99, 132)", "rgb(151,245,138)"],
        hoverOffset: 4,
      },
    ],
  };

  const DestinationPieChartData = {
    labels: ["Big Query", "Google Sheet"],
    datasets: [
      {
        label: "Imports by Output Destination Type",
        data: [data.destination_chart[1], data.destination_chart[0]],
        backgroundColor: ["rgb(54, 162, 235)", "rgb(255, 99, 132)"],
        hoverOffset: 4,
      },
    ],
  };

  const TENANT_ID = !localStorage.getItem("selected_tenant_id")
    ? 0
    : parseInt(localStorage.getItem("selected_tenant_id"));

  const fetchProductDashBoardData = async () => {
    try {
      const response = await fetch(`${BASE_URL}super/product/${TENANT_ID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const res = await response.json();

      // console.log("---------------", res);

      let gsheet = 0,
        big_query = 0,
        active_imports = {};
      let newAuditLogs = [],
        repeated_logs = {};

      // to get the entries sorted by end time
      res.audit_logs = res.audit_logs.sort((a, b) =>
        a.end_time > b.end_time ? 1 : -1
      );

      for (let x in res.all_imports) {
        let tenant_name =
          res.all_imports[x].tenant || res.all_imports[x].tenant_name;

        if (active_imports[tenant_name]) {
          if (res.all_imports[x].status === "Active") {
            active_imports[tenant_name].active_imports =
              active_imports[tenant_name].active_imports + 1;
          }
        } else {
          if (res.all_imports[x].status === "Active") {
            active_imports[tenant_name] = {
              active_imports: 1,
              failed_imports: 0,
              rows_written: 0,
              data_processed: 0,
            };
          }
        }
      }

      for (let x in res.audit_logs) {
        let this_log = res.audit_logs[x];
        let tenant_name = res.audit_logs[x].tenant_name;
        let newLog = {
          "Import Name": {
            type: "text",
            align: "left",
            id: "import_name",
            value: this_log.program_name,
          },
          Date: {
            type: "text",
            align: "left",
            id: "Date",
            value: this_log.end_time.slice(0, 19),
          },
          Frequency: {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.imports_data
              ? this_log.imports_data.frequency
              : "-",
          },
          "Date/Day/Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: "-",
          },
          "Time of Run": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.imports_data
              ? this_log.imports_data.time_of_run
              : "-",
          },
          "Run Status": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.status,
          },
          Error: {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.error,
          },
          "Rows Processed": {
            type: "number",
            align: "left",
            id: "Frequency",
            value: parseInt(this_log.rows_written),
          },
          "Data Processed": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.size
              ? `${parseFloat(this_log.size).toFixed(2)} MB`
              : "0",
          },
          "Start Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.start_time,
          },
          "End Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.end_time,
          },
          "Run Time": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.run_duration.slice(0, 7),
          },
          "Connection Name": {
            type: "text",
            align: "left",
            id: "Frequency",
            value: this_log.connection_type.toUpperCase(),
          },
        };

        // take email import
        if (this_log.connection_type.toLowerCase() === "email") {
          // check if that email import exists in repeated variable

          if (repeated_logs[this_log.import_id]) {
            if (repeated_logs[this_log.import_id].includes(this_log.time)) {
              newAuditLogs.push(newLog);
              repeated_logs[this_log.import_id].push(this_log.date);
            } else {
            }
          } else {
            repeated_logs[this_log.import_id] = [];
            repeated_logs[this_log.import_id].push(this_log.date);
            newAuditLogs.push(newLog);
          }
        } else {
          newAuditLogs.push(newLog);
        }
        if (moment().diff(moment(this_log.date), "days") < 2) {
          if (active_imports[tenant_name]) {
            active_imports[tenant_name].rows_written += parseFloat(
              this_log.rows_written
            );
            active_imports[tenant_name].data_processed += parseFloat(
              this_log.size
            );
          } else {
            active_imports[tenant_name] = {
              active_imports: 1,
              failed_imports: 0,
              rows_written: parseFloat(this_log.rows_written),
              data_processed: parseFloat(this_log.size),
            };
          }
        }
      }

      let gamCount = 0;
      let emailCount = 0;
      let tamCount = 0;
      for (let h in res.all_connections) {
        if (res.all_connections[h].connection_type.toUpperCase() === "EMAIL") {
          emailCount = emailCount + 1;
        } else if (
          res.all_connections[h].connection_type.toUpperCase() === "GAM"
        ) {
          gamCount = gamCount + 1;
        } else if (res.all_connections[h].connection_type.toUpperCase() === "TAM") {
          tamCount = tamCount + 1;
        }
      }

      for (let h in res.all_imports) {
        if (res.all_imports[h].output_file_type.toLowerCase() === "bq table") {
          big_query += 1;
        } else if (
          res.all_imports[h].output_file_type.toLowerCase() === "gsheet"
        ) {
          gsheet += 1;
        }
      }

      for (let h in res.audit_logs) {
        let tenant_name = res.audit_logs[h].tenant_name;
        if (res.audit_logs[h].status !== "Success") {
          if (active_imports[tenant_name]) {
            active_imports[tenant_name].failed_imports += 1;
          } else {
            active_imports[tenant_name] = {
              active_imports: 0,
              failed_imports: 1,
            };
          }
        }
      }

      let allUsers = [],
        last_failed_list = res.last_failed_list;

      for (let x in res.all_tenants) {
        let this_tenant = res.all_tenants[x];
        let newLog = {
          "Client Name": {
            type: "text",
            align: "left",
            id: "Date",
            value: this_tenant.tenant_name,
          },
          "# of Connections": {
            type: "number",
            align: "left",
            id: "Date",
            value: this_tenant.total_connections,
          },
          "# Active Imports": {
            type: "number",
            align: "left",
            id: "Date",
            value: active_imports[this_tenant.tenant_name]
              ? active_imports[this_tenant.tenant_name].active_imports
              : 0,
          },
          "Latest run failed imports": {
            type: "number",
            align: "left",
            id: "Date",
            value: last_failed_list[this_tenant.tenant_name]
              ? last_failed_list[this_tenant.tenant_name]
              : 0,
          },
          "Rows processed (24 hrs)": {
            type: "number",
            align: "left",
            id: "Date",
            value: active_imports[this_tenant.tenant_name]
              ? active_imports[this_tenant.tenant_name].rows_written
                ? active_imports[this_tenant.tenant_name].rows_written
                : 0
              : 0,
          },
          "Data Processed (Last 24 hrs)": {
            type: "text",
            align: "left",
            id: "Date",
            value: active_imports[this_tenant.tenant_name]
              ? DataConvert(
                active_imports[this_tenant.tenant_name].data_processed
              )
              : 0,
          },
        };

        allUsers.push(newLog);
      }
      setData({
        ...data,
        auditLogs: res.audit_logs,
        all_users: allUsers,
        loading: false,
        pieChart_EmailCount: emailCount,
        pieChart_GAMCount: gamCount,
        pieChart_TAMCount: tamCount,
        destination_chart: [gsheet, big_query],
      });
    } catch (error) {
      setData({ ...data, loading: false });
      if (error.code === "token_not_valid") {
        localStorage.clear();
        window.location.replace("/");
      }
    }
  };

  useEffect(() => {
    fetchProductDashBoardData();
    // eslint-disable-next-line
  }, []);

  if (data.loading) {
    return (
      <div className="h-screen max-w-screen mx-auto text-center flex justify-center flex-col">
        <AiOutlineLoading3Quarters
          className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
          size={40}
        />
      </div>
    );
  }
  return (
    <Fragment>
      <main className="m-4">
        <div className=" my-5 md:flex md:justify-between max-w-full">
          <div className="text-3xl font-bold flex justify-between items-center">
            {/* Welcome, Super Admin */}
            Client Oveview
          </div>
          {/* <div className="flex">
            <OverAllTenantSelection />
            <div className="flex justify-between items-center"></div>
          </div> */}
        </div>

        <div className="my-5">
          {/*Was flex gap-2*/}
          <div class="grid gap-4 grid-cols-1 sm:grid-cols-6 justify-center">
            {/* ------------------------------------------------Table Started--------------------------------  */}

            <div className=" col-span-4">
              <div
                className="overflow-x-auto shadow-md"
                style={{ maxHeight: "570px" }}
              >
                <div className="align-middle inline-block min-w-full">
                  <SimpleTable
                    data={data.all_users}
                    columns={data.headers}
                    actions={[]}
                    tableCSS={{ headerColor: "#E2EAE3", color: "black" }}

                  // paginationLimit={10}
                  />
                </div>
              </div>
            </div>

            {/* ------------------------------------------------PIE Started--------------------------------  */}
            {/*Was flex-2 w-1/4*/}
            <div className="col-span-2 justify-center ">
              <div className="flex flex-col gap-4">
                <div className="sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div className="">
                    <div className="header mb-3">
                      <p className="text-base text-center font-bold">
                        Imports by Connection Type
                      </p>
                    </div>
                    <div className="w-48 mx-auto">
                      <div>
                        <Doughnut data={pieChartData} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                  <div className="header mb-3">
                    <p className="text-base text-center font-bold">
                      Imports by output destination
                    </p>
                  </div>
                  <div className="w-48 mx-auto">
                    <div>
                      <Doughnut data={DestinationPieChartData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------------------------------Table Started--------------------------------  */}

          <div className="flex flex-col mt-5">
            <ImportLogs data={data.auditLogs} />
          </div>
        </div>
      </main>
    </Fragment>
  );
}

/*

<div class="flex">
   
  <div class="flex-1 w-1 bg-black">
    02
  </div>
  <div class="flex-2 w-1/4 bg-red-600">
    03
  </div>
  
</div>
*/
