import React, { useMemo, } from 'react'
import { useState } from "react";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { apiCallPost } from "../../api/RestAPICalls";
import { BASE_URL, TENANT_ID } from "../../utils/variables";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import Input from "../../components/Inputs/Input";
import logoMain from "../../assets/logo2.png"


const LoginPage = () => {

  const [state, setState] = useState({
    email: "",
    password: "",
    isLoggedIn: localStorage.getItem("access_token") ? true : false,
    tenent: "",
    isadmin: localStorage.getItem("isSuperAdmin") ? true : false,


  });
  const [barData, setBarData] = useState({
    data: {
      labels: ["1", "2", "3", "4", "5", "6"],
      datasets: [
        {
          label: "# of Ingestions",
          data: [12, 19, 15, 10, 12, 8],
          backgroundColor: "rgb(137,190,75)",
        },
        {
          label: "# of Failed Ingestions",
          data: [2, 3, 11, 5, 1, 4],
          backgroundColor: "white",
        },
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            maxBarThickness: 50,
          },
        ],
        xAxes: [
          {
            stacked: true,
            maxBarThickness: 50,
          },
        ],
      },
    }
  })
  const calculation = useMemo(() => Render_bar(barData), [barData]);


  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const [inputError, setInputError] = useState({
    emailError: "",
    passwordError: "",
  });
  const [loading, setLoading] = useState(false);

  const data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Ingestions",
        data: [12, 19, 15, 10, 12, 8],
        backgroundColor: "rgb(137,190,75)",
      },
      {
        label: "# of Failed Ingestions",
        data: [2, 3, 11, 5, 1, 4],
        backgroundColor: "white",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          maxBarThickness: 50,
        },
      ],
      xAxes: [
        {
          stacked: true,
          maxBarThickness: 50,
        },
      ],
    },
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    setInputError({
      emailError: "",
      passwordError: "",
    });
  };

  // const formValidation = () => {
  //   let isValid = true;

  //   if (!state.email || !/\S@\S+\.\S+/.test(state.email)) {
  //     setInputError((prevError) => {
  //       return {
  //         ...prevError,
  //         emailError: "Email invalid or missing",
  //       };
  //     });
  //     isValid = false;
  //   }

  //   if (state.password.length < 6) {
  //     setInputError((prevError) => {
  //       return {
  //         ...prevError,
  //         passwordError: "password invalid or missing",
  //       };
  //     });
  //     isValid = false;
  //   }

  //   return isValid;
  // };
  const formValidation = (email, password) => {
    let isValid = true;

    if (!email || !/\S@\S+\.\S+/.test(email)) {
      setInputError((prevError) => {
        return {
          ...prevError,
          emailError: "Email invalid or missing",
        };
      });
      isValid = false;
    }

    if (password.length < 6) {
      setInputError((prevError) => {
        return {
          ...prevError,
          passwordError: "password invalid or missing",
        };
      });
      isValid = false;
    }

    return isValid;
  };

  const _loginUser = async (e) => {
    e.preventDefault();
    let email = document.getElementById("email").value
    let password = document.getElementById("password").value
    let isFormValid = formValidation(email, password);
    if (isFormValid) {
      setLoading(true);
      let loginUrl = BASE_URL + "login";
      let bodyParams = {
        email: email,
        password: password,
      };
      apiCallPost(loginUrl, bodyParams, onSuccessUserLogin, onFailureUserLogin);
      setInputError({
        emailError: "",
        passwordError: "",
      });
    }

    // let isFormValid = formValidation();
    // if (isFormValid) {
    //   setLoading(true);
    //   let loginUrl = BASE_URL + "login";
    //   let bodyParams = {
    //     email: state.email,
    //     password: state.password,
    //   };
    //   apiCallPost(loginUrl, bodyParams, onSuccessUserLogin, onFailureUserLogin);
    //   setInputError({
    //     emailError: "",
    //     passwordError: "",
    //   });
    // }
  };

  const onSuccessUserLogin = async (response) => {
    if (!response.access) {
      setState({ ...state, error: response.error });
      setToast({ type: true, message: response.error });
      setLoading(false);
      return;
    }

    localStorage.setItem("access_token", response.access);
    localStorage.setItem("refresh_token", response.refresh);
    localStorage.setItem("user_email", response.email);
    localStorage.setItem("full_name", response.full_name);
    localStorage.setItem("user_account_id", response.account_id);
    localStorage.setItem("selected_tenant_id", response.tenants[0].tenant_id);
    localStorage.setItem("tenants", JSON.stringify(response.tenants));

    if (response.is_admin === true) {
      localStorage.setItem("isSuperAdmin", JSON.stringify(response.is_admin));
      localStorage.setItem("selected_tenant_id", 0);

      setState({ ...state, isLoggedIn: true, isadmin: true });
      return;
    }
    setState({ ...state, isLoggedIn: true });

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const onFailureUserLogin = async (responseJson) => {
    setState({
      ...state,
      error: responseJson.error ? responseJson.error : "Something went wrong",
    });
    setToast({
      type: true,
      message: responseJson.error ? responseJson.error : "Something went wrong",
    });
    setLoading(false);
  };

  if (state.isLoggedIn && state.isadmin) {
    return <Redirect to="/dashboard/super" />;
  } else if (state.isLoggedIn && !state.isadmin) {
    return <Redirect to="/dashboard" />;
  } else {
  }

  return (
    <div className="min-h-screen flex flex-col justify-center bg-ai-primary-dark ">
      <div className="max-w-full mx-auto">
        <div className="rounded-lg bg-ai-primary-dark  overflow-hidden shadow divide-y divide-main-800 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
          <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none flex flex-col justify-center relative group bg-ai-primary-dark  p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sa-secondary">
            <div>
              <span>
                {/* <!-- Heroicon name: outline/clock --> */}
                <img
                  src={logoMain}
                  alt="logo"
                />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium text-white">
                {/* <!-- Extend touch target to entire panel --> */}
                <span className="absolute inset-0" aria-hidden="true"></span>
              </h3>
              <p className="mt-2 text-sm text-gray-100"></p>
            </div>
            <div className="grid grid-cols-4">
              <div className="col-span-3">
                <Bar data={calculation.data} options={calculation.options} />
              </div>
              <div className="col-span-1"></div>
            </div>
          </div>

          {/* ----------------------------- sign in section (right)------------------------------------- */}

          <div className="">
            <div className="min-h-screen bg-ai-primary-dark  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <p className="text-white text-3xl mb-6">
                  Welcome to DataBeat Ingestion{" "}
                </p>

                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <p className="text-black text-2xl font-bold mx-auto text-center mb-2">
                    Sign In{" "}
                  </p>

                  <form
                    className="space-y-6"
                    onSubmit={(e) => {
                      _loginUser(e);
                    }}
                  >
                    <div>
                      <label
                        htmlhtmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                        // value={state.email}
                        // onChange={(e) => {
                        //   handleChange(e);
                        // }}
                        />
                        <span className="text-red-600">
                          {inputError.emailError}
                        </span>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlhtmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                        //className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                        // value={state.password}
                        // onChange={(e) => {
                        //   handleChange(e);
                        // }}
                        />
                        <span className="text-red-600">
                          {inputError.passwordError}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="text-sm">
                        <Link
                          to="/ForgotPassword"
                          className="font-medium text-grey-800 hover:text-grey-900"
                        >
                          Forgot your password?
                        </Link>
                      </div>
                    </div>

                    {/* ------------------------Error Msg------------------ */}
                    {toast.message && (
                      <ToastMsg
                        msg={toast.message}
                        isError={toast.type}
                        setToast={setToast}
                      />
                    )}

                    {/* ------------------------Error Msg------------------ */}

                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black  hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                        style={{ backgroundColor: "#6bd6d4" }}
                      >
                        {loading ? (
                          <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                            <AiOutlineLoading3Quarters
                              className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                              size={40}
                            />
                          </div>
                        ) : (
                          "Sign In"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Render_bar = (calculation) => {
  return calculation
}

export default LoginPage;