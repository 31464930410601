/***
 *  Get user local storage data
 *
 */

export async function getUserAccessToken() {
  try {
    const UserAccessToken = `Bearer ${
      localStorage.getItem("access_token")
        ? localStorage.getItem("access_token")
        : ""
    }`;

    return UserAccessToken;
  } catch (e) {
  }
  return "";
}
