import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL } from "../../../../utils/variables";
import { IoIosCloseCircle } from "react-icons/io";

const RemoveConnectionModal = ({
  removeConnectionRef,
  removeConnectionData,
}) => {
  const [inputs, setInputs] = useState({});

  const [state, setState] = useState({
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const onRemoveConnection = async () => {
    try {
      setState({ ...state, loading: true });
      const API = `${BASE_URL}remove/connection`;
      const response = await fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          connection_id: removeConnectionData.toString(),
        }),
      });
      const responseData = await response.json();
      // console.log("remove connection", responseData);
      setState({
        ...state,
        loading: false,
      });
      if (responseData.error) {
        setToast({ type: true, message: responseData.error });
      } else {
        setToast({ type: false, message: responseData.response });
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    } catch (error) {
      setState({
        ...state,
        loading: false,
      });
      setToast({
        type: true,
        message: "Something went wrong",
      });
    }
  };

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <Modal ref={removeConnectionRef}>
      {/* <div>
        <div className="mt-3">
          <h3
            className="text-lg leading-6 font-bold text-gray-900"
            id="modal-title"
          >
            Removing the Connection
          </h3>

          <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="mt-5 md:mt-0 md:col-span-3">
                <div>
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="">
                        <>
                          <div className="col-span-4 sm:col-span-4">
                            <label
                              htmlFor="remove"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Please type
                              <span className="font-bold"> CONFIRM</span> to
                              remove this Connection
                            </label>
                            <Input
                              type="text"
                              name="remove"
                              id="remove"
                              onChange={handleChange}
                              value={inputs.remove}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toast.message && (
        <ToastMsg
          msg={toast.message}
          isError={toast.type}
          setToast={setToast}
        />
      )}
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          disabled={inputs.remove === "CONFIRM" ? false : true}
          onClick={onRemoveConnection}
          className={
            inputs.remove === "CONFIRM"
              ? "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
              : "w-full inline-flex justify-center disabled:opacity-50 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
          }
        >
          {state.loading ? (
            <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
              <AiOutlineLoading3Quarters
                className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                size={40}
              />
            </div>
          ) : (
            "Remove"
          )}
        </button>
        <button
          type="button"
          onClick={() => removeConnectionRef.current.closeModal()}
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:col-start-1 sm:text-sm"
        >
          Close
        </button>
      </div> */}

      <div>
        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Removing the Connection
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              removeConnectionRef.current.closeModal();
            }}
          />
        </div>
        <div className="p-4 ">
          <Input
            label="Please type CONFIRM to remove the connection"
            type="text"
            name="remove"
            id="remove"
            onChange={handleChange}
            value={inputs.remove}
          />
          {toast.message && (
            <ToastMsg
              msg={toast.message}
              isError={toast.type}
              setToast={setToast}
            />
          )}
          <button
            className={` max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none ${inputs.remove !== "CONFIRM" && "disabled:opacity-50"
              } `}
            onClick={onRemoveConnection}
            disabled={inputs.remove === "CONFIRM" ? false : true}
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col ">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveConnectionModal;
