import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../../components/Layout";
import { dashboardRoutes } from "../../Routes";

const DashboardRoutes = () => {
  const [active, setActive] = useState(2);

  useEffect(() => {
    let currentURL = window.location.pathname;
    if (currentURL === "/dashboard/connections") {
      setActive(4);
    }
  }, [active]);

  return (
    <Layout active={active}>
      <Switch>
        {dashboardRoutes.map((route, k) => (
          <Route key={k} exact={route.exact} path={route.path}>
            {route.component}
          </Route>
        ))}
      </Switch>
    </Layout>
  );
};
export default DashboardRoutes;
