import React, { useState, useRef, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import SideoverComponent from "../SlideOverComponent/SlideOverComponent";
import Chips from "../Chips/Chips";

const DataCard = ({ openShow, actions, usecase, k }) => {
    // [We are setting the state for SlideOverComponent below]
    const [view, setView] = useState(false);
    // console.log("act", actions);
    const [state, setState] = useState({});
    // [The function below is used to render action items when we click on the ThreeDots.]
    const _renderActionItem = (usecase, k) => {
        // console.log("renderactionitem", k);
        // console.log("_renderActionItem", row)
        const _handleActionClick = (action) => {
            if (action.label === "Show") {
                setView(true)
            } else {

                action.action(usecase, k)
            }
        }
        return (
            <div className="absolute z-50 top-15 right-0 mr-6 w-32 bg-white border rounded shadow-lg" >
                <div className="flex flex-col">
                    {actions.map((action, i) => (

                        <button
                            key={i}
                            className={`p-2 cursor-pointer text-right ${usecase.statuschip?.value === 'GAM' && action.label === 'Issue Password'
                                ? 'text-gray-400 cursor-not-allowed hover:text-grey-500'
                                : 'hover:text-green-400'
                                }`}
                            onClick={() => { usecase.statuschip?.value === 'GAM' && action.label === 'Issue Password' ? console.log("Err") : _handleActionClick(action) }}
                            title={usecase.statuschip?.value === 'GAM' && action.label === 'Issue Password' ? "GAM doesn't have a Password" : ""}
                        >
                            {action.label}
                        </button>
                    ))
                    }</div>
            </div >
        );
    };

    // [The two functions below are utility functions that I used to format data.]
    function extractArrayData(arr) {
        const array = arr.map(item => item.user);
        return array;
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const amPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        return `${year}-${month}-${day} ${hours}:${minutes} ${amPm}`;
    }

    //[The array below is being passed into SlideOverComponent to render rows.]
    const USECASE_VIEW_ITEMS = [
        {
            label: "Connection Name",
            values: usecase.name.value,
            type: "text",
            id: "input_table",
        },
        {
            label: "Connection Type",
            values: usecase.statuschip.value,
            type: "text",
            id: "input_table",
        },
        {
            label: "Total Imports",
            values: usecase.total_imports.value,
            type: "text",
            id: "input_table",
        },
        {
            label: "Created By",
            values: usecase.created_by.value,
            type: "text",
            id: "created_by",
        },
        {
            label: "Project Name",
            values: usecase.project_name.value,
            type: "text",
            id: "project_name",
        },
        {
            label: "Last Updated",
            values: usecase.last_updated.value,
            type: "text",
            id: "last_updated",
        },
        {
            label: "Service Acount",
            values: usecase.service_account.value,
            type: "text",
            id: "service_account",
        },
        {
            label: "Old Owners",
            values: extractArrayData(usecase.old_owners.value),
            type: "chips",
            id: "old_owners",
        },
        {
            label: "Created At",
            values: formatDate(usecase.created_at.value),
            type: "text",
            id: "created_at",
        },

        // {
        //     label: "",
        //     values: ,
        //     type: "",
        //     id: "",
        // },
        // {
        //     label: " Metrics",
        //     type: "formula",
        //     values: [],
        //     id: "weighted_metric",
        // },

    ];

    //[The function below is used to decide how to display components in SlideOverComponent]
    const _renderUsecaseViewValue = (item) => {
        switch (item.type) {
            case "text":
                return <span>{item.values}</span>;
            case "chips":

                return <Chips data={item.values} showNumber={item?.showNumber} />;

            default:
                return <span>-</span>;
        }
    };

    return (
        <>
            {/* [The card Component] */}
            <div
                key={k}
                className={`relative z-49 bg-white shadow rounded-lg overflow-hidden bg-white hover:cursor-pointer transition-all transform hover:scale-105`}

            >
                <div
                    className={` font-semibold py-1 px-4 text-center rounded-br-2xl rounded-tl-md max-w-max ${usecase.statuschip?.style}`}
                >
                    <label
                        for={""}
                        className="text-sm font-semibold px-2 text-ai-primary-text-dark"
                    >
                        {usecase?.statuschip?.value}
                    </label>
                </div>

                {/* [The button to show Actions Dropdown] */}
                <button
                    onClick={() => {
                        setState({
                            ...state,
                            dropdown: !state.dropdown,
                            selected: usecase.id?.value,
                        });
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            if (
                                state.dropdown &&
                                state.selected === usecase.id?.value
                            ) {
                                setState({
                                    ...state,
                                    dropdown: false,
                                    selected: null,
                                });
                            }
                        }, 300);
                    }}
                    className="text-gray-600 cursor-pointer absolute right-0 top-2 font-semibold pb-1 px-4"

                >
                    <BsThreeDotsVertical className="w-6 h-6 text-gray-600 cursor-pointer z-50" />
                </button>
                {state.dropdown &&
                    state.selected === usecase.id?.value &&
                    _renderActionItem(usecase, k)}
                <div
                    className={`relative py-4 px-4 sm:px-6 overflow-hidden `}
                    onClick={
                        // () => setView(true)
                        () => openShow(usecase, k)
                        // console.log("ANotherKval", k)
                    }
                    title="Click to show more!"
                // [We are displaying the SlideOverComponent when we click on this div.]
                >

                    <dt className="flex justify-between">
                        <p
                            className="text-xl font-bold text-ai-primary-text-dark"
                        // title={usecase.name}
                        >
                            {usecase?.name?.value}
                        </p>
                    </dt>
                    <div className="flex gap-x-2 justify-between ">
                        <div className="flex pt-5 flex-wrap">
                            <div className="w-full p-0">
                                <p className="text-lg text-ai-primary-text-dark flex justify-center items-center  w-12 h-12 rounded-xl border-2 font-bold border-sa-secondary">
                                    {usecase?.total_imports?.value}
                                </p>
                            </div>
                            <div className="">
                                <div className="text-sm whitespace-nowrap leading-3 text-ai-primary-text-dark opacity-80 font-semibold">
                                    {/* # of */}# imports
                                    <span className="block mt-1"> </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center flex-wrap gap-2">
                            <div>
                                <dd className="flex-1 mt-4">
                                    <div className="text-md text-ai-primary-text-dark opacity-80 font-semibold">
                                        Created By
                                    </div>
                                    <div className="text-sm font-bold text-ai-primary-text-dark">
                                        {usecase?.created_by?.value}
                                    </div>
                                </dd>
                                <dd className="flex-1 mt-4">
                                    <div className="text-md text-ai-primary-text-dark opacity-80 font-semibold">
                                        Last Updated
                                    </div>
                                    <div className="text-sm font-bold text-ai-primary-text-dark">
                                        {usecase?.last_updated?.value}
                                    </div>
                                </dd>
                            </div>
                        </div>
                    </div>


                </div>


            </div>

            {/* [The Slideover component] */}
            {
                view && (
                    <SideoverComponent
                        close={() => {
                            setView(false);
                        }}
                    >
                        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                            <div className="px-4 sm:px-6 pb-4  border-b border-sa-secondary">
                                <h2
                                    className="text-lg font-bold text-ai-secondary-teal-thick flex items-center"
                                    id="slide-over-title"
                                >
                                    {/* {usecase.name} */}  {usecase?.name?.value}
                                    <span
                                        className={`${usecase?.statuschip?.value === "Hourly"
                                            ? "bg-[#FAEBC8]"
                                            : "bg-ai-primary-teal-light"
                                            } ml-2 py-1.5 px-4 rounded-2xl text-xs text-ai-primary-text-dark `}
                                    >
                                        {/* {usecase.is_complete ? usecase.type : "Incomplete"} */}{usecase?.statuschip?.value}
                                    </span>
                                </h2>
                            </div>
                            <div className="relative flex-1 px-4 sm:px-6">
                                {/* <!-- Replace with your content --> */}
                                <div className="absolute inset-0">
                                    {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
                                    <div className="flex flex-col">
                                        <div className="  -my-2 overflow-x-auto">
                                            <div className="py-2 align-middle inline-block min-w-full">
                                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {USECASE_VIEW_ITEMS.map((item, j) => (
                                                                <tr
                                                                    key={j}
                                                                    className={
                                                                        j % 2 ? "bg-[#f4f4f4]" : "bg-white"
                                                                    }
                                                                >
                                                                    <td className="p-6 whitespace-nowrap">
                                                                        <div className="text-sm text-ai-primary-text-dark opacity-80 font-medium">
                                                                            {item.label}
                                                                        </div>
                                                                    </td>
                                                                    <td className="py-6 whitespace-nowrap text-left text-sm text-gray-500">
                                                                        :
                                                                    </td>
                                                                    <td className="p-6 text-sm font-semibold text-ai-primary-text-dark">
                                                                        {_renderUsecaseViewValue(item)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex justify-evenly px-5 my-5">

                                            <button
                                                className="bg-button-bg uppercase py-1 px-4 md:py-2 md:px-6 rounded-md shadow-sm text-sm font-semibold text-ai-primary-text-dark duration-150 hover:bg-ai-primary-gray focus:outline-none focus:outine-none"
                                                onClick={() => {
                                                    actions[0].action(usecase, k)
                                                }}
                                            >
                                                Edit
                                            </button>



                                            {/* <button
                                            className="bg-button-bg uppercase px-4 py-2 md:px-6 whitespace-nowrap rounded-md shadow-sm text-sm font-semibold text-ai-primary-text-dark duration-150 hover:bg-ai-primary-gray focus:outline-none focus:outine-none"
                                            onClick={() => {
                                                // deActiveModelRef.current.openModal();
                                                setView(false);
                                            }}
                                        >
                                            {usecase.is_active ? "De-activate" : "Activate"}
                                        </button> */}


                                            <button
                                                className="bg-button-bg uppercase   px-4 py-2 md:px-6 rounded-md shadow-sm text-sm font-semibold text-ai-primary-text-dark duration-150 hover:bg-ai-primary-gray focus:outline-none focus:outine-none"
                                                onClick={(e) => {
                                                    setView(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /End replace --> */}
                            </div>
                        </div>
                    </SideoverComponent>)
            }
        </>
    );
}

export default DataCard;


