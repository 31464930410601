import React, { useRef, useState } from "react";

export default function InputChip({ values = [], onChange }) {
  const [state, setState] = useState({
    error: "",
    items: values,
    value: " ",
  });

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", " ", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = state.value.toLowerCase().trim();

      if (value && isValid(value)) {
        setState({
          items: [...state.items, state.value],
          value: "",
        });
        onChange([...state.items, state.value]);
      }
    }

    if (evt.keyCode === 8) {
      if (state.value === "" && state.items.length > 0) {
        let newItems = state.items;
        newItems.pop();
        setState({
          items: newItems,
          value: "",
        });
        onChange(newItems);
      }
    }
  };

  const handleChange = (evt) => {
    setState({ ...state, value: evt.target.value.toLowerCase().trim() });
  };

  const handleDelete = (item) => {
    setState({
      items: state.items.filter((i) => i !== item),
    });
    onChange(state.items.filter((i) => i !== item));
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setState({ ...state, error });

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return state.items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  return (
    <div>
      <div className="flex flex-wrap focus:ring-teal-500 my-auto rounded-md focus:border-teal-500 border-2">
        {state.items.map((item, k) => (
          <div
            className="px-2 m-1 h-7 my-auto text-sm border border-gray-400 rounded-full flex justify-between"
            key={k}
          >
            <span className="my-auto cursor-pointer"> {item}</span>

            <span
              className="my-auto ml-2 mr-1 cursor-pointer"
              onClick={() => handleDelete(item)}
            >
              <svg
                className="h-2 w-2 text-black-700"
                viewPort="0 0 10 10"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="7"
                  x2="7"
                  y2="1"
                  stroke="black"
                  strokeWidth="1"
                />
                <line
                  x1="1"
                  y1="1"
                  x2="7"
                  y2="7"
                  stroke="black"
                  strokeWidth="1"
                />
              </svg>
            </span>
          </div>
        ))}
        <input
          type="text"
          className="max-w-full m-2 p-1 focus:ring-white focus:border-white border-none sm:text-sm"
          value={state.value}
          placeholder="Type or paste email addresses and press `Enter`"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      </div>
      {state.error && (
        <p className="error border-l-4 border-red-500 text-red-600 bg-red-50 my-3 p-2">
          {state.error}
        </p>
      )}
    </div>
  );
}
