import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { dashboardRoutes } from "../../Routes";
import { RiLogoutBoxFill } from "react-icons/ri";
import LogoWhite from "../../assets/logoWhite.png";
import Logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";
import decode from "jwt-decode";
import OverAllTenantSelection from "../OverAllTenantSelection";
import logoWhite from "../../assets/logoWhite.png"
import logo from "../../assets/logo.png"

const Layout = (props) => {
  let history = useHistory();
  const [active, setActive] = useState(props.active);
  const [openSidebar, setSidebar] = useState(false);
  const location = useLocation();

  const [userrole, setRole] = useState("");
  const [state, setState] = useState({
    active: 0,
    sidebar: false,
    dropdown: false,
    loading: true,
  });

  useEffect(() => {
    // console.log("hussain", props.active);
    setActive(props.active);
    let currentURL = window.location.pathname;
    dashboardRoutes.map((route, i) => {
      if (route.path === currentURL) {
        setActive(i);
      }
    });
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn) {
      localStorage.clear();
      history.push("/");
    }
  }, [active, props, history]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodeToken = decode(token);
      if (decodeToken.exp * 1000 < new Date().getTime()) _onLogout();
    }
  }, [location]);

  const SideItem = ({ route, v, type }) => {
    // console.log("KKKK", route);

    // console.log("KKKK 3", type);
    const [showNestedItem, setShowNestedItem] = useState(false);
    let nestedItems = [];
    if (route?.nestedItems) {
      nestedItems = route.nestedItems();
    }

    let divClasses =
      active === v
        ? "list-none static text-[#84E5E3]"
        : "list-none static text-[#ADB3BF]";
    let linkClases =
      active === v
        ? "text-[#84E5E3] cursor-pointer text-center no-underline px-2 border-4 border-l-sa-secondary border-transparent  duration-200 bg-[#1B202B] hover:bg-[#1B202B] hover:text-white group w-full p-3 flex gap-2 flex-col  items-center text-xs font-medium"
        : "text-[#ADB3BF] cursor-pointer text-center no-underline  px-2 after:text-white hover:bg-[#1B202B] border-4 border-transparent duration-200 hover:text-white group w-full p-3 rounded-md flex flex-col gap-2 items-center text-xs font-medium ";
    let mobileLinkClasses =
      active === v
        ? "text-[#84E5E3] cursor-pointer border-4 border-l-sa-secondary border-transparent group p-3 rounded-md flex items-center text-base font-medium duration-200 bg-[#1B202B] hover:bg-[#1B202B] hover:text-white w-full "
        : "text-[#ADB3BF] cursor-pointer hover:bg-[#1B202B] border-4 border-transparent duration-200 hover:text-white group p-3 rounded-md flex items-center text-base font-medium";
    let sideIcon =
      active === v
        ? route.icon({ color: "#84E5E3" })
        : route.icon({ color: "#ADB3BF" });

    let renderSideIcon = (type) => {
      if (type === "mobile") {
        return (
          <span className="text-indigo-300 group-hover:text-white mr-6 h-6 w-6 ">
            {sideIcon}
          </span>
        );
      } else {
        return sideIcon;
      }
    };

    if (JSON.parse(localStorage.getItem("isSuperAdmin"))) {
      return (
        route.showInSidebar &&
        route.isSuperAdmin && (
          <li
            className={divClasses}
            key={v}
            onMouseEnter={() => {
              if (route?.isNestedItem) {
                setShowNestedItem(true);
              }
            }}
            onMouseLeave={() => {
              if (route?.isNestedItem) {
                setShowNestedItem(false);
              }
            }}
          >
            {route?.isNestedItem && showNestedItem ? (
              <>
                <a
                  className={type === "mobile" ? mobileLinkClasses : linkClases}
                >
                  {renderSideIcon(type)}
                  <span className="no-underline">{route.name}</span>
                </a>
                {nestedItems?.length > 0 && (
                  <div
                    className="absolute z-50  max-h-52 overflow-y-scroll  left-[113px] text-sm p-2 max-w-max rounded-md bg-[#2b313c] text-[#ADB3BF] font-semibold"
                    style={{ marginTop: "-86px", minWidth: "150px" }}
                  >
                    <ul className="list-none">
                      {nestedItems.map((item, i) => {
                        return (
                          <li
                            className="cursor-pointer hover:bg-[#1B202B] hover:text-[#84E5E3] rounded-md tracking-wider px-2 py-2 whitespace-nowrap"
                            key={i}
                            onClick={() => {
                              history.push(item.path);
                            }}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <Link
                to={
                  // location.pathname !== route.path ? route.path : location.asPath
                  route.path
                }
                onClick={() => {
                  setActive(v);
                  setState({ ...state, sidebar: false });
                }}
                className={type === "mobile" ? mobileLinkClasses : linkClases}
              >
                {/* <a
                  onClick={() => {
                    setActive(v);
                    setState({ ...state, sidebar: false });
                  }}
                  className={type === "mobile" ? mobileLinkClasses : linkClases}
                > */}
                {renderSideIcon(type)}
                <span className="no-underline">{route.name} </span>
                {/* </a> */}
              </Link>
            )}
          </li>
        )
      );
    } else {
      return (
        route.showInSidebar &&
        route.showUser && (
          <div
            className={divClasses}
            key={v}
            onMouseEnter={() => {
              if (route?.isNestedItem) {
                setShowNestedItem(true);
              }
            }}
            onMouseLeave={() => {
              if (route?.isNestedItem) {
                setShowNestedItem(false);
              }
            }}
          >
            {route?.isNestedItem && showNestedItem ? (
              <>
                <a
                  className={type === "mobile" ? mobileLinkClasses : linkClases}
                >
                  {renderSideIcon(type)}
                  <span className="no-underline">{route.name}</span>
                </a>
                {nestedItems?.length > 0 && (
                  <div className="absolute z-50 top-0 overflow-hidden left-[113px] text-sm px-1 py-2 min-w-[140px] rounded-md shadow-md bg-[#2b313c] text-[#ADB3BF] font-semibold">
                    <ul className="m-0 list-none">
                      {nestedItems.map((item, i) => {
                        return (
                          <li
                            className="cursor-pointer hover:bg-[#1B202B] hover:text-[#84E5E3] rounded-md tracking-wider px-2 py-2"
                            key={i}
                            onClick={() => {
                              history.push(item.path);
                            }}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <Link
                to={
                  // location.pathname !== route.path ? route.path : location.asPath
                  route.path
                }
                onClick={() => {
                  setActive(v);
                  setState({ ...state, sidebar: false });
                }}
                className={type === "mobile" ? mobileLinkClasses : linkClases}
              >
                {/* <a
                  onClick={() => {
                    setActive(v);
                    setState({ ...state, sidebar: false });
                  }}
                  className={type === "mobile" ? mobileLinkClasses : linkClases}
                > */}
                {renderSideIcon(type)}
                <span className="no-underline">{route.name} </span>
                {/* </a> */}
              </Link>
            )}
          </div>
        )
      );
    }
  };

  const _onLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const getInitialsLetter = (name) => {
    return name
      .match(/(^\S\S?|\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
  };

  return (
    <div className="h-screen bg-layout flex overflow-hidden">
      {/* <!-- Narrow sidebar --> */}
      <div
        style={{ marginTop: "3.8rem" }}
        className="hidden w-28 bg-ai-primary-text-dark lg:block relative"
      >
        <div className="w-full pt-6 flex flex-col items-center h-full overflow-y-auto overflow-x-hidden hideScrollBar">
          <ul className="flex-1 w-full space-y-1 list-none">
            {dashboardRoutes.map((route, v) => {
              return <SideItem key={v} route={route} v={v} type="desktop" />;
            })}
          </ul>
        </div>
      </div>

      {/* <!--
    Mobile menu

    Off-canvas menu for mobile, show/hide based on off-canvas menu state.
  --> */}
      {state.sidebar && (
        <div className="lg:hidden" role="dialog" aria-modal="true">
          <div className="fixed inset-0 z-40 flex">
            <div
              onClick={() => {
                setState({ ...state, sidebar: !state.sidebar });
              }}
              className="fixed inset-0 bg-gray-600 bg-opacity-75"
              aria-hidden="true"
            ></div>

            <div className="relative max-w-xs w-full bg-[#2b313c] pt-5 pb-4 flex-1 flex flex-col">
              <div className="absolute top-1 right-0 -mr-14 p-1">
                <button
                  type="button"
                  onClick={() => {
                    setState({ ...state, sidebar: !state.sidebar });
                  }}
                  className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                >
                  {/* <!-- Heroicon name: outline/x --> */}
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  <span className="sr-only">Close sidebar</span>
                </button>
              </div>

              <div className="flex-shrink-0 px-4 flex items-center">
                <img
                  className="h-12 w-auto"
                  src={logoWhite}
                  //  {"https://ingestion.databeat.io/static/media/logoWhite.12fb668d.png"}
                  // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0OX_qv-YoMGgqZNvpUw2dg91QdTwh-tkJNPmfDlMU&s"
                  alt="DataBeat"
                />
              </div>
              <div className="mt-5 flex-1 h-0 pr-2 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <ul className="space-y-1 text-indigo-100 list-none">
                    {dashboardRoutes.map((route, v) => {
                      return (
                        <SideItem key={v} route={route} v={v} type="mobile" />
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>

            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        </div>
      )}

      {/* <!-- Content area --> */}
      <div
        style={{ marginTop: "3.9rem" }}
        className="flex-1 flex flex-col overflow-hidden"
      >
        <header className="w-full fixed top-0 left-0 z-30">
          <div className="relative z-10 flex-shrink-0 h-16 bg-[#2b313c] lg:bg-white shadow-sm flex">
            <div className="flex-1 my-auto">
              <div className="flex justify-between">
                {/* <!-- Profile dropdown --> */}
                <img
                  className="h-10 hidden lg:block"
                  src={logo}
                  alt="DataBeat"
                />

                <div className="flex gap-4 justify-end ml-auto pr-0 sm:pr-2 md:pr-4">
                  {<OverAllTenantSelection />}
                  {/* {location.pathname !== "/dashboard/imports" && <OverAllTenantSelection />} */}
                  <button
                    onClick={() => {
                      setState({ ...state, dropdown: !state.dropdown });
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setState({ ...state, dropdown: false });
                      }, 300);
                    }}
                    type="button"
                    className="relative rounded-full text-sm focus:outline-none focus:ring-0"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span className="sr-only">Open user menu</span>
                    <h2 className="h-9 w-9 flex justify-center items-center text-center rounded-full text-ai-primary-text-dark font-semibold text-sm bg-ai-secondary-teal-light">
                      {localStorage.getItem("full_name") &&
                        getInitialsLetter(localStorage.getItem("full_name"))}
                    </h2>

                    <div
                      className={`${state.dropdown ? "block" : "hidden"
                        } origin-top-right  absolute right-0  mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      style={{ zIndex: 99 }}
                    // tabIndex="-1"
                    >
                      <div
                        onClick={() => {
                          history.push("/dashboard/profile");
                        }}
                        className="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-1"
                      >
                        Profile
                      </div>
                      <div
                        onClick={() => {
                          _onLogout();
                        }}
                        className="block cursor-pointer px-4 py-2 text-sm text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-1"
                      >
                        Sign out
                      </div>
                    </div>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setState({ ...state, sidebar: !state.sidebar });
                    }}
                    className=" px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                  >
                    <span className="sr-only">Open sidebar</span>
                    {/* <!-- Heroicon name: outline/menu-alt-2 --> */}
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* <!-- Main content --> */}
        <div className="flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto">{props.children}</main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
