import React, { useState, useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import Input from "../../../../components/Inputs/Input";
import InputChip from "../../../../components/Inputs/InputChip";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";

const CreateImport = ({
  import_data,
  type,
  connectionID,
  connectionDetails,
  onClose,
  editImport,
  allReportNames = [],
  scrollRef,
  allReportsData = [],
}) => {
  useEffect(() => {
    setInputs(
      import_data
        ? {
          program_name: "",
          frequency: "",
          connectionType: type,
          reportName: "",
          report_id: "",
          day: "",
          time: "",
          dataPushType: import_data[0]?.processing_type
            ? import_data[0].processing_type
            : "",
          input_email_subject: "",
          input_sheet_name: "",
          skip_rows: "",
          overlapping_column: "",
          importDestination: import_data[0]?.output_file_type
            ? import_data[0].output_file_type
            : "",
          google_sheet_output_url: "",
          output_sheetname: "",
          attachmentType: import_data[0]?.report_type
            ? import_data[0].report_type
            : "",
          bq_credentials: "",
          bq_table_name: "",
          email_list: [], // add it
          mail_folder: "inbox",
          report_time_zone: import_data[0]?.report_time_zone,
          link_type: "",
          end_row: null,
          connection: connectionID,
          account: localStorage.getItem("user_account_id"),
          status: "Active",
          ...import_data[0],
          frequency_week:
            import_data[0]?.frequency === "Hourly"
              ? import_data[0]?.date_range_data
              : import_data[0]?.frequency_week,
        }
        : {
          program_name: "",
          frequency: "Hourly",
          connectionType: type,
          reportName: "",
          day: "",
          frequency_week: "",
          time: "",
          dataPushType: "",
          input_email_subject: "",
          input_sheet_name: "",
          skip_rows: "",
          overlapping_column: "",
          importDestination: "",
          google_sheet_output_url: "",
          output_sheetname: "",
          attachmentType: "",
          bq_credentials: "",
          bq_table_name: "",
          email_list: [], // add it
          mail_folder: "inbox",
          report_time_zone: "",
          link_type: "",
          end_row: null,
          connection: connectionID,
          account: localStorage.getItem("user_account_id"),
          status: "Active",
        }
    );
  }, [import_data]);
  const [inputs, setInputs] = useState(
    import_data
      ? {
        // subqueries: [],
        // mapping_gsheeturl: "",
        // mapping_gsheettabname: "",
        program_name: "",
        frequency: "",
        connectionType: type,
        reportName: "",
        report_id: "",
        report_type: "",
        day: "",
        time: "",
        date_column: "",
        partition_column: "",
        dataPushType: import_data[0]?.processing_type
          ? import_data[0].processing_type
          : "",
        input_email_subject: "",
        input_sheet_name: "",
        skip_rows: "",
        overlapping_column: "",
        importDestination: import_data[0]?.output_file_type
          ? import_data[0].output_file_type
          : "",
        google_sheet_output_url: "",
        output_sheetname: "",
        attachmentType: import_data[0]?.report_type
          ? import_data[0].report_type
          : "",
        bq_credentials: "",
        s3_location: "",
        bq_table_name: "",
        email_list: [], // add it
        mail_folder: "inbox",
        report_time_zone: import_data[0]?.report_time_zone,
        link_type: "",
        end_row: null,
        connection: connectionID,
        account: localStorage.getItem("user_account_id"),
        status: "Active",
        ...import_data[0],
        frequency_week:
          import_data[0]?.frequency === "Hourly"
            ? import_data[0]?.date_range_data
            : import_data[0]?.frequency_week,
      }
      : {
        // subqueries: [],
        // mapping_gsheeturl: "",
        // mapping_gsheettabname: "",
        program_name: "",
        frequency: "",
        connectionType: type,
        reportName: "",
        day: "",
        frequency_week: "",
        time: "",
        dataPushType: "",
        input_email_subject: "",
        input_sheet_name: "",
        skip_rows: "",
        overlapping_column: "",
        importDestination: "",
        google_sheet_output_url: "",
        output_sheetname: "",
        attachmentType: "",
        bq_credentials: "",
        bq_table_name: "",
        email_list: [], // add it
        mail_folder: "inbox",
        report_time_zone: "",
        link_type: "",
        end_row: null,
        connection: connectionID,
        account: localStorage.getItem("user_account_id"),
        status: "Active",
        s3_location: "",
        partition_column: "",
        date_column: "",
      }
  );

  const [inputError, setInputError] = useState({
    program_nameError: "",
    frequencyError: "",
    connectionTypeError: "",
    reportNameError: "",
    dayError: "",
    frequency_weekError: "",
    timeError: "",
    processing_typeError: "",
    input_email_subjectError: "",
    input_sheet_nameError: "",
    report_typeError: "",
    skip_rowsError: "",
    overlapping_columnError: "",
    output_file_typeError: "",
    google_sheet_output_urlError: "",
    output_sheetnameError: "",
    attachmentTypeError: "",
    bq_credentialsError: "",
    bq_table_nameError: "",
    email_listError: "",
    mail_folderError: "",
    report_time_zoneError: "",
    link_typeError: "",
    end_rowError: "",
    connectionError: "",
    accountError: "",
    tenant_nameError: "",
    statusError: "",
    s3LocationError: "",
    tableTypeError: "",
    partitionColumnError: "",
    dateColumnError: "",
  });
  const [copyColor, setCopyColor] = useState(false);
  const [state, setState] = useState({
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const formValidation = () => {
    let isValid = true;
    // checks for both connection types
    if (!inputs.program_name.trim()) {
      setInputError((prevError) => {
        return {
          ...prevError,
          program_nameError: "Import Name is Invalid or Missing",
        };
      });
      isValid = false;
    }
    if (!inputs.frequency || inputs.frequency === "-- Select --") {
      setInputError((prevError) => {
        return {
          ...prevError,
          frequencyError: "Frequency is Invalid or Missing",
        };
      });
      isValid = false;
    }
    else if (inputs.frequency === "Monthly") {
      if (!inputs.day) {
        setInputError((prevError) => {
          return {
            ...prevError,
            dayError: "frequency Date is Invalid or Missing",
          };
        });
        isValid = false;
      }
    } else if (inputs.frequency === "Weekly")
      if (!inputs.frequency_week || inputs.frequency_week === "-- Select --") {
        setInputError((prevError) => {
          return {
            ...prevError,
            frequency_weekError: "Frequency day is Invalid or Missing",
          };
        });
        isValid = false;
      }
    if (!inputs.dataPushType || inputs.dataPushType === "-- Select --") {
      setInputError((prevError) => {
        return {
          ...prevError,
          dataPushTypeError: "Data Push Type  is Invalid or Missing",
        };
      });
      isValid = false;
    } else if (inputs.dataPushType === "Append") {
      if (!inputs.overlapping_column.trim() && inputs.frequency !== "Hourly") {
        if (type.toLowerCase() !== "tam") {
          setInputError((prevError) => {
            return {
              ...prevError,
              overlapping_columnError: "Primary Key is Invalid or Missing",
            };
          });
          isValid = false;
        }
      }
    }
    if (
      !inputs.importDestination ||
      inputs.importDestination === "-- Select --"
    ) {
      setInputError((prevError) => {
        return {
          ...prevError,
          importDestinationError: "Import Destination is Invalid or Missing",
        };
      });
      isValid = false;
    } else if (inputs.importDestination === "BQ Table") {
      if (!inputs.bq_table_name?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            bq_table_nameError: "BQ Table Path is Invalid or Missing",
          };
        });
        isValid = false;
      }
    } else if (inputs.importDestination === "Gsheet") {
      if (!inputs.google_sheet_output_url?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            google_sheet_output_urlError:
              "Google Sheet URL is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.output_sheetname?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            output_sheetnameError: "Data push Tab Name is Invalid or Missing",
          };
        });
        isValid = false;
      }
    }
    if (inputs.email_list?.length < 1) {
      setInputError((prevError) => {
        return {
          ...prevError,
          email_listError: "Must have email",
        };
      });
      isValid = false;
    }
    // checks for only email connection type
    if (type.toLowerCase() === "email") {
      if (!inputs.time?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            timeError: "Frequency time is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.mail_folder.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            mail_folderError: "Email Folder is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.input_email_subject.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            input_email_subjectError: "Email Subject is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.input_sheet_name?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            input_sheet_nameError:
              "Input Attachment Name is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.skip_rows?.toString()?.trim()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            skip_rowsError: "skip rows(from Row 1) is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.end_row?.toString()) {
        setInputError((prevError) => {
          return {
            ...prevError,
            end_rowError: "skip rows(end of the report) is Invalid or Missing",
          };
        });
        isValid = false;
      }
      if (!inputs.attachmentType || inputs.attachmentType === "-- Select --") {
        setInputError((prevError) => {
          return {
            ...prevError,
            attachmentTypeError: "Attachment Type is Invalid or Missing",
          };
        });
        isValid = false;
      }
    }

    // checks for only gam connection type
    if (type.toLowerCase() === "gam") {
      if (inputs.frequency !== "Hourly") {
        if (!inputs.time) {
          setInputError((prevError) => {
            return {
              ...prevError,
              timeError: "Frequency time is Invalid or Missing",
            };
          });
          isValid = false;
        }
      }
      if (!inputs.report_id) {
        setInputError((prevError) => {
          return {
            ...prevError,
            reportNameError: "Report Name is Invalid or Missing",
          };
        });
        isValid = false;
      }
      // if (!inputs.report_time_zone) {
      //   setInputError((prevError) => {
      //     return {
      //       ...prevError,
      //       report_time_zoneError: "Report time zone is Invalid or Missing",
      //     };
      //   });
      //   isValid = false;
      // }
    }
    if (type.toLowerCase() === "tam") {
      if (!inputs.time?.trim() && inputs.frequency !== "Hourly") {
        setInputError((prevError) => {
          return {
            ...prevError,
            timeError: "Frequency time is Invalid or Missing",
          };
        });
        isValid = false;
      }

      if (!inputs.s3_location) {
        setInputError((prevError) => {
          return {
            ...prevError,
            s3LocationError: "S3 Location is Invalid or Missing",
          };
        });
        isValid = false;
      }

      if (inputs.table_type === "-- Select --") {
        setInputError((prevError) => {
          return {
            ...prevError,
            tableTypeError: "Table type is Invalid or Missing",
          };
        });
        isValid = false;
      }
      //partitionColumnError
      // if (inputs.table_type === "Partitioned Table" && !inputs.partition_column) {
      //   if (inputs.frequency !== "Hourly") {
      //     setInputError((prevError) => {
      //       return {
      //         ...prevError,
      //         partitionColumnError: "Partition Column is Invalid or Missing",
      //       };
      //     });
      //     isValid = false;
      //   }
      // }
      if (inputs.importDestination === "BQ Table") {
        if (!inputs.date_column && inputs.frequency !== "Hourly") {
          setInputError((prevError) => {
            return {
              ...prevError,
              dateColumnError: "Date Column is Invalid or Missing",
            };
          });
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const onCreateImport = async () => {
    let isFormValid = formValidation();
    // console.log("these are inputs", inputs);
    //console.log(connectionDetails);
    if (isFormValid) {
      let body = type.toLowerCase() === "tam" ? {
        program_name: inputs.program_name,
        frequency: inputs.frequency,
        frequency_week: inputs.frequency_week,
        time:
          inputs.frequency === "Hourly" ? "0" : inputs.time.split(":").join(""),
        // overlapping_column:
        //   inputs.dataPushType === "Replace" ? "" : inputs.overlapping_column,
        bq_table_name: inputs.bq_table_name,
        // bq_credentials: "test",//localStorage.getItem("selectedServiceAccount"),
        // subqueries: [],
        // mapping_gsheeturl: "",
        // mapping_gsheettabname: "",
        email_list: inputs.email_list,
        connection: connectionDetails.connection_id,
        account: inputs.account,
        status: inputs.status,
        report_type: inputs.report_type,
        table_type: inputs.table_type,
        output_file_type: inputs.importDestination,
        processing_type: inputs.dataPushType,
        s3_location: inputs.s3_location ? inputs.s3_location : "",
        import_id: inputs.import_id,
        date_column: inputs.date_column,
        google_sheet_output_url: inputs.google_sheet_output_url,
        output_sheetname: inputs.output_sheetname,

        // partition_column: inputs.partition_column,
      } : {
        ...inputs,
        processing_type: inputs.dataPushType,
        output_file_type: inputs.importDestination,
        report_type: inputs.attachmentType,
        date_range_data: inputs.date_range_data,
        connection: connectionDetails.connection_id,
        incoming_email: connectionDetails.email,

        // Day means DATE (eg:25-11-2021)
        day:
          inputs.frequency === "Daily" ||
            inputs.frequency === "Weekly" ||
            inputs.frequency === "Hourly"
            ? ""
            : inputs.day,

        // frequency_week means weekday (eg:Sunday)
        frequency_week:
          inputs.frequency === "Daily" ||
            inputs.frequency === "Hourly" ||
            inputs.frequency === "Monthly"
            ? ""
            : inputs.frequency_week,

        time:
          inputs.frequency === "Hourly" ? "0" : inputs.time.split(":").join(""),

        overlapping_column:
          inputs.dataPushType === "Replace" ? "" : inputs.overlapping_column,

        bq_table_name:
          inputs.importDestination === "Gsheet" ? "" : inputs.bq_table_name,

        output_sheetname:
          inputs.importDestination === "BQ Table"
            ? ""
            : inputs.output_sheetname,

        google_sheet_output_url:
          inputs.importDestination === "BQ Table"
            ? ""
            : inputs.google_sheet_output_url,
      };

      if (type.toLowerCase() === "email" || type.toLowerCase() === "tam") {
        body.bq_credentials = connectionDetails?.service_account;
      } else {
        body.bq_credentials = connectionDetails?.service_account;
        body.network_id = connectionDetails?.network_id;
      }

      let tenantId = TENANT_ID;
      let API = "";

      if (type.toLowerCase() === "tam") {
        API = `${BASE_URL}imports/tam/${tenantId}`;
      }
      else if (import_data) {
        API = `${BASE_URL}imports/update/${tenantId}`;
      }
      else {
        API =
          type.toLowerCase() === "email" || type.toLowerCase() === "ga"
            ? `${BASE_URL}imports/email/${tenantId}`
            : `${BASE_URL}imports/gam/${tenantId}`
        // :`${BASE_URL}imports/tam/${tenantId}`;
      }

      //console.log("testhere", body);

      try {
        setState({ error: "", success: "", loading: true });
        // const body2 = {
        //   ...body,
        //   selected_tenant_id: parseInt(localStorage.selected_tenant_id)
        // };
        if (import_data && type.toLowerCase() === "tam") {
          const response = await fetch(API, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify(body),
          });
          const responseData = await response.json();
        }
        else {
          const response = await fetch(API, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            body: JSON.stringify(body),
          })
          const responseData = await response.json();
        }



        setState({
          ...state,
          loading: false,
        });

        setToast({
          type: false,
          message: "Import Operation Successfull!",
        });
        //UNCOMMNET THESE TO REDIRECT

        setTimeout(() => {
          window.location.reload(true);
        }, 3000);

        onClose();
      } catch (error) {
        setState({
          ...state,
          loading: false,
        });
        setToast({
          type: true,
          message: "Something went wrong",
        });
        //console.log("setToast", toast);
      }

      // fetch(API, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      //   },
      //   body: JSON.stringify(body),
      // })
      //   .then((res) => res.json())
      //   .then((res) => {

      //     setState({
      //       ...state,
      //       success: "Import Operation Successfull!",
      //       error: null,
      //       loading: false,
      //     });
      //     setTimeout(() => {
      //       window.location.reload(true);
      //     }, 1000);
      //   })
      //   .catch((err) => {
      //     setState({
      //       ...state,
      //       success: false,
      //       error: "Something went wrong",
      //       loading: false,
      //     });
      //   });
    }
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });

    setState({ error: "", success: "" });
    setInputError({ ...inputError, [e.target.name + "Error"]: "" });
  };

  return (
    <div className="p-2 px-4 bg-white" ref={scrollRef}>
      {type.toLowerCase() === "email" || type.toLowerCase() === "ga" ? (
        // <!-- Email connection -->
        <div className="space-y-3 divide-y divide-gray-200"  >
          <div className="space-y-8 divide-y divide-gray-200">

            <div>

              <p className="mt-1 text-sm text-gray-500">
                {/* Use a permanent address where you can receive mail. */}
              </p>
            </div>

            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                {/*  */}
                <Input
                  label="Import Name"
                  name="program_name"
                  type="text"
                  value={inputs.program_name}
                  onChange={handleChange}
                />
                <span className="text-red-600">
                  {inputError.program_nameError}
                </span>

                {/*  */}
                <Input
                  onChange={(e) => {
                    setInputs({ ...inputs, frequency: e });
                    setInputError({ ...inputError, frequencyError: "" });
                  }}
                  label="Frequency"
                  type="dropdown"
                  name="frequency"
                  values={["-- Select --", "Weekly", "Daily"]}
                  //"Monthly",
                  value={inputs.frequency}
                  isTooltip={true}
                  ToolTipDescription="Select how frequently the import should run"
                />
                <span className="text-red-600">
                  {inputError.frequencyError}
                </span>

                {/*  */}
                {inputs.frequency === "Monthly" && (
                  <Input
                    label="Frequency Month"
                    name="day"
                    type="date"
                    value={inputs.day}
                    onChange={handleChange}
                  />
                )}
                <span className="text-red-600">{inputError.dayError}</span>

                {/*  */}
                {inputs.frequency === "Weekly" && (
                  <Input
                    onChange={(e) => {
                      setInputs({ ...inputs, frequency_week: e });
                      setInputError({
                        ...inputError,
                        frequency_weekError: "",
                      });
                    }}
                    type="dropdown"
                    label="Select Day"
                    name="frequency_week"
                    values={[
                      "-- Select --",
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ]}
                    value={inputs.frequency_week}
                  />
                )}
                <span className="text-red-600">
                  {inputError.frequency_weekError}
                </span>

                {/*  */}
                <Input
                  label="Frequency Time"
                  name="time"
                  type="time"
                  value={
                    inputs.time.length === 4
                      ? inputs.time.slice(0, 2) +
                      ":" +
                      inputs.time.slice(2, 4)
                      : inputs.time
                  }
                  onChange={handleChange}
                />
                <span className="text-red-600">{inputError.timeError}</span>

                {/*  */}
                <Input
                  label="Mail Folder"
                  name="mail_folder"
                  type="text"
                  value={inputs.mail_folder}
                  onChange={handleChange}
                />
                <span className="text-red-600">
                  {inputError.mail_folderError}
                </span>

                {/*  */}

                <Input
                  label="Input Email Subject"
                  name="input_email_subject"
                  type="text"
                  value={inputs.input_email_subject}
                  onChange={handleChange}
                />
                <span className="text-red-600">
                  {inputError.input_email_subjectError}
                </span>

                {/*  */}
                <Input
                  label="Input Attachment Name"
                  name="input_sheet_name"
                  type="text"
                  value={inputs.input_sheet_name}
                  onChange={handleChange}
                />
                <span className="text-red-600">
                  {inputError.input_sheet_nameError}
                </span>
              </div>

              <div className="sm:col-span-3">
                {/*  */}
                <Input
                  onChange={(e) => {
                    setInputs({ ...inputs, dataPushType: e });
                    setInputError({ ...inputError, dataPushTypeError: "" });
                  }}
                  label="Data Push Type"
                  type="dropdown"
                  name="dataPushType"
                  values={["-- Select --", "Replace", "Append"]}
                  value={inputs.dataPushType}
                  isTooltip={true}
                  ToolTipDescription="Replaces the entire existing data with new data"
                />
                <span className="text-red-600">
                  {inputError.dataPushTypeError}
                </span>

                {/*  */}
                {inputs.dataPushType === "Append" && (
                  <Input
                    label="Primary Key"
                    name="overlapping_column"
                    type="text"
                    value={inputs.overlapping_column}
                    onChange={handleChange}
                    isTooltip={true}
                    ToolTipDescription="Appends the new data to the existing data after removing the duplicate rows"
                  />
                )}
                <span className="text-red-600">
                  {inputError.overlapping_columnError}
                </span>

                {/*  */}
                <Input
                  onChange={(e) => {
                    setInputs({ ...inputs, importDestination: e });
                    setInputError({
                      ...inputError,
                      importDestinationError: "",
                    });
                  }}
                  label="Import Destination"
                  name="importDestination"
                  type="dropdown"
                  values={["-- Select --", "BQ Table", "Gsheet"]}
                  value={inputs.importDestination}
                />
                <span className="text-red-600">
                  {inputError.importDestinationError}
                </span>

                {inputs.importDestination === "BQ Table" && (
                  <Input
                    onChange={(e) => {
                      setInputs({ ...inputs, table_type: e });
                      setInputError({
                        ...inputError,
                        table_type: "",
                      });
                    }}
                    label="Table Type"
                    name="table_type"
                    type="dropdown"
                    values={[
                      "-- Select --",
                      "Regular Table",
                      "Partitioned Table",
                    ]}
                    value={inputs?.table_type}
                  />
                )}

                {/*  */}
                {inputs.importDestination === "BQ Table" && (
                  <>
                    <Input
                      label="BQ Table Path"
                      name="bq_table_name"
                      type="text"
                      value={inputs.bq_table_name}
                      onChange={handleChange}
                    />
                    <span className="text-red-600">
                      {inputError.bq_table_nameError}
                    </span>
                    <div>
                      {/*  */}
                      <Input
                        label="Service Account"
                        name="select_servicea_ccount"
                        type="text"
                        value={localStorage.getItem("selectedServiceAccount")}
                        disabled={"disabled"}
                      />
                    </div>
                  </>
                )}

                {/*  */}
                {inputs.importDestination === "Gsheet" && (
                  <div>
                    <Input
                      label="Google Sheet URL"
                      name="google_sheet_output_url"
                      type="text"
                      value={inputs.google_sheet_output_url}
                      onChange={handleChange}
                      isTooltip={true}
                      ToolTipDescription="Provide the complete Gsheet URL"
                    />
                    <span className="text-red-600">
                      {inputError.google_sheet_output_urlError}
                    </span>

                    <Input
                      label="Data push Tab Name"
                      name="output_sheetname"
                      type="text"
                      value={inputs.output_sheetname}
                      onChange={handleChange}
                      isTooltip={true}
                      ToolTipDescription="Provide the tab name to which the data should be pushed"
                    />
                    <span className="text-red-600">
                      {inputError.output_sheetnameError}
                    </span>
                    <div>
                      <div className="mt-5">
                        <span className="ml-2 text-black text-sm">
                          Service account to give access -
                        </span>
                      </div>
                      <div className="flex">
                        <span className="ml-2 text-black text-sm font-bold">
                          ingestion-projects@databeat-internal.iam.gserviceaccount.com
                        </span>
                        <FaCopy
                          className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                          style={{ color: copyColor ? "blue" : "black" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                            );
                            setCopyColor(true);
                          }}
                        />
                        {copyColor && (
                          <span className="ml-2 text-blue-500 text-sm font-bold">
                            Copied!
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/*  */}
                <Input
                  label="Rows to Skip (from Row 1)"
                  name="skip_rows"
                  type="number"
                  value={inputs.skip_rows}
                  onChange={handleChange}
                  isTooltip={true}
                  ToolTipDescription="Provide the number of unnecessary rows from the top to remove before importing"
                />
                <span className="text-red-600">
                  {inputError.skip_rowsError}
                </span>

                {/*  */}
                <Input
                  label="Rows to Skip (end of the report)"
                  name="end_row"
                  type="number"
                  value={inputs.end_row}
                  onChange={handleChange}
                  isTooltip={true}
                  ToolTipDescription="Provide the number of unnecessary rows from the bottom to remove before importing"
                />
                <span className="text-red-600">
                  {inputError.end_rowError}
                </span>

                {/*  */}
                <Input
                  onChange={(e) => {
                    setInputs({ ...inputs, attachmentType: e });
                    setInputError({ ...inputError, attachmentTypeError: "" });
                  }}
                  label="Attachment Type"
                  name="attachmentType"
                  type="dropdown"
                  values={["-- Select --", "Attachment", "Link"]}
                  value={
                    inputs.attachmentType.charAt(0).toUpperCase() +
                    inputs.attachmentType.slice(1)
                  }
                  isTooltip={true}
                  ToolTipDescription="Refers to any file (csv, xlsx, zip etc)"
                />
                <span className="text-red-600">
                  {inputError.attachmentTypeError}
                </span>

                {/*  */}
                {inputs.attachmentType === "Link" && (
                  <div>
                    <label className="inline-flex items-center mt-8">
                      <input
                        type="checkbox"
                        name="isZip"
                        value={inputs.link_type}
                        className="appearance-none checked:bg-blue-600 checked:border-transparent  "
                        onChange={(e) => {
                          setInputs({
                            ...inputs,
                            link_type: e.target.checked ? "zip" : "",
                          });
                        }}
                      />
                      <div className="flex">
                        <span className="ml-2 text-gray-700">
                          Zip File in link
                        </span>
                      </div>
                    </label>
                  </div>
                )}

                {/*  */}
                <div className="my-2">
                  <label
                    htmlFor="multi"
                    className=" flex text-sm font-semibold text-ai-primary-dark"
                  >
                    Email List
                  </label>
                  <div className="mt-1">
                    <InputChip
                      values={inputs.email_list}
                      onChange={(e) =>
                        setInputs({ ...inputs, email_list: e })
                      }
                    />
                    <span className="text-red-600">
                      {inputError.email_listError}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* {toast.message && (
            <ToastMsg
              msg={toast.message}
              isError={toast.type}
              setToast={setToast}
            />
          )} */}

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => onClose()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Cancel
              </button>
              <button
                onClick={onCreateImport}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal"
              >
                {state.loading ? (
                  <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                    <AiOutlineLoading3Quarters
                      className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                      size={40}
                    />
                  </div>
                ) : editImport ? (
                  "Update"
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : type.toLowerCase() === "gam" ? (
        // <!-- GAM API connection -->
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="">
              <div>

                <p className="mt-1 text-sm text-gray-500">
                  {/* Use a permanent address where you can receive mail. */}
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <div>
                    <Input
                      label="Import Name"
                      name="program_name"
                      type="text"
                      value={inputs.program_name}
                      onChange={handleChange}
                    />
                    <span className="text-red-600">
                      {inputError.program_nameError}
                    </span>
                    <Input
                      label="Network id"
                      name="network_id"
                      type="text"
                      value={localStorage.getItem("selectedNetworkId")}
                      disabled={"disabled"}
                    />
                    <Input
                      type="dropdown"
                      onChange={(e) => {
                        setInputs({ ...inputs, frequency: e });
                        setInputError({ ...inputError, frequencyError: "" });
                      }}
                      label="Frequency"
                      name="frequency"
                      values={[
                        "-- Select --",
                        // "Monthly",
                        // "Weekly",
                        "Daily",
                        "Hourly",
                      ]}
                      value={inputs.frequency}
                    />
                    <span className="text-red-600">
                      {inputError.frequencyError}
                    </span>

                    {/*  */}
                    {inputs.frequency === "Monthly" && (
                      <>
                        <Input
                          label="Frequency Month"
                          name="day"
                          type="date"
                          value={inputs.day}
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.dayError}
                        </span>
                      </>
                    )}

                    {/*  */}
                    {inputs.frequency === "Weekly" && (
                      <>
                        <Input
                          type="dropdown"
                          onChange={(e) => {
                            setInputs({ ...inputs, frequency_week: e });
                            setInputError({
                              ...inputError,
                              frequency_weekError: "",
                            });
                          }}
                          label="Select Day"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ]}
                          value={inputs.frequency_week}
                        />
                        <span className="text-red-600">
                          {inputError.frequency_weekError}
                        </span>
                      </>
                    )}

                    {/*  */}
                    {/* {inputs.frequency === "Hourly" && (
                      <>
                        <Input
                          type="dropdown"
                          onChange={(e) =>
                            setInputs({ ...inputs, frequency_week: e })
                          }
                          label="Select Data Date Range"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Today",
                            "Yesterday",
                            "Last-3 Days",
                            "Last-7 Days",
                          ]}
                          value={inputs.frequency_week}
                        />
                        <span className="text-red-600">
                          {inputError.frequency_weekError}
                        </span>
                      </>
                    )} */}

                    {/*  */}
                    {inputs.frequency !== "Hourly" && (
                      <>
                        <Input
                          label="Frequency Time"
                          name="time"
                          type="time"
                          value={
                            inputs.time.length === 4
                              ? inputs.time.slice(0, 2) +
                              ":" +
                              inputs.time.slice(2, 4)
                              : inputs.time
                          }
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.timeError}
                        </span>
                      </>
                    )}

                    {/*  */}

                    <>
                      <Input
                        type="dropdown"
                        onChange={(e) =>
                          setInputs({ ...inputs, date_range_data: e })
                        }
                        label="Select Data Date Range"
                        name="date_range_data"
                        values={[
                          "-- Select --",
                          "Today",
                          "Yesterday",
                          "Last-3 Days",
                          "Last-7 Days",
                        ]}
                        value={inputs.date_range_data}
                      />
                      <span className="text-red-600">
                        {inputError.date_range_dataError}
                      </span>
                    </>
                    {/* <Input
                      label="Report Time Zone"
                      name="report_time_zone"
                      values={["-- Select --", "UTC", "IST", "EST"]}
                      value={inputs.report_time_zone}
                      type="dropdown"
                      onChange={(e) => {
                        setInputs({ ...inputs, report_time_zone: e });
                        setInputError({
                          ...inputError,
                          report_time_zoneError: "",
                        });
                      }}
                    /> */}
                    <span className="text-red-600">
                      {inputError.report_time_zoneError}
                    </span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="my-2">
                    {/*  */}
                    <Input
                      onChange={(e) => {
                        setInputs({ ...inputs, dataPushType: e });
                        setInputError({ ...inputError, dataPushTypeError: "" });
                      }}
                      label="Data Processing Type/ Data Import Type"
                      name="dataPushType"
                      type="dropdown"
                      values={["-- Select --", "Replace", "Append"]}
                      value={inputs.dataPushType}
                    />
                    <span className="text-red-600">
                      {inputError.dataPushTypeError}
                    </span>

                    {/*  */}
                    {inputs.dataPushType === "Append" && (
                      <Input
                        label="Primary Key"
                        name="overlapping_column"
                        type="text"
                        value={inputs.overlapping_column}
                        onChange={handleChange}
                      />
                    )}
                    <span className="text-red-600">
                      {inputError.overlapping_columnError}
                    </span>

                    {/*  */}
                    <div className="my-2">
                      <Input
                        onChange={(e) => {
                          setInputs({ ...inputs, importDestination: e });
                          setInputError({
                            ...inputError,
                            importDestinationError: "",
                          });
                        }}
                        type="dropdown"
                        label="Import Destination"
                        name="importDestination"
                        values={["-- Select --", "BQ Table", "Gsheet"]}
                        value={inputs.importDestination}
                      />
                      <span className="text-red-600">
                        {inputError.importDestinationError}
                      </span>
                    </div>
                    {inputs.importDestination === "BQ Table" && (
                      <Input
                        onChange={(e) => {
                          setInputs({ ...inputs, table_type: e });
                          setInputError({
                            ...inputError,
                            table_type: "",
                          });
                        }}
                        label="Table Type"
                        name="Table_type"
                        type="dropdown"
                        values={[
                          "-- Select --",
                          "Regular Table",
                          "Partitioned Table",
                        ]}
                        value={inputs?.table_type}
                      />
                    )}
                    {/*  */}
                    {inputs.importDestination === "BQ Table" && (
                      <Input
                        label="BQ Table Path"
                        name="bq_table_name"
                        type="text"
                        value={inputs.bq_table_name}
                        onChange={handleChange}
                      />
                    )}
                    <span className="text-red-600">
                      {inputError.bq_table_nameError}
                    </span>
                    {/*  */}
                    {inputs.importDestination === "Gsheet" && (
                      <div>
                        <Input
                          label="Google Sheet URL"
                          name="google_sheet_output_url"
                          type="text"
                          value={inputs.google_sheet_output_url}
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.google_sheet_output_urlError}
                        </span>

                        <div>
                          <div className="mt-5">
                            <span className="ml-2 text-black text-sm">
                              Service account to give access -
                            </span>
                          </div>
                          <div className="flex">
                            <span className="ml-2 text-black text-sm font-bold">
                              ingestion-projects@databeat-internal.iam.gserviceaccount.com
                            </span>
                            <FaCopy
                              className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                              style={{ color: copyColor ? "blue" : "black" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                                );
                                setCopyColor(true);
                              }}
                            />
                            {copyColor && (
                              <span className="ml-2 text-blue-500 text-sm font-bold">
                                Copied!
                              </span>
                            )}
                          </div>
                        </div>
                        <Input
                          label="Data push Tab Name"
                          name="output_sheetname"
                          type="text"
                          value={inputs.output_sheetname}
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.output_sheetnameError}
                        </span>
                      </div>
                    )}
                    <div>
                      {/*  */}
                      <Input
                        label="Select Sevice Account"
                        name="select_servicea_ccount"
                        type="text"
                        value={localStorage.getItem("selectedServiceAccount")}
                        disabled={"disabled"}
                      />
                    </div>
                    {/* {console.log("allReportsData____", allReportsData)} */}
                    {allReportsData.length > 0 && (
                      <Input
                        onChange={(e) => {
                          setInputs({
                            ...inputs,
                            reportName: e,
                            report_id: allReportsData
                              .filter((report) => report.report_name == e)[0]
                              .report_id.toString(),
                          });
                          setInputError({ ...inputError, reportNameError: "" });
                        }}
                        label="Report Name"
                        name="reportName"
                        type="dropdown"
                        values={allReportNames}
                        value={
                          allReportsData.filter(
                            (report) => report.report_id == inputs.report_id
                          ).length > 0
                            ? allReportsData.filter(
                              (report) => report.report_id == inputs.report_id
                            )[0].report_name
                            : ""
                        }
                      />
                    )}
                    <span className="text-red-600">
                      {inputError.reportNameError}
                    </span>

                    <div className="my-2">
                      <label
                        htmlFor="multi"
                        className="flex text-sm font-semibold text-ai-primary-dark"
                      >
                        Email List
                      </label>
                      <div className="mt-1">
                        <InputChip
                          values={inputs.email_list}
                          onChange={(e) =>
                            setInputs({ ...inputs, email_list: e })
                          }
                        />
                        <span className="text-red-600">
                          {inputError.email_listError}
                        </span>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>

          {toast.message && (
            <ToastMsg
              msg={toast.message}
              isError={toast.type}
              setToast={setToast}
            />
          )}

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => onClose()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Cancel
              </button>
              <button
                onClick={onCreateImport}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal"
              >
                {editImport ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      ) : type.toLowerCase() === "tam" ? (
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="">
              <div>
                <p className="mt-1 text-sm text-gray-500">
                  {/* Use a permanent address where you can receive mail. */}
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <div>
                    <Input
                      label="Import Name"
                      name="program_name"
                      type="text"
                      value={inputs.program_name}
                      onChange={handleChange}
                    //disabled={editImport ? "disabled" : ""}
                    />
                    <span className="text-red-600">
                      {inputError.program_nameError}
                    </span>

                    <Input
                      type="dropdown"
                      onChange={(e) => {
                        setInputs({ ...inputs, report_type: e });
                        //setInputError({ ...inputError, frequencyError: "" });
                      }}
                      label="Report Type"
                      name="report_type"
                      values={[
                        "-- Select --",
                        "Report",
                        "Stream"

                      ]}
                      value={inputs.report_type}
                    />
                    {/* <span className="text-red-600">
                      {inputError.frequencyError}
                    </span> */}
                    {inputs.report_type === "Report" ?
                      <Input
                        type="dropdown"
                        onChange={(e) => {
                          setInputs({ ...inputs, frequency: e });
                          setInputError({ ...inputError, frequencyError: "" });
                        }}
                        label="Frequency"
                        name="frequency"
                        values={[
                          "-- Select --",
                          "Weekly",
                          "Daily",

                        ]}
                        value={inputs.frequency}
                      /> : inputs.report_type === "Stream" ?
                        <Input
                          type="dropdown"
                          onChange={(e) => {
                            setInputs({ ...inputs, frequency: e });
                            setInputError({ ...inputError, frequencyError: "" });
                          }}
                          label="Frequency"
                          name="frequency"
                          values={[
                            // "-- Select --",
                            "Hourly",
                          ]}
                          value={inputs.frequency}
                          disabled
                        />
                        : ""}

                    <span className="text-red-600">
                      {inputError.frequencyError}
                    </span>

                    {/*  */}

                    {inputs.frequency === "Weekly" && (
                      <>
                        {/* <Input
                          type="dropdown"
                          onChange={(e) => {
                            setInputs({ ...inputs, frequency_week: e });
                            setInputError({
                              ...inputError,
                              frequency_weekError: "",
                            });
                          }}
                          label="Select Day"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ]}
                          value={inputs.frequency_week}
                        /> */}
                        <Input
                          onChange={(e) => {
                            setInputs({ ...inputs, frequency_week: e });
                            setInputError({
                              ...inputError,
                              frequency_weekError: "",
                            });
                          }}
                          type="dropdown"
                          label="Select Day"
                          name="frequency_week"
                          values={[
                            "-- Select --",
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ]}
                          value={inputs.frequency_week}
                        />
                        <span className="text-red-600">
                          {inputError.frequency_weekError}
                        </span>
                      </>
                    )}

                    {/*  */}
                    {/* {inputs.frequency === "Hourly" && (
                    <>
                      <Input
                        type="dropdown"
                        onChange={(e) =>
                          setInputs({ ...inputs, frequency_week: e })
                        }
                        label="Select Data Date Range"
                        name="frequency_week"
                        values={[
                          "-- Select --",
                          "Today",
                          "Yesterday",
                          "Last-3 Days",
                          "Last-7 Days",
                        ]}
                        value={inputs.frequency_week}
                      />
                      <span className="text-red-600">
                        {inputError.frequency_weekError}
                      </span>
                    </>
                  )} */}

                    {/*  */}
                    {inputs.frequency !== "Hourly" && (
                      <>
                        <Input
                          label="Frequency Time"
                          name="time"
                          type="time"
                          value={
                            inputs.time.length === 4
                              ? inputs.time.slice(0, 2) +
                              ":" +
                              inputs.time.slice(2, 4)
                              : inputs.time
                          }
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.timeError}
                        </span>
                      </>
                    )}

                    {/*  */}
                    {/* 
                    <>
                      <Input
                        type="dropdown"
                        onChange={(e) =>
                          setInputs({ ...inputs, date_range_data: e })
                        }
                        label="Select Data Date Range"
                        name="date_range_data"
                        values={[
                          "-- Select --",
                          "Today",
                          "Yesterday",
                          "Last-3 Days",
                          "Last-7 Days",
                        ]}
                        value={inputs.date_range_data}
                      />
                      <span className="text-red-600">
                        {inputError.date_range_dataError}
                      </span>
                    </> */}
                    {/* <Input
                    label="Report Time Zone"
                    name="report_time_zone"
                    values={["-- Select --", "UTC", "IST", "EST"]}
                    value={inputs.report_time_zone}
                    type="dropdown"
                    onChange={(e) => {
                      setInputs({ ...inputs, report_time_zone: e });
                      setInputError({
                        ...inputError,
                        report_time_zoneError: "",
                      });
                    }}
                  /> 
                    <span className="text-red-600">
                      {inputError.report_time_zoneError}
                    </span>*/}
                    <Input
                      onChange={(e) => {
                        setInputs({ ...inputs, dataPushType: e });
                        setInputError({ ...inputError, dataPushTypeError: "" });
                        // console.log("dataPushtype", inputs.dataPushType)
                      }}
                      label="Data Processing Type/ Data Import Type"
                      name="dataPushType"
                      type="dropdown"
                      values={["-- Select --", "Append", "Replace"]}
                      value={inputs.dataPushType}

                    />
                    <span className="text-red-600">
                      {inputError.dataPushTypeError}
                    </span>
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className=" my-2">
                    {/*  */}


                    {/* PRIMARY KEY */}
                    {/* {inputs.dataPushType === "Append" && inputs.frequency !== "Hourly" ? (
                        <Input
                          label="Primary Key"
                          name="overlapping_column"
                          type="text"
                          value={inputs.overlapping_column}
                          onChange={handleChange}
                        />
                      ) : ""}
                      <span className="text-red-600">
                        {inputError.overlapping_columnError}
                      </span> */}

                    {/*  */}
                    <div className="my-2 pt-1">
                      <Input
                        onChange={(e) => {
                          setInputs({ ...inputs, importDestination: e });
                          setInputError({
                            ...inputError,
                            importDestinationError: "",
                          });
                        }}
                        type="dropdown"
                        label="Import Destination"
                        name="importDestination"
                        values={["-- Select --", "BQ Table", "Gsheet"]}
                        value={inputs.importDestination}
                      />
                      <span className="text-red-600">
                        {inputError.importDestinationError}
                      </span>
                    </div>
                    {inputs.importDestination === "BQ Table" && (
                      <Input
                        onChange={(e) => {
                          setInputs({ ...inputs, table_type: e });
                          setInputError({
                            ...inputError,
                            tableTypeError: "",
                          });
                        }}
                        label="Table Type"
                        name="Table_type"
                        type="dropdown"
                        values={[
                          "-- Select --",
                          "Regular Table",
                          "Partitioned Table",
                        ]}
                        value={inputs?.table_type}
                      />

                    )}
                    <span className="text-red-600">
                      {inputError.tableTypeError}
                    </span>
                    {inputs.importDestination === "BQ Table" && inputs.frequency !== "Hourly" ? (
                      <Input
                        label="Date Column"
                        name="date_column"
                        type="text"
                        value={inputs.date_column}
                        onChange={handleChange}
                      />
                    ) : ""}
                    <span className="text-red-600">
                      {inputError.dateColumnError}
                    </span>
                    {inputs.importDestination === "Gsheet" && (
                      <div>
                        <Input
                          label="Google Sheet URL"
                          name="google_sheet_output_url"
                          type="text"
                          value={inputs.google_sheet_output_url}
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.google_sheet_output_urlError}
                        </span>

                        <div>
                          <div className="mt-5">
                            <span className="ml-2 text-black text-sm">
                              Service account to give access -
                            </span>
                          </div>
                          <div className="flex">
                            <span className="ml-2 text-black text-sm font-bold">
                              ingestion-projects@databeat-internal.iam.gserviceaccount.com
                            </span>
                            <FaCopy
                              className="h-4 w-4 ml-3 mt-1 cursor-pointer"
                              style={{ color: copyColor ? "blue" : "black" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  "ingestion-projects@databeat-internal.iam.gserviceaccount.com"
                                );
                                setCopyColor(true);
                              }}
                            />
                            {copyColor && (
                              <span className="ml-2 text-blue-500 text-sm font-bold">
                                Copied!
                              </span>
                            )}
                          </div>
                        </div>
                        <Input
                          label="Data push Tab Name"
                          name="output_sheetname"
                          type="text"
                          value={inputs.output_sheetname}
                          onChange={handleChange}
                        />
                        <span className="text-red-600">
                          {inputError.output_sheetnameError}
                        </span>
                      </div>
                    )}
                    {/* PARTITION COLUMN */}
                    {/* {inputs.table_type === "Partitioned Table" && inputs.frequency !== "Hourly" ?
                        (
                          <Input
                            label="Partition Column"
                            name="partition_column"
                            type="text"
                            value={inputs.partition_column}
                            onChange={handleChange}
                          />
                        ) : ""}
                      <span className="text-red-600">
                        {inputError.partitionColumnError}
                      </span> */}
                    {/*  */}
                    {inputs.importDestination === "BQ Table" && (
                      <Input
                        label="BQ Table Path"
                        name="bq_table_name"
                        type="text"
                        value={inputs.bq_table_name}
                        onChange={handleChange}
                      />
                    )}
                    <span className="text-red-600">
                      {inputError.bq_table_nameError}
                    </span>
                    {/*  */}

                    <div>
                      {/*  */}
                      <Input
                        label="Select Sevice Account"
                        name="select_servicea_ccount"
                        type="text"
                        value={localStorage.getItem("selectedServiceAccount")}
                        disabled={"disabled"}
                      />
                    </div>
                    {/* {console.log("allReportsData____", allReportsData)} */}
                    {/* {allReportsData.length > 0 && (
                      <Input
                        onChange={(e) => {
                          setInputs({
                            ...inputs,
                            reportName: e,
                            report_id: allReportsData
                              .filter((report) => report.report_name == e)[0]
                              .report_id.toString(),
                          });
                          setInputError({ ...inputError, reportNameError: "" });
                        }}
                        label="Report Name"
                        name="reportName"
                        type="dropdown"
                        values={allReportNames}
                        value={
                          allReportsData.filter(
                            (report) => report.report_id == inputs.report_id
                          ).length > 0
                            ? allReportsData.filter(
                              (report) => report.report_id == inputs.report_id
                            )[0].report_name
                            : ""
                        }
                      />
                    )} */}
                    {/* <Input
                        // onChange={(e) => {
                        //   setInputs({
                        //     ...inputs,
                        //     reportName: e,
                        //     report_id: allReportsData
                        //       .filter((report) => report.report_name == e)[0]
                        //       .report_id.toString(),
                        //   });
                        //   setInputError({ ...inputError, reportNameError: "" });
                        // }}
                        label="S3 Location"
                        name="reportName"
                        type="text"
                        values={allReportNames}

                      /> */}

                    <Input
                      label="S3 Location"
                      name="s3_location"
                      type="text"
                      value={inputs.s3_location}
                      onChange={handleChange}
                    />
                    <span className="text-red-600">
                      {inputError.s3LocationError}
                    </span>

                    <div className="my-2">
                      <label
                        htmlFor="multi"
                        className="flex text-sm font-semibold text-ai-primary-dark"
                      >
                        Email List
                      </label>
                      <div className="mt-1">
                        <InputChip
                          values={inputs.email_list}
                          onChange={(e) =>
                            setInputs({ ...inputs, email_list: e })
                          }
                        />
                        <span className="text-red-600">
                          {inputError.email_listError}
                        </span>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
            {/* {toast.message && (
              <ToastMsg
                msg={toast.message}
                isError={toast.type}
                setToast={setToast}
              />
            )} */}

          </div>


          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => onClose()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Cancel
              </button>
              <button
                onClick={onCreateImport}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal"
              >
                {editImport ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      ) : (<p></p>)}
      {toast.message && (
        <ToastMsg
          msg={toast.message}
          isError={toast.type}
          setToast={setToast}
        />
      )}
    </div>
  );
};

export default CreateImport;
