import React from "react";
import ToolTipText from "../../Tooltips";

function SimpleInput({
  label,
  type,
  value,
  name,
  defaultValue,
  required,
  disabled,
  placeholder,
  error,
  onChange,
  children,
  icon,
  isTooltip,
  ToolTipDescription,
}) {
  return (
    <div className="w-full flex flex-col gap-x-2 ">
      {label && (
        <label
          htmlFor={name}
          className="capitalize mb-1 text-sm flex ml-1  font-semibold text-ai-primary-dark tracking-wide "
        >
          {label}
          {required && (
            <span className="text-ai-primary-text-dark ml-1"> * </span>
          )}
          {isTooltip && <ToolTipText text={ToolTipDescription} />}
        </label>
      )}

      <div className="w-full">
        <div className="relative flex gap-2 items-center">
          {icon && (
            <span className="absolute inset-y-0 left-0 flex items-center px-3">
              {icon()}
            </span>
          )}
          <input
            name={name}
            type={type}
            id={name}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            autoComplete={name}
            onWheel={(event) => {
              if (type === "number") {
                event.currentTarget.blur();
              }
            }}
            placeholder={placeholder ? placeholder : ""}
            required={required}
            disabled={disabled}
            className={`border w-full py-2.5 placeholder-ai-primary-dark placeholder-opacity-50 border-ai-primary-grat-unselect-border shadow-sm  text-ai-primary-dark  text-sm font-semibold rounded-md  focus:ring-ai-primary-teal focus:shadow-ai-input-focus focus:border-ai-primary-teal transition ${
              error && "border-ai-secondary-red"
            } ${icon && "pl-10"} ${disabled && "bg-ai-primary-gray"}`}
          />
          {children && <div className="col-span-1">{children}</div>}
        </div>

        {error && (
          <p className="ml-1 mt-1 text-xs tracking-wider font-medium text-ai-secondary-red">
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

export default SimpleInput;
