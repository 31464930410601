import React, { Fragment, useEffect, useState } from "react";
import FilterTable from "../Table/FilterTable";
import moment from "moment";

function ImportLogs(props) {
  const [data, setData] = useState({
    loading: true,
    auditHeaders: [
      { align: "left", value: "Import Name" },
      { align: "left", value: "Date" },
      { align: "left", value: "Run Status" },
      { align: "left", value: "Rows Processed" },
      { align: "left", value: "Run Time" },
      { align: "left", value: "Connection Name" },
      { align: "left", value: "Error" },
    ],
    auditLogs: props.data,
  });

  useEffect(() => {
    let responseData = {
      audit_logs: props.data,
    };
    let active_imports = {};

    let newAuditLogs = [],
      repeated_logs = {};
    for (let x in responseData.audit_logs) {
      let this_log = responseData.audit_logs[x];
      let tenant_name = responseData.audit_logs[x].tenant_name;

      let newLog = {
        "Import Name": {
          type: "text",
          align: "left",
          id: "import_name",
          value: this_log.program_name,
        },
        Date: {
          type: "text",
          align: "left",
          id: "Date",
          value: this_log.end_time.slice(0, 19),
        },
        Frequency: {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.imports_data ? this_log.imports_data.frequency : "-",
        },
        "Date/Day/Time": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: "-",
        },
        "Time of Run": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.imports_data
            ? this_log.imports_data.time_of_run
            : "-",
        },
        "Run Status": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.status,
        },
        Error: {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.error,
        },
        "Rows Processed": {
          type: "number",
          align: "left",
          id: "Frequency",
          value: parseInt(this_log.rows_written),
        },
        "Data Processed": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.size
            ? `${parseFloat(this_log.size).toFixed(2)} MB`
            : "0",
        },
        "Start Time": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.start_time,
        },
        "End Time": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.end_time,
        },
        "Run Time": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.run_duration.slice(0, 7),
        },
        "Connection Name": {
          type: "text",
          align: "left",
          id: "Frequency",
          value: this_log.connection_type.toUpperCase(),
        },
      };
      // newAuditLogs.push(newLog);

      // take email import
      if (this_log.connection_type.toLowerCase() === "email") {
        // check if that email import exists in repeated variable

        if (repeated_logs[this_log.import_id]) {
          if (repeated_logs[this_log.import_id].includes(this_log.time)) {
            newAuditLogs.push(newLog);
            repeated_logs[this_log.import_id].push(this_log.date);
          } else {
          }
        } else {
          repeated_logs[this_log.import_id] = [];
          repeated_logs[this_log.import_id].push(this_log.date);

          newAuditLogs.push(newLog);
        }
      } else {
        newAuditLogs.push(newLog);
      }
      if (moment(this_log.date).diff(moment(), "days") < 2)
        if (active_imports[tenant_name]) {
          active_imports[tenant_name].rows_written += parseFloat(
            this_log.rows_written
          );
          active_imports[tenant_name].data_processed += parseFloat(
            this_log.size
          );
        } else {
          active_imports[tenant_name] = {
            active_imports: 1,
            failed_imports: 0,
            rows_written: parseFloat(this_log.rows_written),
            data_processed: parseFloat(this_log.size),
          };
        }
      if (moment(this_log.date).diff(moment(), "days") < 2)
        if (active_imports[tenant_name]) {
          active_imports[tenant_name].rows_written += parseFloat(
            this_log.rows_written
          );
          active_imports[tenant_name].data_processed += parseFloat(
            this_log.size
          );
        } else {
          active_imports[tenant_name] = {
            active_imports: 1,
            failed_imports: 0,
            rows_written: parseFloat(this_log.rows_written),
            data_processed: parseFloat(this_log.size),
          };
        }
    }

    setData({
      ...data,
      auditLogs: newAuditLogs,

      loading: false,
    });
  }, []);

  if (data.loading) return <div>Loading</div>;

  return (
    <FilterTable
      data={data.auditLogs}
      columns={data.auditHeaders}
      actions={[]}
      // paginationLimit={25}
      tableCSS={{ headerColor: "#E2EAE3", color: "black" }}
    />
  );
}

export default ImportLogs;
