import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiFillPlusCircle, AiOutlineLoading3Quarters } from "react-icons/ai";
import Multiselect from "../../../components/Inputs/Multiselect";
import moment from "moment";
import { apiCallGet, apiCallPost } from "../../../api/RestAPICalls";
import { BASE_URL } from "../../../utils/variables";
import Modal from "../../../components/Modal";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import DataTable from "../../../components/Table";
import ModalItemUser from "./createUser";
import ModalItemTenant from "./createTenant";
import ModalItemRemoveUser from "./removeUser";
import ModalItemRemoveTenant from "./removeTenant";
import SimpleTable from "../../../components/Table/simple";
import ModalItemReIssuePassword from "./reIssuePassword";
import Input from "../../../components/Inputs/Input";
import { IoIosCloseCircle } from "react-icons/io";
import Dropdown from "../../../components/Inputs/Select/indexNew";
import MultiSelectChips from "../../../components/Inputs/MultiSelectChips/MultiSelectChips";
//import onRemoveUser from "./removeUser";

// Global Varibles using for only temporary use

export default function UserbaseSuperAdmin() {
  const [usersData, setUsersData] = useState({
    loading: true,
    headers: [
      "User Name",
      "Email",
      "Tenant",
      "Last logged in",
      "User Status",
      "Actions",
      // "Re-issue Password",
      //      "Remove",
      // "Transfer Tenant",
    ],
    stats: {
      total: "7,421",
      success: "98.16%",
      attentionNeeded: "101",
    },

    allUsers: [],
  });

  const [tenantTableData, setTenantTableData] = useState({
    headers: [
      { align: "left", value: "Tenant Name" },
      { align: "right", value: "Number of users" },
      { align: "right", value: "Number of connections" },
    ],

    all_tenants: [],
  });

  const [state, setState] = useState({
    editUser: 0,
    transferTenant: 0,
    verifyTransferTenant: 0,
    tenantRemoveID: 0,
    removeUserID: 0,
    reIssuePassword: 0,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const [transerTenantData, setTranserTenantData] = useState({});
  useEffect(() => {
    _getUsersandTenantsAPICall();
  }, []);

  const _getUsersandTenantsAPICall = () => {
    let userAndTenantURL = BASE_URL + "super/users";

    apiCallGet(
      userAndTenantURL,
      _onSuccessUsersAndTenantFetch,
      _onFailureUsersAndTenantFetch
    );
  };

  const _onSuccessUsersAndTenantFetch = (response) => {
    var allTenats = [];
    let tempTenants = {};

    for (let i in response.tenants) {
      tempTenants["Tenant ID"] = {
        type: "text",
        align: "left",
        id: "tenant_id",
        value: response.tenants[i].tenant_id,
      };
      tempTenants["Tenant Name"] = {
        type: "text",
        align: "left",
        id: "tenant_name",
        value: response.tenants[i].tenant_name,
        tenantId: response.tenants[i].tenant_id,
      };
      tempTenants["Number of users"] = {
        type: "number",
        align: "right",
        id: "tenant_users",
        value:
          response.tenants[i].total_users < 0
            ? "0"
            : response.tenants[i].total_users,
      };
      // console.log("NUMBER OF USERS!", response.tenants[i].tenant_name, response.tenants[i].total_users);
      tempTenants["Number of connections"] = {
        type: "number",
        align: "right",
        id: "tenant_users",
        value: response.tenants[i].total_connections,
      };
      tempTenants["Remove Tenant"] = {
        type: "number",
        align: "right",
        id: "tenant_users",
        value: response.tenants[i].is_removed,
      };

      if (response.tenants[i].is_removed) {
      } else {
        allTenats.push(tempTenants);
        tempTenants = {};
      }
    }

    let users = [];
    let tempUsers = {};
    for (let j in response.users) {
      tempUsers["Account ID"] = response.users[j].account_id;
      tempUsers["User Name"] = response.users[j].full_name;
      tempUsers["Email"] = response.users[j].email;
      tempUsers["First Name"] = response.users[j]?.first_name;
      tempUsers["Last Name"] = response.users[j]?.last_name;
      tempUsers["Mobile"] = response.users[j]?.mobile;

      tempUsers["Tenant"] = [];
      let allTens = [];
      response.tenants.forEach((tenant) => {
        if (
          response.users[j].tenant_ids.includes(tenant.tenant_id.toString())
        ) {
          allTens.push(tenant.tenant_name);
        }
      });

      tempUsers["Tenant"] = allTens.join(", ");
      tempUsers["Tenant IDs"] = [];
      let allIds = [];
      response.tenants.forEach((tenant) => {
        if (
          response.users[j].tenant_ids.includes(tenant.tenant_id.toString())
        ) {
          allIds.push(tenant.tenant_id);
        }
      });
      tempUsers["Tenant IDs"] = allIds.join(",");

      tempUsers["Last logged in"] =
        moment(response.users[j].last_login).format("YYYY-MM-DD hh:mm a") ||
        "Not yet";

      tempUsers["User Status"] = response.users[j].is_active;
      tempUsers["RemoveUser"] = response.users[j].is_user_removed;
      tempUsers["Re-issue Password"] = response.users[j].forgot_password;

      if (response.users[j].is_user_removed) {
      } else {
        users.push(tempUsers);
        tempUsers = {};
      }
    }
    //console.log("TESTOFNEWID's", users);

    setTenantTableData({
      ...tenantTableData,
      all_tenants: allTenats,
    });
    setUsersData({ ...usersData, allUsers: users, loading: false });
  };

  const _onFailureUsersAndTenantFetch = (responseJson) => {};

  const modalRef = useRef();
  const modalRefRemoveUser = useRef();
  const modalRefEditUser = useRef();
  const modalRefReIssuePassword = useRef();

  const modalRefTenant = useRef();
  const modalRefRemoveTenant = useRef();
  const modalRefTransferTenant = useRef();
  const modalRefVerifyTransferTenant = useRef();

  const ModalItemVerifyTransferTenant = (props) => {
    const [inputs, setInputs] = useState({
      //confirm: ""
    });
    const [state, setState] = useState({
      loading: false,
    });

    const [toast, setToast] = useState({
      type: false,
      message: "",
    });
    // const [verifyTenantState, setVerifyTenantState] = useState({
    //   loading: false,
    // });
    const handleChange = (e) => {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    };
    const onVerifyTenant = async () => {
      //console.log("payload 2", props.data)
      let sendTenantsToTransfer = BASE_URL + "switch-user/";
      let bodyParams = {
        details: props.data,
      };

      await apiCallPost(
        sendTenantsToTransfer,
        bodyParams,
        onSuccessTenantSendinfo,
        onFailureTenantSendinfo
      );
    };
    //console.log("account_id:", JSON.stringify(props.data[0].old_user_id));
    const onSuccessTenantSendinfo = async (response) => {
      setState({ loading: true });
      await removeSender();
      // console.log("TRANSFER SUCCESS")
      setState({ loading: false });

      if (removeSender()) {
        setToast({
          type: false,
          message: "Tenants Transferred successfully",
        });
        modalRefRemoveTenant.current.closeModal();
        window.location.reload(true);
      } else {
        alert("Something went wrong");
      }
    };
    const onFailureTenantSendinfo = (response) => {
      setState({ loading: true });
      // console.log("Data fetch failed:", response)
      setState({ loading: false });
      setToast({
        type: true,
        message: "Something Went Wrong",
      });

      // console.log("TRANSFER FAILURE")
    };
    const removeSender = async () => {
      let removeUserURL = BASE_URL + "remove/user";
      let bodyParams = {
        account_id: props.data[0].old_user_id,
      };

      await apiCallPost(
        removeUserURL,
        bodyParams,
        _onSuccessRemoveUser,
        _onFailureRemoveUser
      );
    };
    const _onSuccessRemoveUser = (response) => {
      //console.log("REMOVE SUCCESS")
      setToast({
        type: false,
        message: "User Removed successfully",
      });

      return true;
    };
    const _onFailureRemoveUser = (response) => {
      // console.log("REMOVE FAILURE!!!!!!!!")
      setToast({
        type: false,
        message: "There was an error in Removing the User",
      });
      return false;
    };

    // <ToastMsg
    //   msg={toast.message}
    //   isError={toast.type}
    //   setToast={setToast}
    // />
    if (state.loading) {
      return (
        <Modal ref={modalRefTransferTenant} className="p-2">
          <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
            <h2 className="text-base text-ai-primary-dark  font-bold ">
              Transfer Tenant
            </h2>
            <IoIosCloseCircle
              className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
              onClick={() => {
                modalRefTransferTenant.current.closeModal();
              }}
            />
          </div>
          <div className="gap-100 h-96 w-96 mx-auto text-center flex justify-center flex-col ">
            <AiOutlineLoading3Quarters
              className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
              size={40}
            />
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal ref={modalRefVerifyTransferTenant}>
          {" "}
          <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
            <h2 className="text-base text-ai-primary-dark  font-bold ">
              Confirm Transfer Tenant
            </h2>
            <IoIosCloseCircle
              className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
              onClick={() => {
                modalRefVerifyTransferTenant.current.closeModal();
              }}
            />
          </div>
          <div className="p-4 ">
            <Input
              label="Please type CONFIRM to Transfer the Tenants"
              type="text"
              name="remove"
              id="remove"
              onChange={handleChange}
              value={inputs.remove}
            />
            {toast.message && (
              <ToastMsg
                msg={toast.message}
                isError={toast.type}
                setToast={setToast}
              />
            )}
            <button
              className={` max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none ${
                inputs.remove !== "CONFIRM" && "disabled:opacity-50"
              } `}
              onClick={() => onVerifyTenant()}
              disabled={inputs.remove === "CONFIRM" ? false : true}
            >
              Transfer
            </button>
          </div>
        </Modal>
      );
    }
  };

  const ModalItemTransferTenant = ({ user, callbackFromchild }) => {
    const [inputs, setInputs] = useState({
      loading: true,
      tenant: user["Tenant"]?.split(","),
      full_name: user["User Name"],
      tenant_users: user["Tenant Users"],
      account_id: user["Account ID"],
    });
    //console.log("Test!!!!", user);
    const [error, setError] = useState({
      tenantError: "",
      tenantUsersError: "",
    });
    const [tenantsUserData, setTenantsUserData] = useState();
    const [state, setState] = useState({
      loading: false,
    });
    //console.log("TENANTS USER DATA", tenantsUserData);

    const [toast, setToast] = useState({
      type: false,
      message: "",
    });

    const [disableSend, setDisableSend] = useState({
      type: false,
      message: "",
      errCount: 0,
    });
    let currentIdsStr = user["Tenant IDs"];
    let currentIdsArr = [];
    currentIdsArr = currentIdsStr?.split(",");
    //console.log("TESTNEWID's",typeof currentIdsArr)
    useEffect(() => {
      setState({ loading: true });
      let getTenantsInfo = BASE_URL + "switch-user-fetch/";
      let bodyParams = {
        tenant_ids: currentIdsArr,
      };
      // console.log("TYPEOFBODYPARAMS", typeof bodyParams)
      apiCallPost(
        getTenantsInfo,
        bodyParams,
        onSuccessTenantinfo,
        onFailureTenantinfo
      );
    }, []);
    //console.log("Tenants Log",user.["Tenant"])
    const onSuccessTenantinfo = (response) => {
      //console.log("This is the response when modal loads", response)
      function removeAccount(account_id) {
        response.data.forEach((tenant) => {
          tenant.users = tenant.users.filter(
            (user) => user.account_id !== account_id
          );
        });
        return response;
      }
      //console.log("FILTERED DATA!!!!", removeAccount(inputs.account_id))
      removeAccount(inputs.account_id);
      setTenantsUserData(response.data);
      setState({ loading: false });
    };
    // console.log("TESTDATA!!!AGAIN", tenantsUserData);
    const onFailureTenantinfo = (response) => {
      console.log("Data fetch failed:", response);
    };

    const [dataToSend, setDataToSend] = useState([]);

    const openConfirmModal = async () => {
      if (tenantsUserData?.length === dataToSend?.length) {
        await callbackFromchild(dataToSend);
        await modalRefVerifyTransferTenant.current.openModal();
      } else {
        setToast({
          type: true,
          message: "Select all Tenants",
        });
      }
    };

    if (state.loading) {
      return (
        <Modal ref={modalRefTransferTenant} className="p-2">
          <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
            <h2 className="text-base text-ai-primary-dark  font-bold ">
              Transfer Tenant
            </h2>
            <IoIosCloseCircle
              className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
              onClick={() => {
                modalRefTransferTenant.current.closeModal();
              }}
            />
          </div>
          <div className="gap-100 h-96 w-96 mx-auto text-center flex justify-center flex-col ">
            <AiOutlineLoading3Quarters
              className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
              size={40}
            />
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal ref={modalRefTransferTenant} className="p-2">
          <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
            <h2 className="text-base text-ai-primary-dark  font-bold ">
              Transfer Tenant
            </h2>
            <IoIosCloseCircle
              className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
              onClick={() => {
                modalRefTransferTenant.current.closeModal();
              }}
            />
          </div>
          <div className="">
            <h6 className="py-2 pt-4 px-6 font-bold text-ai-primary-dark">
              From, <span className="text-teal-500">{inputs.full_name}</span>
            </h6>
          </div>

          <div className="">
            {tenantsUserData?.map((e) => {
              return (
                <div className="">
                  <div className="grid grid-cols-2 gap-x-4 gap-y-4 px-2">
                    <div className="col-span-1 py-2 px-4 text-base text-ai-primary-dark font-bold text-sm my-auto ">
                      {e.tenant_name}{" "}
                    </div>
                    <div className={`ol-span-1 py-2 px-4 rounded-sm`}>
                      <Dropdown
                        className={`w-100 `}
                        onChange={(q) => {
                          // console.log("tenant_id:", parseInt(e.tenant_id), "new_user_id:", q, "old_user_id", inputs.account_id)
                          // q ? console.log("True") : console.log("False");
                          var transferData = {
                            tenant_id: parseInt(e.tenant_id),
                            old_user_id: inputs.account_id,
                            new_user_id: q,
                          };
                          setDataToSend((dataToSend) => [
                            ...dataToSend,
                            transferData,
                          ]);
                          //console.log("DATA TO SEND", dataToSend)
                          if (q === inputs.account_id) {
                            setToast({
                              type: true,
                              message:
                                "One or more senders and recievers are the same!",
                            });
                          } else {
                            setToast({
                              type: false,
                              message: "",
                            });
                          }

                          //console.log("THIS IS THE FINAL DATA", dataToSend);
                        }}
                        values={e?.users?.map((user, ind) => {
                          return {
                            label: user.full_name,
                            value: user.account_id,
                            email: user.email,
                          };
                        })}
                        value="Select User"
                        required={true}
                        tooltip={true}
                      ></Dropdown>
                    </div>
                  </div>
                </div>
              );
            })}
            {toast.message && (
              <ToastMsg
                msg={toast.message}
                isError={toast.type}
                setToast={setToast}
              />
            )}

            <button
              className={` max-w-max font-medium m-4 py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none `}
              //onClick={onTransferTenant}
              onClick={() => {
                openConfirmModal();
              }}
            >
              {state.loading ? (
                <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col ">
                  <AiOutlineLoading3Quarters
                    className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                    size={40}
                  />
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </Modal>
      );
    }
  };

  const ModalItemEditUser = ({ user }) => {
    // console.log("this is user", user);
    const [inputs, setInputs] = useState({
      tenant: user["Tenant"]?.split(", "),
      tenant_ids: user["Tenant IDs"]?.split(", "),
      full_name: user["User Name"],
      first_name: user["First Name"],
      last_name: user["Last Name"],
      mobile: user["Mobile"],
      user_type: "normal_user",
      email: user["Email"],
    });

    const [error, setError] = useState({
      fullNameError: "",
      tenantError: "",
      tenantUserError: "",
    });

    const [state, setState] = useState({
      loading: false,
    });

    const [toast, setToast] = useState({
      type: false,
      message: "",
    });

    const handleChange = (e) => {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
      setError({
        fullNameError: "",
        tenantError: "",
      });
    };
    const formValidation = () => {
      let isValid = true;
      if (!inputs.full_name.trim() || inputs.full_name.trim().length < 4) {
        setError((PrevError) => {
          return {
            ...PrevError,
            fullNameError: "Fullname is Invalid or missing",
          };
        });
        isValid = false;
      }

      if (!inputs.tenant.length) {
        setError((PrevError) => {
          return { ...PrevError, tenantError: "Tenant is required" };
        });
        isValid = false;
      }
      return isValid;
    };
    const onEditUser = async (e) => {
      setState({ success: false, error: false, loading: true });
      let updateUserStatusURL = BASE_URL + "user/update";
      const onSuccessUserUpdate = () => {
        setToast({
          type: false,
          message: "User Updated successfully",
        });
        window.location.reload();
      };

      const onFailureUserUpdate = () => {
        setError({
          tenantUserError: "",
        });
        setToast({
          type: true,
          message: "Something went wrong!",
        });
      };
      let isFormValid = formValidation();
      if (isFormValid) {
        try {
          setState({ loading: true });

          let bodyParams = {
            account_id: user["Account ID"],
            username: inputs.full_name,
            tenant_ids: inputs.tenant,
            updateType: "userInfo",
            first_name: inputs?.first_name,
            last_name: inputs?.last_name,
            mobile: inputs?.mobile,
          };
          // console.log("TYPEOFBODYPARAMS", typeof bodyParams)
          apiCallPost(
            updateUserStatusURL,
            bodyParams,
            onSuccessUserUpdate,
            onFailureUserUpdate
          );

          // setState({ ...state, loading: false });
          // setToast({
          //   type: false,
          //   message: "User Updated successfully",
          // });
          // setError({

          //   tenantUserError: "",
          // });
        } catch (error) {
          // setState({ ...state, loading: false });
          setError({
            tenantUserError: "Kindly Choose all users!",
          });
          setToast({
            type: false,
            message: error,
          });
        }
      }
    };

    console.log("tenantTableData_", tenantTableData.all_tenants);
    return (
      <Modal ref={modalRefEditUser}>
        <div>
          <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
            <h2 className="text-base text-ai-primary-dark  font-bold ">
              Edit User
            </h2>
            <IoIosCloseCircle
              className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
              onClick={() => {
                modalRefEditUser.current.closeModal();
              }}
            />
          </div>
          <div className="p-4 flex flex-col gap-2">
            {/* edit user form */}
            <div className="grid grid-cols-2  gap-x-2 gap-y-2">
              <Input
                label="First Name"
                placeholder="First Name"
                type="text"
                name={inputs.full_name ? "full_name" : "first_name"}
                id={inputs.full_name ? "full_name" : "first_name"}
                onChange={handleChange}
                value={inputs.first_name ? inputs.first_name : inputs.full_name}
                // error={inputs.full_name ? error.fullNameError : error?.firstNameError}
              />
              <Input
                label="Last Name"
                type="text"
                placeholder="Last Name"
                name="last_name"
                id="last_name"
                onChange={handleChange}
                value={inputs.last_name}
                // error={error.fullNameError}
              />
              <Input
                label="Phone"
                placeholder="Phone"
                type="text"
                id="mobile"
                name="mobile"
                onChange={handleChange}
                value={inputs.mobile}
                error={error?.mobileError}
              />

              <Input
                label="Email"
                placeholder="Email"
                type="text"
                name="email"
                id="email"
                onChange={handleChange}
                value={inputs.email}
                error={error?.emailError}
                disabled={true}
              />
            </div>
            <div className="col-span-6 sm:col-span-6">
              {/* <Multiselect
                onChange={(e) => {
                  let tenant_ids = [];
                  tenantTableData.all_tenants.forEach((tenant) => {
                    if (e.includes(tenant["Tenant Name"]["value"])) {
                      tenant_ids.push(tenant["Tenant ID"]["value"].toString());
                    } else {
                      // console.log("adding new tenant error==>", tenant);
                    }
                  });
                  setInputs({ ...inputs, tenant: tenant_ids });
                  setError({
                    fullNameError: "",
                    tenantError: "",
                  });
                }}
                label="Tenant"
                name="tenant"
                value={inputs.tenant}
                values={[
                  ...tenantTableData?.all_tenants?.map(
                    (item) => item["Tenant Name"]
                  ),
                ]}
              /> */}
              <MultiSelectChips
                onChange={(e) => {
                  let tenant_ids = [];
                  tenantTableData.all_tenants.forEach((tenant) => {
                    if (e.includes(tenant["Tenant Name"]["value"])) {
                      tenant_ids.push(tenant["Tenant ID"]["value"].toString());

                      // console.log("test", tenant_ids);
                     } else {
               
                    }
                  });
                  setInputs({ ...inputs, tenant: tenant_ids });
                  setError({
                    fullNameError: "",
                    tenantError: "",
                  });
                  // console.log("hussain", e);
                }}
                label="Tenants"
                value={inputs.tenant}
                values={[
                  ...tenantTableData?.all_tenants?.map(
                    (item) => item["Tenant Name"]
                  ),
                ]}
              />
              <span className="text-red-600">{error.tenantError}</span>
            </div>

            {toast.message && (
              <ToastMsg
                msg={toast.message}
                isError={toast.type}
                setToast={setToast}
              />
            )}
            <button
              className={` max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none `}
              onClick={onEditUser}
            >
              {state.loading ? (
                <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col ">
                  <AiOutlineLoading3Quarters
                    className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                    size={40}
                  />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const _onClickRemoveUser = (e) => {
    setState({ ...state, removeUserID: e });
    setTimeout(() => {
      modalRefRemoveUser.current.openModal();
    }, 100);
  };

  const _onClickEditUser = (a) => {
    setState({ ...state, editUser: a });
    setTimeout(() => {
      modalRefEditUser?.current?.openModal();
    }, 100);
  };

  const _onClickTransferTenant = (a) => {
    setState({ ...state, transferTenant: a });
    setTimeout(() => {
      modalRefTransferTenant.current.openModal();
    }, 100);
  };

  const _UpdateUserStatusAPICall = (newStatus, user) => {
    let updateUserStatusURL = BASE_URL + "user/update";
    let bodyParams = {
      account_id: user["Account ID"],
      updateType: "status",
      status: newStatus,
    };

    apiCallPost(
      updateUserStatusURL,
      bodyParams,
      async (responseJson) => {
        setToast({
          type: false,
          message:
            responseJson.response +
            " status" +
            (newStatus ? " Active" : " Inactive"),
        });
        window.location.reload();
      },
      async (responseJson) => {
        setToast({
          type: true,
          message: responseJson.response,
        });
      }
    );
  };

  const _onClickRemoveTenant = (e) => {
    setState({ ...state, tenantRemoveID: e["Tenant ID"].value });
    setTimeout(() => {
      modalRefRemoveTenant.current.openModal();
    }, 100);
  };

  const _onReIssuePassword = (e) => {
    // console.log("_onReIssuePassword event-->", e);
    setState({ ...state, reIssuePassword: e });
    setTimeout(() => {
      modalRefReIssuePassword.current.openModal();
    }, 100);
  };
  if (usersData.loading) {
    return (
      <div className="h-screen max-w-screen mx-auto text-center flex justify-center flex-col">
        <AiOutlineLoading3Quarters
          className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
          size={40}
        />
      </div>
    );
  }

  const callbackfun = (paylaod) => {
    //console.log("paylaodok", paylaod)
    setTranserTenantData(paylaod);
  };

  return (
    <Fragment>
      {toast.message && (
        <ToastMsg
          msg={toast.message}
          isError={toast.type}
          isPageToast={true}
          setToast={setToast}
        />
      )}
      <ModalItemUser modalRef={modalRef} tenantTableData={tenantTableData} />
      <ModalItemTenant modalRefTenant={modalRefTenant} />
      <ModalItemRemoveUser
        usersData={usersData}
        modalRefRemoveUser={modalRefRemoveUser}
        removeUserID={state.removeUserID}
      />
      <ModalItemReIssuePassword
        modalRefReIssuePassword={modalRefReIssuePassword}
        reIssuePasswordEmail={state.reIssuePassword}
      />
      <ModalItemRemoveTenant
        tenantTableData={tenantTableData}
        modalRefRemoveTenant={modalRefRemoveTenant}
        tenantRemoveID={state.tenantRemoveID}
      />
      <ModalItemEditUser user={state.editUser} />
      <ModalItemTransferTenant
        user={state.transferTenant}
        callbackFromchild={callbackfun}
      />
      <ModalItemVerifyTransferTenant
        send={state.verifyTransferTenant}
        data={transerTenantData}
      />

      <main className="m-4">
        <div className=" my-5 md:flex md:justify-between max-w-full">
          <div className="text-3xl font-bold flex justify-between items-center">
            {/* Welcome, Super Admin */}
            Users and Tenants
          </div>
          {/* <div className="flex">
            <OverAllTenantSelection />

            <div className="flex justify-between items-center"></div>
          </div> */}
        </div>

        <div className="mt-8">
          <div className="mt-10 my-5 flex justify-between max-w-full">
            <div className="text-2xl font-bold flex justify-between items-center">
              Overall Users
            </div>
            <div className="flex">
              <div className="flex justify-between items-center">
                <button
                  onClick={() => modalRef.current.openModal()}
                  className="p-2 flex  items-center cursor-pointer rounded bg-ai-primary-text-teal text-white uppercase text-xs font-semibold"
                >
                  <AiFillPlusCircle className="w-5 h-5 mr-2" />
                  Create User
                </button>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------Table Started--------------------------------  */}

          <div className="flex flex-col mt-5">
            <div
              className="overflow-x-auto shadow-xl"
              style={{ maxHeight: "500px" }}
            >
              <div className="align-middle inline-block min-w-full">
                <DataTable
                  headers={usersData.headers}
                  content={usersData.allUsers}
                  values
                  onClickRemoveUser={(e) => {
                    _onClickRemoveUser(e);
                  }}
                  onUserStatusChange={(e, k) => {
                    _UpdateUserStatusAPICall(e, k);
                  }}
                  onClickEditUser={(a) => {
                    _onClickEditUser(a);
                  }}
                  onClickTransferTenant={(a) => {
                    _onClickTransferTenant(a);
                  }}
                  onReIssuePassword={(e) => {
                    _onReIssuePassword(e);
                  }}
                  tableCSS={{ headerColor: "#E2EAE3", color: "black" }}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 my-5 flex justify-between max-w-full">
            <div className="text-2xl font-bold">Overall Tenants </div>
            <div>
              <button
                onClick={() => modalRefTenant.current.openModal()}
                className="p-2 flex  items-center cursor-pointer rounded bg-ai-primary-text-teal text-white uppercase text-xs font-semibold"
              >
                <AiFillPlusCircle className="w-5 h-5 mr-2" />
                Create New Tenant
              </button>
            </div>
          </div>

          {/* ------------------------------------------------Table Started--------------------------------  */}

          <div className="flex flex-col mt-5">
            <div
              className="overflow-x-auto shadow-xl"
              style={{ maxHeight: "500px" }}
            >
              <div className="align-middle inline-block min-w-full">
                {tenantTableData.all_tenants.length && (
                  <SimpleTable
                    columns={tenantTableData.headers}
                    data={tenantTableData.all_tenants}
                    actions={[
                      {
                        label: "Remove",
                        action: (e) => {
                          _onClickRemoveTenant(e);
                        },
                        style: "text-red-600",
                      },
                    ]}
                    tableCSS={{ headerColor: "#E2EAE3", color: "black" }}
                    defaultSort={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
