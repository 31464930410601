import React from "react";
import { useState } from "react";
import { apiCallPost } from "../../../api/RestAPICalls";
import { v4 as uuidv4 } from "uuid";
import { BASE_URL } from "../../../utils/variables";
import Input from "../../../components/Inputs/Input";
import Modal from "../../../components/Modal";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { useEffect } from "react";
import { MdDelete } from "react-icons/md";

const AddError = React.memo(({ modalRef, selectedItem, data, fetchData }) => {
  const [state, setState] = useState({
    loading: false,
    error: "",
  });
  const [inputs, setInputs] = useState({
    subHeading: "",
    values: [""],
    errorImage: "",
  });

  const addError = (e) => {
    setState({ ...state, loading: true });
    e.preventDefault();
    let body;
    if (selectedItem) {
      body = data.map((item) => {
        return item.id === selectedItem.id ? { ...item, ...inputs } : item;
      });
    } else {
      body = [{ ...inputs, id: uuidv4() }, ...data];
    }
    apiCallPost(
      BASE_URL + "user-guide",
      body,
      (res) => {
        if (res?.error) {
          setState({
            ...state,
            loading: false,
            error: res.error,
          });
        } else {
          setState({ ...state, loading: false, error: "" });
          modalRef.current.closeModal();
          fetchData();
        }
      },
      (err) => {
        setState({
          ...state,
          loading: false,
          error: "Something went wrong",
        });
      }
    );
  };

  const handleAddMore = (e) => {
    inputs.values.push("");
    setInputs({ ...inputs });
  };

  const handleDelete = (i) => {
    inputs.values.splice(i, 1);
    setInputs({ ...inputs });
  };

  useEffect(() => {
    if (selectedItem) {
      setInputs({ ...selectedItem });
    }
    return () => {
      setInputs({ subHeading: "", values: [""] });
    };
  }, [selectedItem]);

  return (
    <Modal ref={modalRef}>
      <>
        <div className="w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            {selectedItem ? "Edit Error" : "Add Error"}
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRef.current.closeModal();
            }}
          />
        </div>
        <form className="px-4 py-2" onSubmit={addError}>
          <Input
            label="Error Title"
            required={true}
            type="text"
            name="subHeading"
            id="subHeading"
            value={inputs?.subHeading}
            onChange={(e) => {
              setInputs({ ...inputs, subHeading: e.target.value });
            }}
          />
          <div className="mb-3">
            <label className="flex text-sm font-semibold text-ai-primary-dark mb-1">
              Error Points
            </label>
            <div className="flex flex-col gap-2">
              {inputs.values.map((error, i) => {
                return (
                  <div className="flex gap-2 items-center">
                    <textarea
                      required
                      value={error}
                      onChange={(e) => {
                        inputs.values.splice(i, 1, e.target.value);
                        setInputs({ ...inputs });
                      }}
                      className={`shadow-sm  focus:ring-ai-primary-teal focus:shadow-ai-input-focus focus:border-ai-primary-teal block w-full sm:text-sm border-gray-300 rounded-md `}
                    />
                    <MdDelete
                      className={`${
                        i === 0 && "invisible"
                      } w-6 h-6 cursor-pointer text-red-400`}
                      onClick={() => handleDelete(i)}
                    />
                  </div>
                );
              })}
            </div>

            <button
              type="button"
              className="text-xs px-2 font-semibold mt-1 underline text-ai-primary-text-teal"
              onClick={handleAddMore}
            >
              Add more
            </button>
          </div>
          <Input
            label="Error Image (URL)"
            type="text"
            name="errorImage"
            id="errorImage"
            value={inputs?.errorImage}
            onChange={(e) => {
              setInputs({ ...inputs, errorImage: e.target.value });
            }}
          />
          {state.error && (
            <p className="my-2 text-ai-primary-text-red ">{state.error}</p>
          )}

          <button
            type="submit"
            className=" max-w-max font-medium py-2   px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none"
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : selectedItem ? (
              "Edit"
            ) : (
              "Add"
            )}
          </button>
        </form>
      </>
    </Modal>
  );
});

export default AddError;
