import React, { useState } from "react";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import ToolTipText from "../../../../components/Tooltips";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";
import { IoIosCloseCircle } from "react-icons/io";
import { apiCallPost } from "../../../../api/RestAPICalls"
import { useHistory } from "react-router-dom";

const CreateConnectionModal = ({ modalRef }) => {
  let history = useHistory();
  const [inputs, setInputs] = useState({
    connectionType: "GAM",
    serviceAccount: "",
    networkID: "",
    connectionName: "",
    email: "",
    password: "",
    access_key_id: "",
    secret_access_key: "",
    project_name: "",
  });



  const [inputError, setInputError] = useState({
    connectionTypeError: "",
    serviceAccountError: "",
    networkIDError: "",
    connectionNameError: "",
    emailError: "",
    passwordError: "",
    accessKeyIdError: "",
    secretAccessKeyError: "",
    projectNameError: "",
  });

  const [state, setState] = useState({
    uploadError: false,
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(true);

  const modalData = [{ name: "E-Mail", img: "/emaillogo.png", url: "email" }, { name: "GAM", img: "/gamlogo.png", url: "GAM" }, { name: "TAM", img: "/tamlogo.png", url: "TAM" }]





  const _sendToCreateConnection = (k) => {

  }

  return (
    <Modal ref={modalRef}>

      <div className="">

        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Create New Connection
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRef.current.closeModal();
            }}
          />
        </div>


      </div>


      <div className="flex justify-center py-10 pb-12 my-4 mb-8 items-center gap-6">
        {modalData.map((k) => (
          <div className="border-1 m-2 hover:bg-gray-200 hover:cursor-pointer transition-all transform hover:scale-110">
            <img src={k.img} className="p-5 w-20 h-20" alt={k.img} onClick={() => history.push(`/dashboard/connections/create/${k.url}`)} />
            <span className="flex justify-center items-center text-center font-bold text-xs">{k.name}</span>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CreateConnectionModal;
