import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import "./index.css";

function ToolTipText({ text }) {
  return (
    <div>
      <div class="tooltip cursor-pointer">
        <BsFillInfoCircleFill className="mt-1 ml-2" />
        <span class="tooltiptext text-xs">{text}</span>
      </div>
    </div>
  );
}

export default ToolTipText;
