import React from "react";

function ToastMsg({ msg, isError, isPageToast, setToast }) {
  setTimeout(() => {
    setToast({ type: false, message: "" });
  }, 6000);
  return (
    msg && (
      <div
        role="alert"
        className={
          isPageToast
            ? "mt-16 sm:mr-6 mt-16 sm:mt-8 mb-6 sm:mb-0 absolute left-0 sm:left-auto right-0 sm:top-0 z-50 bg-white dark:bg-gray-800 shadow-lg rounded flex transition duration-150 ease-in-out"
            : ""
        }
        id="notification"
      >
        <div
          className={
            isPageToast
              ? isError
                ? "rounded-md bg-red-200 p-6"
                : "rounded-md bg-green-200 p-6"
              : isError
                ? "rounded-md bg-red-50 p-4"
                : "rounded-md bg-green-50 p-4"
          }
        >
          <div className="flex">
            <div className="flex-shrink-0">
              {/* <!-- Heroicon name: solid/x-circle --> */}

              {isError ? (
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  />
                </svg>
              )}
            </div>
            <div className="ml-3">
              <h3
                className={
                  isError
                    ? "text-sm font-medium text-red-800"
                    : "text-sm font-medium text-green-800"
                }
              >
                {msg}
              </h3>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ToastMsg;
