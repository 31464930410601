export const configruationSVG = (data) => {
  return (
    <svg
      id="settings"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
    >
      <g id="Group_2" data-name="Group 2" opacity={data.opacity}>
        <g id="Group_1" data-name="Group 1">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M17.8,8.3a8.486,8.486,0,0,0-1.1-2.6l1-2.1L16.3,2.2l-2.1,1a7.715,7.715,0,0,0-2.5-1L11,0H9L8.2,2.2A7.956,7.956,0,0,0,5.7,3.3l-2.1-1L2.2,3.6l1,2.1a7.714,7.714,0,0,0-1,2.5L0,9v2l2.2.8a9.336,9.336,0,0,0,1,2.5l-1,2.1,1.4,1.4,2.1-1a7.956,7.956,0,0,0,2.5,1.1L9,20h2l.8-2.2c.3-.1.7-.2,1-.3l-1.6-1.6A6.01,6.01,0,1,1,10,4a5.914,5.914,0,0,1,5.9,6.8l1.7,1.7a2.354,2.354,0,0,0,.2-.8L20,11V9Z"
            fill={data.color}
          />
        </g>
      </g>
      <g
        id="Group_4"
        data-name="Group 4"
        transform="translate(6.017 5.833)"
        opacity={data.opacity}
      >
        <g id="Group_3" data-name="Group 3">
          <path
            id="Path_2"
            data-name="Path 2"
            d="M168.052,161.31l-5.907-5.907a4.01,4.01,0,0,0-4.706-5.507l1.8,1.8a1.982,1.982,0,0,1-2.8,2.8l-1.8-1.7a3.957,3.957,0,0,0,5.306,4.706l5.907,5.907a1.452,1.452,0,0,0,2.1,0A1.544,1.544,0,0,0,168.052,161.31Z"
            transform="translate(-154.47 -149.745)"
            fill={data.color}
          />
        </g>
      </g>
    </svg>
  );
};

export const mappingSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17.5"
      viewBox="0 0 20 17.5"
    >
      <g id="font" transform="translate(0 -29.235)" opacity={data.opacity}>
        <path
          id="Path_65"
          data-name="Path 65"
          d="M7.5,31.735h2.5v1.25h2.5v-3.75H0v3.75H2.5v-1.25H5v12.5H2.5v2.5H9.993v-2.5H7.5Z"
          fill={data.color}
        />
        <path
          id="Path_66"
          data-name="Path 66"
          d="M233.882,175.411v3.748h2.5V177.91h1.249v6.246h-1.249v2.5h5v-2.5h-1.249V177.91h1.249v1.249h2.5v-3.748h-9.994Z"
          transform="translate(-223.876 -139.92)"
          fill={data.color}
        />
      </g>
    </svg>
  );
};

export const metricsSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
    >
      <g id="g1393" transform="translate(-1.75 -1.75)" opacity={data.opacity}>
        <path
          id="rect1381"
          d="M6.286,2A4.292,4.292,0,0,0,2,6.286V17.714A4.292,4.292,0,0,0,6.286,22H17.714A4.292,4.292,0,0,0,22,17.714V6.286A4.292,4.292,0,0,0,17.714,2Zm0,1.429H17.714a2.835,2.835,0,0,1,2.857,2.857V17.714a2.835,2.835,0,0,1-2.857,2.857H6.286a2.835,2.835,0,0,1-2.857-2.857V6.286A2.835,2.835,0,0,1,6.286,3.429Z"
          fill={data.color}
          stroke={data.color}
          stroke-width="0.5"
        />
        <path
          id="path1383"
          d="M9.714,15A.714.714,0,0,0,9,15.714v5a.714.714,0,0,0,1.429,0v-5A.714.714,0,0,0,9.714,15Z"
          transform="translate(-2 -3.714)"
          fill={data.color}
          stroke={data.color}
          stroke-width="0.5"
        />
        <path
          id="path1385"
          d="M15.714,8.025A.714.714,0,0,0,15,8.74v9.982a.714.714,0,0,0,1.429,0V8.74A.714.714,0,0,0,15.714,8.025Z"
          transform="translate(-3.714 -1.722)"
          fill={data.color}
          stroke={data.color}
          stroke-width="0.5"
        />
        <path
          id="path1387"
          d="M21.714,12a.714.714,0,0,0-.714.714v7.143a.714.714,0,0,0,1.429,0V12.714A.714.714,0,0,0,21.714,12Z"
          transform="translate(-5.429 -2.857)"
          fill={data.color}
          stroke={data.color}
          stroke-width="0.5"
        />
      </g>
    </svg>
  );
};

export const alertsSVG = (data) => {
  return (
    <svg
      id="alert"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_37" data-name="Group 37">
        <path
          id="Path_68"
          data-name="Path 68"
          d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm1.2,18H10.8V15.6h2.4Zm0-4.8H10.8V6h2.4Z"
          fill="#787e89"
        />
      </g>
    </svg>
  );
};

// --------------------------_Sidebar icons
export const alertsSVG_Sidebar = (data) => {
  return (
    <svg
      id="alert"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Group_37" data-name="Group 37">
        <path
          id="Path_68"
          data-name="Path 68"
          d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm1.2,18H10.8V15.6h2.4Zm0-4.8H10.8V6h2.4Z"
          fill={data.color}
        />
      </g>
    </svg>
  );
};
export const dashboardSVG_Sidebar = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24.492"
      viewBox="0 0 30 24.492"
    >
      <path
        id="speedometer"
        d="M30,14.993a14.858,14.858,0,0,1-3.029,9.034A1.172,1.172,0,0,1,25.1,22.613a12.653,12.653,0,1,0-22.758-7.62A12.533,12.533,0,0,0,4.9,22.613a1.172,1.172,0,0,1-1.869,1.414A14.992,14.992,0,0,1,15,0,14.988,14.988,0,0,1,30,14.993ZM22.425,7.936a1.172,1.172,0,0,1,0,1.657l-3.82,3.82a4.1,4.1,0,1,1-1.657-1.657l3.82-3.82A1.172,1.172,0,0,1,22.425,7.936Zm-5.67,7.425A1.755,1.755,0,1,0,15,17.116,1.757,1.757,0,0,0,16.755,15.361Zm0,0"
        fill={data.color}
      />
    </svg>
  );
};
export const usecaseSVG_Sidebar = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="28.125"
      viewBox="0 0 30 28.125"
    >
      <g id="workflow" transform="translate(0 -16)">
        <path
          id="Path_69"
          data-name="Path 69"
          d="M151,16h12.308v7.033H151Z"
          transform="translate(-142.154)"
          fill={data.color}
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M151,196h12.308v7.033H151Z"
          transform="translate(-142.154 -169.454)"
          fill={data.color}
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M151,376h12.308v7.033H151Z"
          transform="translate(-142.154 -338.908)"
          fill={data.color}
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M393.186,241h-6.154v1.758h4.4v8.791h-2.273l1.137-1.137-1.243-1.243-3.259,3.259,3.259,3.259,1.243-1.243-1.137-1.137h4.032Z"
          transform="translate(-363.186 -211.82)"
          fill={data.color}
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M2.895,70.413l1.137,1.137H1.758V62.758h4.4V61H0V73.308H4.032L2.895,74.444l1.243,1.243L7.4,72.428,4.138,69.17Z"
          transform="translate(0 -42.363)"
          fill={data.color}
        />
      </g>
    </svg>
  );
};
export const alertConfigSVG_Sidebar = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.147"
      height="30"
      viewBox="0 0 23.147 30"
    >
      <path
        id="file-management"
        d="M65.26,8H83.084v3.448a1.271,1.271,0,0,0,1.27,1.27H87.8V37.4a.606.606,0,0,1-.6.6H65.26a.606.606,0,0,1-.6-.6V8.6A.606.606,0,0,1,65.26,8Zm4.119,5.731h9.834a.484.484,0,0,0,0-.968H69.378a.484.484,0,0,0,0,.968Zm0,4.037h13.7a.484.484,0,0,0,0-.968h-13.7a.484.484,0,0,0,0,.968ZM84.051,8.052v3.4a.3.3,0,0,0,.3.3h3.4a.607.607,0,0,0-.125-.177l-3.4-3.4A.606.606,0,0,0,84.051,8.052ZM77.527,21.884,77.194,19.8H75.263l-.333,2.079a5.193,5.193,0,0,0-1.363.565L71.86,21.214,70.495,22.58l1.235,1.706a5.193,5.193,0,0,0-.565,1.363l-2.08.333v1.931l2.08.333a5.2,5.2,0,0,0,.565,1.363l-1.236,1.706,1.365,1.365,1.706-1.235a5.194,5.194,0,0,0,1.363.565l.333,2.08h1.931l.333-2.08a5.2,5.2,0,0,0,1.363-.565L80.6,32.681l1.365-1.365-1.235-1.706a5.194,5.194,0,0,0,.565-1.363l2.079-.333V25.982l-2.08-.333a5.2,5.2,0,0,0-.565-1.363l1.235-1.706L80.6,21.214,78.89,22.45A5.191,5.191,0,0,0,77.527,21.884Zm-1.3,2.21a2.853,2.853,0,1,0,2.853,2.853A2.853,2.853,0,0,0,76.228,24.095Z"
        transform="translate(-64.655 -8)"
        fill={data.color}
      />
    </svg>
  );
};

export const exceptionsConfigSVG_Sidebar = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.053"
      height="30"
      viewBox="0 0 28.053 30"
    >
      <path
        id="Subtraction_30"
        data-name="Subtraction 30"
        d="M.868-1454H-14v-23.165H-.456L3.569-1484l10.484,18.311H4.334v8.225a3.441,3.441,0,0,1-1.016,2.451A3.445,3.445,0,0,1,.868-1454Zm-13.108-21.406v19.648h9.959a.985.985,0,0,0,.984-.985v-2.861H1.636a.9.9,0,0,0,.655-.272,1.008,1.008,0,0,0,.284-.715v-5.079H-9.333v-1.74H-6.2l1.324-2.248H-9.333v-1.76h5.491l2.35-3.99Zm12.7,17.561v1.1a2.729,2.729,0,0,1-.184.985H.868a1.7,1.7,0,0,0,1.208-.5,1.689,1.689,0,0,0,.5-1.2v-.551a2.691,2.691,0,0,1-.939.167Zm2.967-12.316a1.17,1.17,0,0,0-.825.366,1.034,1.034,0,0,0-.3.761,1.151,1.151,0,0,0,1.127,1.127,1.17,1.17,0,0,0,.825-.366,1.034,1.034,0,0,0,.3-.761A1.151,1.151,0,0,0,3.429-1470.162ZM2.3-1477.18v5.4H4.556v-5.4Zm-6.961,19.242H-9.332v-1.758h4.674v1.757Zm4.2-3.888H-9.331v-1.758H-.456v1.757Z"
        transform="translate(14 1484)"
        fill={data.color}
      />
    </svg>
  );
};
export const settingsConfigSVG_Sidebar = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.106"
      height="26.613"
      viewBox="0 0 28.106 26.613"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1.804)">
        <path
          id="Path_74"
          data-name="Path 74"
          d="M27.429,11.206a3.673,3.673,0,0,1-2.895-4.965A1.724,1.724,0,0,0,24.019,4.3,13.819,13.819,0,0,0,19.9,1.913a1.733,1.733,0,0,0-1.958.543,3.663,3.663,0,0,1-5.771,0,1.733,1.733,0,0,0-1.958-.543A13.884,13.884,0,0,0,6.368,4.067a1.743,1.743,0,0,0-.543,2A3.654,3.654,0,0,1,2.78,11.085a1.733,1.733,0,0,0-1.518,1.4A13.247,13.247,0,0,0,1,15.1a13.416,13.416,0,0,0,.178,2.2,1.733,1.733,0,0,0,1.527,1.452A3.654,3.654,0,0,1,5.684,23.92a1.705,1.705,0,0,0,.478,2.042,13.922,13.922,0,0,0,4.085,2.352,1.874,1.874,0,0,0,.59.1,1.724,1.724,0,0,0,1.405-.731,3.626,3.626,0,0,1,3-1.574,3.673,3.673,0,0,1,2.942,1.48,1.724,1.724,0,0,0,2.024.571,14.053,14.053,0,0,0,3.747-2.239,1.733,1.733,0,0,0,.506-1.977,3.654,3.654,0,0,1,2.932-5.05,1.733,1.733,0,0,0,1.471-1.424,13.585,13.585,0,0,0,.244-2.37,13.452,13.452,0,0,0-.235-2.5,1.714,1.714,0,0,0-1.442-1.393Zm-7.692,3.9a4.684,4.684,0,1,1-4.684-4.684A4.684,4.684,0,0,1,19.737,15.1Z"
          fill={data.color}
        />
      </g>
    </svg>
  );
};
export const userManagementConfigSVG_Sidebar = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.95"
      height="28"
      viewBox="0 0 24.95 28"
    >
      <g id="admin" transform="translate(-27.5)">
        <circle
          id="Ellipse_38"
          data-name="Ellipse 38"
          cx="3.842"
          cy="3.842"
          r="3.842"
          transform="translate(36.189 8.875)"
          fill={data.color}
        />
        <path
          id="Path_82"
          data-name="Path 82"
          d="M129.451,332.775a6,6,0,0,0-.951,3.245v3.672h13.874V336.02a6.024,6.024,0,0,0-6.032-6.02h-1.81A6.009,6.009,0,0,0,129.451,332.775Z"
          transform="translate(-95.406 -311.692)"
          fill={data.color}
        />
        <path
          id="Path_83"
          data-name="Path 83"
          d="M52.451,14.985V9.973l-3.029-.721a9.952,9.952,0,0,0-.486-1.172l1.632-2.653L47.025,1.883,44.373,3.516A9.941,9.941,0,0,0,43.2,3.03L42.48,0H37.47l-.721,3.03a9.94,9.94,0,0,0-1.172.486L32.925,1.883,29.383,5.427,31.015,8.08a9.952,9.952,0,0,0-.486,1.172L27.5,9.973v5.012l3.029.721a9.952,9.952,0,0,0,.486,1.172l-1.64,2.665L31.831,21.9a7.7,7.7,0,0,1,4.8-4.844,5.51,5.51,0,1,1,6.807,0,7.7,7.7,0,0,1,4.822,4.924h0l2.3-2.466-1.62-2.633a9.952,9.952,0,0,0,.486-1.172Z"
          transform="translate(0)"
          fill={data.color}
        />
      </g>
    </svg>
  );
};

export const calenderSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40.195"
      height="40.495"
      viewBox="0 0 40.195 40.495"
    >
      <g id="Calendar" transform="translate(-2 -2)">
        <path
          id="Path_45563"
          data-name="Path 45563"
          d="M38.658,6H35.325V4.666a2.666,2.666,0,0,0-5.332,0V6H24.661V4.666a2.666,2.666,0,0,0-5.332,0V6H14V4.666a2.666,2.666,0,0,0-5.332,0V6H5.333A3.332,3.332,0,0,0,2,9.33V35.986a3.332,3.332,0,0,0,3.333,3.332H23.995a.666.666,0,1,0,0-1.333H5.333a2.005,2.005,0,0,1-2-2V15.328H40.657V26.656a.667.667,0,0,0,1.333,0V9.33A3.332,3.332,0,0,0,38.658,6ZM31.326,4.666a1.333,1.333,0,0,1,2.666,0v4a1.333,1.333,0,0,1-2.666,0Zm-10.664,0a1.333,1.333,0,0,1,2.666,0v4a1.333,1.333,0,0,1-2.666,0ZM10,4.666a1.333,1.333,0,0,1,2.666,0v4a1.333,1.333,0,0,1-2.666,0Zm30.659,9.329H3.333V9.33a2.005,2.005,0,0,1,2-2H8.665V8.664a2.666,2.666,0,0,0,5.332,0V7.331h5.332V8.664a2.666,2.666,0,0,0,5.332,0V7.331h5.332V8.664a2.666,2.666,0,0,0,5.332,0V7.331h3.333a2.005,2.005,0,0,1,2,2Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_45564"
          data-name="Path 45564"
          d="M27.345,28.336A1.336,1.336,0,0,0,26.009,27H23.336A1.336,1.336,0,0,0,22,28.336v2a1.336,1.336,0,0,0,1.336,1.336h2.673a1.336,1.336,0,0,0,1.336-1.336Zm-4.009,2v-2h2.673v2Z"
          transform="translate(-6.675 -8.343)"
        />
        <path
          id="Path_45565"
          data-name="Path 45565"
          d="M15.345,28.336A1.336,1.336,0,0,0,14.009,27H11.336A1.336,1.336,0,0,0,10,28.336v2a1.336,1.336,0,0,0,1.336,1.336h2.673a1.336,1.336,0,0,0,1.336-1.336Zm-4.009,2v-2h2.673v2Z"
          transform="translate(-2.655 -8.343)"
        />
        <path
          id="Path_45566"
          data-name="Path 45566"
          d="M50.009,31.677a1.336,1.336,0,0,0,1.336-1.336v-2A1.336,1.336,0,0,0,50.009,27H47.336A1.336,1.336,0,0,0,46,28.336v2a1.336,1.336,0,0,0,1.336,1.336Zm-2.673-3.341h2.673v2H47.336Z"
          transform="translate(-14.7 -8.343)"
        />
        <path
          id="Path_45567"
          data-name="Path 45567"
          d="M27.345,37.336A1.336,1.336,0,0,0,26.009,36H23.336A1.336,1.336,0,0,0,22,37.336v2a1.336,1.336,0,0,0,1.336,1.336h2.673a1.336,1.336,0,0,0,1.336-1.336Zm-4.009,2v-2h2.673v2Z"
          transform="translate(-6.675 -11.346)"
        />
        <path
          id="Path_45568"
          data-name="Path 45568"
          d="M15.345,37.336A1.336,1.336,0,0,0,14.009,36H11.336A1.336,1.336,0,0,0,10,37.336v2a1.336,1.336,0,0,0,1.336,1.336h2.673a1.336,1.336,0,0,0,1.336-1.336Zm-4.009,2v-2h2.673v2Z"
          transform="translate(-2.655 -11.346)"
        />
        <path
          id="Path_45569"
          data-name="Path 45569"
          d="M26.009,45H23.336A1.336,1.336,0,0,0,22,46.336v2a1.336,1.336,0,0,0,1.336,1.336h2.673a1.336,1.336,0,0,0,1.336-1.336v-2A1.336,1.336,0,0,0,26.009,45Zm-2.673,3.341v-2h2.673v2Z"
          transform="translate(-6.675 -14.35)"
        />
        <path
          id="Path_45570"
          data-name="Path 45570"
          d="M35.336,31.677h2.673a1.336,1.336,0,0,0,1.336-1.336v-2A1.336,1.336,0,0,0,38.009,27H35.336A1.336,1.336,0,0,0,34,28.336v2A1.336,1.336,0,0,0,35.336,31.677Zm0-3.341h2.673v2H35.336Z"
          transform="translate(-10.68 -8.343)"
        />
        <path
          id="Path_45572"
          data-name="Path 45572"
          d="M14.009,45H11.336A1.336,1.336,0,0,0,10,46.336v2a1.336,1.336,0,0,0,1.336,1.336h2.673a1.336,1.336,0,0,0,1.336-1.336v-2A1.336,1.336,0,0,0,14.009,45Zm-2.673,3.341v-2h2.673v2Z"
          transform="translate(-2.655 -14.35)"
        />
        <path
          id="Path_45573"
          data-name="Path 45573"
          d="M44.686,36a8.686,8.686,0,1,0,8.686,8.686A8.686,8.686,0,0,0,44.686,36Zm0,16.035a7.35,7.35,0,1,1,7.35-7.35,7.35,7.35,0,0,1-7.35,7.35Z"
          transform="translate(-11.382 -11.382)"
        />
        <g
          id="Group_217"
          data-name="Group 217"
          transform="translate(-273.646 -172.346)"
        >
          <circle
            id="Ellipse_55"
            data-name="Ellipse 55"
            cx="9"
            cy="9"
            r="9"
            transform="translate(297.841 196.841)"
            fill="#0ccb7d"
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M90.188,105.14l-5.233,5.233-2.93-2.909,1.193-1.172,1.737,1.716,4.04-4.04Z"
            transform="translate(220.396 98.926)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export const checkSuccessSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g id="check" transform="translate(-0.232 -0.233)">
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M20,40a20.147,20.147,0,0,1-4.031-.406,19.89,19.89,0,0,1-7.151-3.009,20.058,20.058,0,0,1-7.246-8.8A19.9,19.9,0,0,1,.406,24.031,20.456,20.456,0,0,1,.394,15.98a19.834,19.834,0,0,1,2.98-7.14,20,20,0,0,1,8.754-7.25A19.843,19.843,0,0,1,15.868.417,20.09,20.09,0,0,1,19.886,0H20a20.147,20.147,0,0,1,4.031.406,19.89,19.89,0,0,1,7.151,3.009,20.059,20.059,0,0,1,7.246,8.8,19.906,19.906,0,0,1,1.165,3.754,20.193,20.193,0,0,1,0,8.061,19.889,19.889,0,0,1-3.009,7.151,20.059,20.059,0,0,1-8.8,7.246,19.9,19.9,0,0,1-3.754,1.165A20.147,20.147,0,0,1,20,40ZM12.159,17.614,8.92,20.8l7.955,7.9,14.2-14.2-3.238-3.182L16.875,22.273l-4.716-4.659Z"
          transform="translate(0.232 0.233)"
          fill="#3bb54a"
        />
      </g>
    </svg>
  );
};

export const arrowUpFillSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g id="Group_244" data-name="Group 244" transform="translate(-1252 -447)">
        <g id="Group_143" data-name="Group 143" transform="translate(0 -94)">
          <g
            id="Ellipse_44"
            data-name="Ellipse 44"
            transform="translate(1252 541)"
            fill="#9396a8"
            stroke="#9396a8"
            stroke-width="2"
          >
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="17" fill="none" />
          </g>
          <path
            id="down-chevron"
            d="M12.077,8.923,7,3.846,1.923,8.923,0,7,7,0l7,7Zm0,0"
            transform="translate(1262.994 554)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
export const arrowDownSVG = (data) => {
  return (
    <svg
      id="Group_143"
      data-name="Group 143"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Ellipse_44"
        data-name="Ellipse 44"
        fill="#fff"
        stroke="#9396a8"
        stroke-width="2"
      >
        <circle cx="18" cy="18" r="18" stroke="none" />
        <circle cx="18" cy="18" r="17" fill="none" />
      </g>
      <path
        id="down-chevron"
        d="M12.077,0,7,5.077,1.923,0,0,1.923l7,7,7-7Zm0,0"
        transform="translate(11.021 15)"
        fill="#9396a8"
      />
    </svg>
  );
};
export const closeSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.139"
      height="11.139"
      viewBox="0 0 11.139 11.139"
    >
      <g
        id="Group_42964"
        data-name="Group 42964"
        transform="translate(-1007.931 -124.931)"
        opacity="0.55"
      >
        <line
          id="Line_604"
          data-name="Line 604"
          x2="10.078"
          y2="10.078"
          transform="translate(1008.461 125.461)"
          fill="none"
          stroke="#707070"
          stroke-width="1.5"
        />
        <line
          id="Line_605"
          data-name="Line 605"
          x1="10.078"
          y2="10.078"
          transform="translate(1008.461 125.461)"
          fill="none"
          stroke="#707070"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};
export const dislikeSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.002"
      height="22.632"
      viewBox="0 0 24.002 22.632"
    >
      <path
        id="Icon"
        d="M17.527,16.3a1.377,1.377,0,0,0-1.368-1.368H12.368A1.365,1.365,0,0,0,11,16.3V27.041a1.365,1.365,0,0,0,1.368,1.368H16.14a1.365,1.365,0,0,0,1.368-1.368Zm16.05,8.432a1.881,1.881,0,0,0,.3-2.644,1.814,1.814,0,0,0-1.035-.647,1.86,1.86,0,0,0,.3-2.626,1.925,1.925,0,0,0-1.054-.666A1.885,1.885,0,0,0,30.9,14.8l-10.632.111A1.365,1.365,0,0,0,18.9,16.279V27.041c0,1.7,5.436,4.826,5.714,6.509.185,1.109-.055,3.883.684,3.883,1.276,0,2.885-.481,2.885-3.291a15.769,15.769,0,0,0-1.627-5.7h6.583A1.862,1.862,0,0,0,35,26.578,1.8,1.8,0,0,0,33.577,24.729Z"
        transform="translate(-11 -14.8)"
        fill="#9396a8"
      />
    </svg>
  );
};
export const likeSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.002"
      height="22.632"
      viewBox="0 0 24.002 22.632"
    >
      <path
        id="Icon"
        d="M17.527,35.935A1.377,1.377,0,0,1,16.159,37.3H12.368A1.365,1.365,0,0,1,11,35.935V25.192a1.365,1.365,0,0,1,1.368-1.368H16.14a1.365,1.365,0,0,1,1.368,1.368ZM33.577,27.5a1.881,1.881,0,0,1,.3,2.644,1.814,1.814,0,0,1-1.035.647,1.86,1.86,0,0,1,.3,2.626,1.925,1.925,0,0,1-1.054.666A1.885,1.885,0,0,1,30.9,37.432l-10.632-.111A1.365,1.365,0,0,1,18.9,35.953V25.192c0-1.7,5.436-4.826,5.714-6.509.185-1.109-.055-3.883.684-3.883,1.276,0,2.885.481,2.885,3.291a15.769,15.769,0,0,1-1.627,5.7h6.583A1.862,1.862,0,0,1,35,25.654,1.8,1.8,0,0,1,33.577,27.5Z"
        transform="translate(-11 -14.8)"
        fill="#9396a8"
      />
    </svg>
  );
};
export const cameraSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.399"
      height="16.999"
      viewBox="0 0 20.399 16.999"
    >
      <path
        id="iconmonstr-photo-camera-4"
        d="M11.049,8.8a2.55,2.55,0,1,0,2.55,2.55A2.553,2.553,0,0,0,11.049,8.8ZM20.4,4.55V19H0V4.55H5.04a1.7,1.7,0,0,0,1.414-.757L7.65,2h6.8l1.2,1.793a1.7,1.7,0,0,0,1.414.757ZM4.25,7.95a.85.85,0,1,0-.85.85A.85.85,0,0,0,4.25,7.95Zm11.049,3.4a4.25,4.25,0,1,0-4.25,4.25A4.25,4.25,0,0,0,15.3,11.349Z"
        transform="translate(0 -2)"
        fill="#2b313c"
      />
    </svg>
  );
};
export const bellDeactiveSVG = (data) => {
  return (
    <svg
      id="notification_2_"
      data-name="notification (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="39.951"
      height="39.951"
      viewBox="0 0 39.951 39.951"
    >
      <path
        id="Path_45560"
        data-name="Path 45560"
        d="M29.878,11.732,39.951,1.655,38.3,0,28.478,9.818a10.409,10.409,0,0,0-6.568-3.4V1.655H19.585v4.8a10.431,10.431,0,0,0-9.364,10.419v9.364a3.529,3.529,0,0,1-3.528,3.511V31.6L0,38.3l1.655,1.655Z"
        fill="#eb4335"
      />
      <path
        id="Path_45561"
        data-name="Path 45561"
        d="M186.422,196.018a3.515,3.515,0,0,1-3.511-3.511v-9.364a10.4,10.4,0,0,0-.425-2.8l-18.014,18.014h21.951Z"
        transform="translate(-151.637 -166.272)"
        fill="#eb4335"
      />
      <path
        id="Path_45562"
        data-name="Path 45562"
        d="M226.848,443.552a3.506,3.506,0,0,0,3.3-2.341h-6.591A3.506,3.506,0,0,0,226.848,443.552Z"
        transform="translate(-206.108 -406.783)"
        fill="#eb4335"
      />
    </svg>
  );
};
export const triangleDownSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M5,0l5,9H0Z"
        transform="translate(10 9) rotate(180)"
        fill="#cc574d"
      />
    </svg>
  );
};

export const triangleDownGreenSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M5,0l5,9H0Z"
        transform="translate(10 9) rotate(180)"
        fill="#57c799"
      />
    </svg>
  );
};
export const triangleUpSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M5,0l5,9H0Z"
        fill="#57c799"
      />
    </svg>
  );
};

export const triangleUpRedSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M5,0l5,9H0Z"
        fill="#cc574d"
      />
    </svg>
  );
};
export const triangleUpWhiteSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
    >
      <path
        id="Polygon_10"
        data-name="Polygon 10"
        d="M5,0l5,9H0Z"
        fill="#fff"
      />
    </svg>
  );
};

export const triangleDownWhiteSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M5,0l5,9H0Z"
        transform="translate(10 9) rotate(180)"
        fill="#ffffff"
      />
    </svg>
  );
};
export const slackSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="slack" transform="translate(-0.002 0.001)">
        <path
          id="Path_45527"
          data-name="Path 45527"
          d="M8.213,12.651a1.891,1.891,0,0,0-1.891,1.891v4.729a1.891,1.891,0,0,0,3.781,0V14.542a1.892,1.892,0,0,0-1.891-1.891Z"
          transform="translate(-1.58 -3.163)"
          fill="#e91e63"
        />
        <path
          id="Path_45528"
          data-name="Path 45528"
          d="M.019,14.541a1.892,1.892,0,1,0,3.784,0V12.649H1.911A1.893,1.893,0,0,0,.019,14.541Z"
          transform="translate(-0.004 -3.163)"
          fill="#e91e63"
        />
        <path
          id="Path_45529"
          data-name="Path 45529"
          d="M8.215,0h0a1.892,1.892,0,1,0,0,3.784H10.1v-1.9A1.889,1.889,0,0,0,8.215,0Z"
          transform="translate(-1.58)"
          fill="#00bcd4"
        />
        <path
          id="Path_45530"
          data-name="Path 45530"
          d="M1.894,10.108H6.633a1.892,1.892,0,1,0,0-3.784H1.894a1.892,1.892,0,1,0,0,3.784Z"
          transform="translate(0 -1.581)"
          fill="#00bcd4"
        />
        <path
          id="Path_45531"
          data-name="Path 45531"
          d="M20.828,6.323a1.888,1.888,0,0,0-1.889,1.889v1.9H20.83a1.892,1.892,0,1,0,0-3.784Z"
          transform="translate(-4.734 -1.581)"
          fill="#4caf50"
        />
        <path
          id="Path_45532"
          data-name="Path 45532"
          d="M12.641,1.892V6.635a1.891,1.891,0,1,0,3.781,0V1.892a1.891,1.891,0,1,0-3.781,0Z"
          transform="translate(-3.16 -0.001)"
          fill="#4caf50"
        />
        <g
          id="Group_198"
          data-name="Group 198"
          transform="translate(9.479 9.486)"
        >
          <path
            id="Path_45533"
            data-name="Path 45533"
            d="M16.421,20.846a1.891,1.891,0,0,0-1.891-1.891H12.64v1.892a1.891,1.891,0,0,0,3.781,0Z"
            transform="translate(-12.639 -14.226)"
            fill="#ff9800"
          />
          <path
            id="Path_45534"
            data-name="Path 45534"
            d="M19.269,12.649H14.53a1.892,1.892,0,0,0,0,3.785h4.738a1.892,1.892,0,1,0,0-3.785Z"
            transform="translate(-12.638 -12.649)"
            fill="#ff9800"
          />
        </g>
      </g>
    </svg>
  );
};
export const infoFillSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM6.682,7.3a.839.839,0,0,1,.466.141.639.639,0,0,1,.145.477,2.086,2.086,0,0,1-.048.437c-.032.159-.072.322-.118.485l-.521,1.784c-.042.167-.076.336-.1.5a3.179,3.179,0,0,0-.03.437,1.132,1.132,0,0,0,.437.905,1.758,1.758,0,0,0,1.065.362c.032,0,.066,0,.107,0h.058a2.682,2.682,0,0,0,.816-.127c.249-.085.583-.207.993-.363l.14-.551a2.428,2.428,0,0,1-.345.114,1.676,1.676,0,0,1-.371.042l-.085,0c-.028,0-.058,0-.086,0a.936.936,0,0,1-.478-.131.58.58,0,0,1-.162-.481,2.491,2.491,0,0,1,.053-.438,4.61,4.61,0,0,1,.114-.49l.516-1.776a2.68,2.68,0,0,0,.105-.538,2.783,2.783,0,0,1,.017-.293c0-.048.009-.087.009-.117a1.143,1.143,0,0,0-.406-.914,1.719,1.719,0,0,0-1.04-.353c-.038,0-.077,0-.115,0a2.974,2.974,0,0,0-.874.144c-.317.1-.646.215-.976.346l-.148.577c.094-.032.213-.071.363-.118A1.5,1.5,0,0,1,6.593,7.3.842.842,0,0,1,6.682,7.3ZM8.93,3.2a1.249,1.249,0,0,0-.844.328,1.045,1.045,0,0,0-.109,1.474.971.971,0,0,0,.109.109,1.309,1.309,0,0,0,1.749,0,1.05,1.05,0,0,0,.1-1.48,1.138,1.138,0,0,0-.1-.1A1.241,1.241,0,0,0,8.994,3.2H8.93Z"
        fill="#09cbc6"
      />
    </svg>
  );
};
export const infoDarkSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Subtraction_3"
        data-name="Subtraction 3"
        d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM6.682,7.3a.839.839,0,0,1,.466.141.639.639,0,0,1,.145.477,2.086,2.086,0,0,1-.048.437c-.032.159-.072.322-.118.485l-.521,1.784c-.042.167-.076.336-.1.5a3.179,3.179,0,0,0-.03.437,1.132,1.132,0,0,0,.437.905,1.758,1.758,0,0,0,1.065.362c.032,0,.066,0,.107,0h.058a2.682,2.682,0,0,0,.816-.127c.249-.085.583-.207.993-.363l.14-.551a2.428,2.428,0,0,1-.345.114,1.676,1.676,0,0,1-.371.042l-.085,0c-.028,0-.058,0-.086,0a.936.936,0,0,1-.478-.131.58.58,0,0,1-.162-.481,2.491,2.491,0,0,1,.053-.438,4.61,4.61,0,0,1,.114-.49l.516-1.776a2.68,2.68,0,0,0,.105-.538,2.783,2.783,0,0,1,.017-.293c0-.048.009-.087.009-.117a1.143,1.143,0,0,0-.406-.914,1.719,1.719,0,0,0-1.04-.353c-.038,0-.077,0-.115,0a2.974,2.974,0,0,0-.874.144c-.317.1-.646.215-.976.346l-.148.577c.094-.032.213-.071.363-.118A1.5,1.5,0,0,1,6.593,7.3.842.842,0,0,1,6.682,7.3ZM8.93,3.2a1.249,1.249,0,0,0-.844.328,1.045,1.045,0,0,0-.109,1.474.971.971,0,0,0,.109.109,1.309,1.309,0,0,0,1.749,0,1.05,1.05,0,0,0,.1-1.48,1.138,1.138,0,0,0-.1-.1A1.241,1.241,0,0,0,8.994,3.2H8.93Z"
        fill="#2b313c"
        opacity="0.6"
      />
    </svg>
  );
};
export const treeNodeSVG = (data) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.5"
      height="14"
      viewBox="0 0 37.5 14"
    >
      <g
        id="Group_44073"
        data-name="Group 44073"
        transform="translate(-922.5 -568)"
      >
        <circle
          id="Ellipse_63"
          data-name="Ellipse 63"
          cx="4"
          cy="4"
          r="4"
          transform="translate(952 574)"
          fill="#9396a8"
        />
        <path
          id="Path_53706"
          data-name="Path 53706"
          d="M30,27H10A10,10,0,0,1,0,17"
          transform="translate(923 551)"
          fill="none"
          stroke="#9396a8"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};
