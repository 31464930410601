import React from "react";
import { cameraSVG } from "../../assets/svgIcons";
import Input from "../../components/Inputs/Input";

function Profile() {
  return (
    <div className="divide-y-2 w-full overflow-hidden">
      <div className="flex justify-between items-center px-4 sm:px-10 py-5">
        <div>
          <h2 className="text-3xl font-semibold">My Profile</h2>
        </div>
      </div>
      <div className="px-4 sm:px-10 py-5 w-full ">
        <div className="bg-white shadow-md" style={{ minHeight: "500px" }}>
          <div className="px-4 sm:px-14 py-10 grid grid-cols-12 gap-4 justify-items-center">
            <div className="col-span-2 hidden sm:block">
              <div className=" relative w-16 h-16 sm:w-24 sm:h-24">
                <img
                  className="rounded-full border border-gray-100 shadow-sm"
                  src="https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png"
                  alt="user image"
                />
                <div className="absolute bottom-0 right-0 shadow-lg p-1 my-1 border-2 border-white rounded-full bg-white z-2">
                  {cameraSVG()}
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-8 w-full divide-y-2">
              <div className="flex justify-between items-center pb-8">
                <h1 className="text-ai-primary-dark text-xl sm:text-2xl">
                  {localStorage.getItem("full_name")}
                </h1>
                <span className="px-4 py-2 max-w-max text-white bg-ai-primary-text-teal rounded-full">
                  {JSON.parse(localStorage.getItem("isSuperAdmin"))
                    ? "Super Admin"
                    : "Normal User"}
                </span>
              </div>
              <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-8">
                  <Input
                    name={"email"}
                    label={"Email"}
                    type={"text"}
                    value={localStorage.getItem("user_email")}
                    onChange={() => {}}
                    disabled={true}
                  />
                </div>
                {/* <div className="flex flex-col gap-2 mt-5">
                  <label
                    htmlFor="groups"
                    className="capitalize  text-sm  font-bold text-ai-primary-dark tracking-wide "
                  >
                    Groups
                  </label>
                  {state.userGroups?.length === 0 ||
                  state.userGroups?.length === undefined ? (
                    <label
                      htmlFor="groups"
                      className="capitalize  text-sm  font-bold text-ai-primary-dark tracking-wide "
                    >
                      Not assigned to any group
                    </label>
                  ) : (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5">
                      {state.userGroups?.map((group, i) => {
                        return (
                          <div
                            key={i}
                            className="w-full max-w-max flex justify-between items-center font-semibold border-blue-gray-300 rounded-md whitespace-nowrap  border  py-3 px-1 shadow-sm text-blue-gray-900 sm:text-sm"
                          >
                            <span className="pr-3 pl-3">{group.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
