import React, { useState, useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import Input from "../../../../components/Inputs/Input";
import InputChip from "../../../../components/Inputs/InputChip";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { BASE_URL, TENANT_ID } from "../../../../utils/variables";
import { useLocation, useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import CreateImport from "../CreateImport";
const NewCreateImportPage = () => {
  let history = useHistory();
  // We are getting data that we passed from _openEditConnection in connections page
  const location = useLocation();

  const importData = location.state?.dataImports;
  const [create, setCreate] = useState({
    allReportsData: false,
    allReportNames: [],
    loading: false,
  });
  const [state, setState] = useState(importData.imstate)
  // console.log("state===>", state)
  // console.log("ungabungauu", importData.import_data);
  const fetchReportNames = async (id) => {
    let connection_type = importData?.connectionDetails?.connection_type;

    if (connection_type.toUpperCase() === "GAM") {
      const API = `${BASE_URL}fetch-gamreports`;
      try {
        const response = await fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            network_id: localStorage.getItem("selectedNetworkId"),
          }),
        });
        const responseData = await response.json();
        let reportNames = [];
        responseData.response.forEach((i, v) => {
          reportNames.push(i.report_name);
        });
        reportNames.unshift("-- Select --");
        setCreate({
          allReportsData: responseData,
          allReportNames: reportNames,
          loading: false,
        });
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          success: false,
          error: "Something went wrong",
        }));
      }
    }
  };
  // console.log("MYDATA=>>>>", importData);
  useEffect(() => {
    // Call the fetchReportNames function with the appropriate argument
    fetchReportNames(importData.connectionDetails.network_id);
  }, []);

  let currentConnectionType = importData.connectionDetails.connection_type;
  const onClose = () => {
    // history.push(
    //   `/dashboard/imports?connection=${importData.connectionDetails.connection_id}&type=${importData.connectionDetails.connection_type}`
    // );
    history.goBack();
  }


  return (
    <div>
      <div className="mt-5 ml-3 md:px-5 flex justify-between max-w-full">
        <div>
          <h2 className="text-2xl sm:text-3xl text-ai-primary-text-dark font-semibold">
            {importData.editImport ? "Edit" : "Create"} Import
          </h2>
          <span className="ml-1 text-md pb-1 text-ai-primary-text-teal font-semibold">
            {currentConnectionType.toUpperCase()}
          </span>
        </div>
      </div>
      <div className="px-6 py-2 sm:px-10">
        <div>
          {/* <dl className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"></dl> */}
          <CreateImport
            type={currentConnectionType}
            import_data={importData.import_data}
            connectionID={importData.connection}
            onClose={onClose}
            editImport={importData.editImport}
            allReportNames={create?.allReportNames}
            allReportsData={create?.allReportsData?.response || []}
            connectionDetails={importData?.connectionDetails}
          />
        </div></div>
    </div>
  );
}

export default NewCreateImportPage;