import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Input from "../../../../components/Inputs/Input";
import Modal from "../../../../components/Modal";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import { IoIosCloseCircle } from "react-icons/io";
import { BASE_URL } from "../../../../utils/variables";
const ModalItemRemoveImport = ({ connection_id, modalRefRemoveImport }) => {
  const [inputs, setInputs] = useState({});
  const [state, setState] = useState({
    loading: false,
  });

  const [toast, setToast] = useState({
    type: false,
    message: "",
  });

  const onRemoveImport = async () => {
    try {
      setState({ ...state, loading: true });
      const API = `${BASE_URL}remove/import`;
      const response = await fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          import_id: connection_id,
          import_type: window.location.href.toLowerCase().includes("email")
            ? "email"
            : window.location.href.toLowerCase().includes("gam") ? "gam" : "tam",
        }),
      });
      const responseData = await response.json();
      setState({
        ...state,
        loading: false,
      });
      setToast({
        type: false,
        message: "Remove successful",
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (error) {
      setState({
        ...state,
        loading: false,
      });
      setToast({
        type: true,
        message: "Something went wrong",
      });
    }
  };

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  return (
    <Modal ref={modalRefRemoveImport}>
      <div className="">
        <div className=" w-full py-4 px-6 bg-ai-primary-gray flex justify-between items-center">
          <h2 className="text-base text-ai-primary-dark  font-bold ">
            Remove Import
          </h2>
          <IoIosCloseCircle
            className="w-6 h-6 text-ai-primary-gray-dark cursor-pointer"
            onClick={() => {
              modalRefRemoveImport.current.closeModal();
            }}
          />
        </div>
        <div className="mt-10 mr-4 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-3">
              <div>
                <div className="pl-4 sm:rounded-md">

                  <div className="">
                    <>
                      <div className="col-span-4 sm:col-span-4">
                        <label
                          htmlFor="username"
                          className="flex mt-4 text-sm font-semibold text-ai-primary-dark"
                        >
                          Please type CONFIRM to remove this Import
                          {/* {" "} */}
                          {/* <span className="font-bold"> this </span> Import */}
                        </label>
                        <Input
                          type="text"
                          name="remove"
                          id="remove"
                          onChange={handleChange}
                          value={inputs.remove}
                          className=" focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {toast.message && (
          <ToastMsg
            msg={toast.message}
            isError={toast.type}
            setToast={setToast}
          />
        )}
        <div className="p-2 pl-4 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
          {/* <button
            type="button"
            disabled={inputs.remove === "CONFIRM" ? false : true}
            onClick={onRemoveImport}
            className={
              inputs.remove === "CONFIRM"
                ? " inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ai-primary-teal text-base font-medium text-white hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal sm:col-start-1 sm:text-sm"
                : " inline-flex justify-center disabled:opacity-50 rounded-md border border-transparent shadow-sm px-4 py-2 bg-ai-primary-teal-btn text-base font-medium text-white cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal sm:col-start-1 sm:text-sm"
              //bg-ai-primary-teal-btn hover:bg-ai-primary-teal-btn-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ai-primary-teal
            }
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Remove"
            )}
          </button> */}
          <button
            className={` max-w-max font-medium py-2  px-12 border border-transparent shadow-sm text-sm rounded-md text-white bg-button hover:bg-ai-primary-teal transition focus:outline-none focus:outine-none ${inputs.remove !== "CONFIRM" && "disabled:opacity-50"
              } `}
            onClick={onRemoveImport}
            disabled={inputs.remove === "CONFIRM" ? false : true}
          >
            {state.loading ? (
              <div className="h-6 w-6 mx-auto text-center flex justify-center flex-col ">
                <AiOutlineLoading3Quarters
                  className="animate-spin font-extrabold mx-auto text-center my-auto flex flex-col justify-center"
                  size={40}
                />
              </div>
            ) : (
              "Remove"
            )}
          </button>

        </div>
      </div>

    </Modal>
  );
};

export default ModalItemRemoveImport;
