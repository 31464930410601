import React, { Fragment, useEffect, useState } from "react";
import { IoIosArrowRoundUp } from "react-icons/io";
import Toggle from "../Toggle";
import { BsThreeDotsVertical } from "react-icons/bs";
export default function SimpleTable(props) {
  const [data, setData] = useState(props.data);
  const [columns, setColumns] = useState(props.columns);

  const [state, setState] = useState({
    loading: false,
    selectedColumn: props.columns[0],
    ascending: true,
    page: 0,
    totalPages: [],
    dropdown: false,
    selected: null,
  });
  // console.log("This is data", data);


  useEffect(() => {
    if (props.defaultSort === true) {
      sortBy();
    }
  }, []);

  const sortBy = (col) => {
    // setState({ ...state, loading: true });
    let column = col ? col : state.selectedColumn,
      ascending = state.ascending,
      newData = data;

    if (state.selectedColumn === col) {
      ascending = !ascending;
    }

    if (ascending) {
      newData.sort((a, b) =>
        a[column.value].value > b[column.value].value ? 1 : -1
      );
    } else {
      newData.sort((a, b) =>
        a[column.value].value < b[column.value].value ? 1 : -1
      );
    }
    setData(newData);
    setState({ ...state, ascending, loading: false, selectedColumn: col });
  };

  //DISABLE
  const _renderActionItem = (row, k) => {
    // console.log("_renderActionItem", row)
    return (
      <div className="absolute top-15 right-0 mr-6 w-32 bg-white border rounded shadow-lg">
        <div className="flex flex-col">
          {props?.actions?.map((action, i) => (

            <button
              key={i}
              className={`p-2 cursor-pointer text-right ${row['Connection Type'].value === 'GAM' && action.label === 'Issue Password'
                ? 'text-gray-400 cursor-not-allowed hover:text-grey-500'
                : 'hover:text-green-400'
                }`}
              onClick={() => { row['Connection Type'].value === 'GAM' && action.label === 'Issue Password' ? console.log("Err") : action.action(row, k) }}
              title={row['Connection Type'].value === 'GAM' && action.label === 'Issue Password' ? "GAM doesn't have a Password" : ""}
            >
              {action.label}
            </button>
          ))
          }</div>
      </div >
    );
  };
  //IMPORTS--------------------------------------------------------<<<<<<<<<<<<<<<<<<<<<<<<<
  const _renderImportsActionItem = (row, k) => {
    //console.log("_renderImportsActionItem", row)
    const handleActionClick = (action) => {
      if (action.label === "Upload a File") {
        // console.log("Triggered");
        const fileInput = document.getElementById(`file_upload_${k}`);

        fileInput.click();
      } else {
        action.action(row, k);
      }
    };

    return (
      <div className="absolute top-15 z-40 right-0 mr-6 w-32 bg-white border rounded shadow-lg">
        <div className="flex flex-col">
          {props?.actions?.map((action, i) => (

            < button
              key={i}
              className={`p-2 cursor-pointer text-right hover:text-green-400`}
              onClick={() => handleActionClick(action)}

            >

              {action.label}
            </button>
          ))
          }</div>
      </div >
    );
  }

  const _renderTable = () => {
    return (
      <Fragment>
        <div className="align-middle inline-block min-w-full">
          {state.loading ? (
            <div className="p-2">Loading...</div>
          ) : (
            <table
              style={{
                color: props.tableCSS?.color,
              }}
              className="w-full max-w-full divide-y-2 divide-gray-50 bg-white z-49"
            //"min-w-full divide-y divide-gray-200 sticky z-1 top-0"
            >
              <thead
                style={{
                  backgroundColor: props.tableCSS?.headerColor,
                  color: props.tableCSS?.color,
                }}
                className="sticky top-0 min-w-full mx-auto z-49"
              >
                <tr>
                  {columns.map((column, k) => (
                    <th
                      key={k}
                      scope="col"
                      className="ml-3 overflow-hidden truncate px-3 py-3 text-left text-sm text-ai-primary-text-dark font-semibold capitalize tracking-wider"
                    >
                      <span
                        className={
                          column.align === "left"
                            ? "flex justify-start cursor-pointer"
                            : "flex justify-center cursor-pointer"
                        }
                        onClick={() => sortBy(column)}
                      >
                        {column.value}
                        {column === state.selectedColumn && (
                          <IoIosArrowRoundUp
                            className={
                              state.ascending
                                ? "text-lg transform duration-200 ml-2"
                                : "text-lg transform rotate-180 duration-200 ml-2"
                            }
                          />
                        )}
                      </span>
                    </th>
                  ))}

                  {props.actions.length > 0 && (
                    <th
                      scope="col"
                      className="ml-3 overflow-hidden truncate px-3 py-3 text-left text-sm text-ai-primary-text-dark font-semibold capitalize tracking-wider"
                    >
                      {/* Actions */}
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white overflow-hidden divide-y divide-gray-200">
                {data
                  .slice(
                    props.paginationLimit
                      ? props.paginationLimit * state.page
                      : 0,
                    props.paginationLimit
                      ? props.paginationLimit * (state.page + 1)
                      : data.length
                  )
                  .map((row, k) => (
                    <tr key={k}>
                      {/* {console.log("This is K", k)} */}

                      {columns.map((column, l) => (
                        <td
                          key={l}
                          className={
                            row[column.value].align === "left"
                              ? "px-4 mx-3 max-w-sm overflow-hidden py-4 text-left whitespace-nowrap text-sm font-semibold text-black"
                              : "px-4 mx-3 max-w-sm overflow-hidden py-4 text-center whitespace-nowrap text-sm font-semibold text-black"
                          }
                          title={row[column.value].value}
                        >
                          {row[column.value].type === "text"
                            ? row[column.value].value
                            : new Intl.NumberFormat().format(
                              row[column.value].value
                            )}
                        </td>
                      ))}
                      {props.actions.length > 0 && (

                        <td className="p-4 whitespace-nowrap text-center text-sm font-semibold z-45">
                          <button
                            onClick={() => {
                              setState({
                                ...state,
                                dropdown: !state.dropdown,
                                selected: row.Connection_id.value,
                              });
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (
                                  state.dropdown &&
                                  state.selected === row.Connection_id.value
                                ) {
                                  setState({
                                    ...state,
                                    dropdown: false,
                                    selected: null,
                                  });
                                }
                              }, 300);
                            }}

                          >
                            <BsThreeDotsVertical className="w-6 h-6 text-gray-600 cursor-pointer z-0" />
                          </button>

                          {state.dropdown &&
                            state.selected === row.Connection_id.value &&
                            _renderActionItem(row, k)}
                        </td>
                        // <td className="px-6 flex py-4 whitespace-nowrap justify-center text-sm font-medium">
                        //   {props.actions.map((action) => (
                        //     <div
                        //       onClick={() => action.action(row, k)}
                        //       className={
                        //         "p-1 text-md rounded-md ml-3 cursor-pointer " +
                        //         action.style
                        //       }
                        //     >
                        //       {console.log("This is ROW", JSON.stringify(row))}
                        //       {action.label}
                        //     </div>
                        //   ))}
                        // </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        </div>
        {props.paginationLimit && (
          <div class="bg-white pl-4 py-3 flex items-center justify-between border-t border-gray-200 z-1">
            <div class="flex-1 flex justify-between sm:hidden z-1">
              <a
                href="#next"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                href="#next"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between z-1">
              <div>
                <p class="text-sm text-gray-700">
                  Showing
                  <span class="font-medium">
                    {" "}
                    {state.page * props.paginationLimit}{" "}
                  </span>
                  to{" "}
                  <span class="font-medium">
                    {" "}
                    {(state.page + 1) * props.paginationLimit}{" "}
                  </span>
                  of <span class="font-medium">{data.length} </span>
                  results
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px z-10"
                  aria-label="Pagination"
                >
                  <a
                    href="#next"
                    onClick={() => {
                      if (state.page > 0)
                        setState({ ...state, page: state.page - 1 });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span class="sr-only">Previous</span>
                    {/* <!-- Heroicon name: solid/chevron-left --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  {/* <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" --> */}

                  <a
                    href="#next"
                    onClick={() => {
                      if (state.page < data.length / props.paginationLimit)
                        setState({ ...state, page: state.page + 1 });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    {/* <!-- Heroicon name: solid/chevron-right --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
  const _renderImportsTable = () => {
    return (
      <Fragment>
        <div className="align-middle inline-block min-w-full">
          {state.loading ? (
            <div className="p-2">Loading...</div>
          ) : (
            <table
              style={{
                color: props.tableCSS?.color,
              }}
              className="w-full max-w-full divide-y-2 divide-gray-50 bg-white z-49 "
            //"min-w-full divide-y divide-gray-200 sticky z-1 top-0"
            >
              <thead
                style={{
                  backgroundColor: props.tableCSS?.headerColor,
                  color: props.tableCSS?.color,
                }}
                className="sticky top-0 min-w-full mx-auto z-49"
              >
                <tr>
                  {columns.map((column, k) => (
                    <th
                      key={k}
                      scope="col"
                      className="ml-3 overflow-hidden truncate px-3 py-3 text-left text-sm text-ai-primary-text-dark font-semibold capitalize tracking-wider"
                    >
                      <span
                        className={
                          column.align === "left"
                            ? "flex justify-start cursor-pointer"
                            : "flex justify-center cursor-pointer"
                        }
                        onClick={() => sortBy(column)}
                      >
                        {column.value}
                        {column === state.selectedColumn && (
                          <IoIosArrowRoundUp
                            className={
                              state.ascending
                                ? "text-lg transform duration-200 ml-2"
                                : "text-lg transform rotate-180 duration-200 ml-2"
                            }
                          />
                        )}
                      </span>
                    </th>
                  ))}

                  {props.actions.length > 0 && (
                    <th
                      scope="col"
                      className="ml-3 overflow-hidden truncate px-3 py-3 text-left text-sm text-ai-primary-text-dark font-semibold capitalize tracking-wider"
                    >
                      Actions
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white overflow-hidden divide-y divide-gray-200">
                {data
                  .slice(
                    props.paginationLimit
                      ? props.paginationLimit * state.page
                      : 0,
                    props.paginationLimit
                      ? props.paginationLimit * (state.page + 1)
                      : data.length
                  )
                  .map((row, k) => (
                    <tr key={k}>
                      {/* {console.log("This is K", row, k)} */}

                      {columns.map((column, l) => (
                        <td
                          key={l}
                          className={
                            row[column.value].align === "left"
                              ? "px-4 mx-3 max-w-sm overflow-hidden py-4 text-left whitespace-nowrap text-sm font-semibold text-black"
                              : "px-4 mx-3 max-w-sm overflow-hidden py-4 text-center whitespace-nowrap text-sm font-semibold text-black"
                          }
                          title={row[column.value].value}
                        >
                          {/* {console.log("RowStatus", row["Status"].value)} */}
                          {/* {console.log("COlumn", JSON.stringify(columns))} */}
                          {row[column.value].type === "text"
                            ? row[column.value].value
                            : row[column.value].type === "status" ?
                              <Toggle
                                key={k}
                                name="Status"
                                id={"active" + k}
                                value={row["Status"].value === "Active" ? true : false}
                                onChange={(e) => {
                                  //console.log("uWu", row["Import_id"].value);
                                  props.onUserStatusChange(e, row["Import_id"].value);
                                }}

                              // console.log("onUserStatusChange", data);
                              /> :
                              new Intl.NumberFormat().format(
                                row[column.value].value
                              )}
                          {/* onUserStatusChange(e, k)  */}
                        </td>
                      ))}
                      {props.actions.length > 0 && (

                        <td className="p-4 whitespace-nowrap text-center text-sm font-semibold z-45">
                          <button
                            onClick={() => {
                              setState({
                                ...state,
                                dropdown: !state.dropdown,
                                selected: row.Import_id.value,
                              });
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                if (
                                  state.dropdown &&
                                  state.selected === row.Import_id.value
                                ) {
                                  setState({
                                    ...state,
                                    dropdown: false,
                                    selected: null,
                                  });
                                }
                              }, 300);
                            }}

                          >
                            <BsThreeDotsVertical className="w-6 h-6 text-gray-600 cursor-pointer z-0" />
                          </button>
                          <input
                            name={"file_upload_" + k}
                            id={"file_upload_" + k}
                            onChange={(e) => {
                              // console.log('Printing file here===>')
                              // console.log("File", e.target.files[0]);
                              props.onUploadFile(e, row);
                            }}
                            type="file"
                            accept=".json"
                            className="sr-only"
                            title="Upload a File"
                          />

                          {state.dropdown &&
                            state.selected === row.Import_id.value &&
                            _renderImportsActionItem(row, k)}
                        </td>
                        // <td className="px-6 flex py-4 whitespace-nowrap justify-center text-sm font-medium">
                        //   {props.actions.map((action) => (
                        //     <div
                        //       onClick={() => action.action(row, k)}
                        //       className={
                        //         "p-1 text-md rounded-md ml-3 cursor-pointer " +
                        //         action.style
                        //       }
                        //     >
                        //       {console.log("This is ROW", JSON.stringify(row))}
                        //       {action.label}
                        //     </div>
                        //   ))}
                        // </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        </div>
        {props.paginationLimit && (
          <div class="bg-white pl-4 py-3 flex items-center justify-between border-t border-gray-200 z-1">
            <div class="flex-1 flex justify-between sm:hidden z-1">
              <a
                href="#next"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                href="#next"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between z-1">
              <div>
                <p class="text-sm text-gray-700">
                  Showing
                  <span class="font-medium">
                    {" "}
                    {state.page * props.paginationLimit}{" "}
                  </span>
                  to{" "}
                  <span class="font-medium">
                    {" "}
                    {(state.page + 1) * props.paginationLimit}{" "}
                  </span>
                  of <span class="font-medium">{data.length} </span>
                  results
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px z-10"
                  aria-label="Pagination"
                >
                  <a
                    href="#next"
                    onClick={() => {
                      if (state.page > 0)
                        setState({ ...state, page: state.page - 1 });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span class="sr-only">Previous</span>
                    {/* <!-- Heroicon name: solid/chevron-left --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  {/* <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" --> */}

                  <a
                    href="#next"
                    onClick={() => {
                      if (state.page < data.length / props.paginationLimit)
                        setState({ ...state, page: state.page + 1 });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    {/* <!-- Heroicon name: solid/chevron-right --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
  const _renderTenantsTable = () => {
    return (
      <Fragment>
        <div className="align-middle inline-block min-w-full">
          {state.loading ? (
            <div className="p-2">Loading...</div>
          ) : (
            <table
              style={{
                color: props.tableCSS?.color,
              }}
              className="w-full max-w-full divide-y-2 divide-gray-50 bg-white z-49 "
            //"min-w-full divide-y divide-gray-200 sticky z-1 top-0"
            >
              <thead
                style={{
                  backgroundColor: props.tableCSS?.headerColor,
                  color: props.tableCSS?.color,
                }}
                className="sticky top-0 min-w-full mx-auto z-49"
              >
                <tr>
                  {columns.map((column, k) => (
                    <th
                      key={k}
                      scope="col"
                      className="ml-3 overflow-hidden truncate px-3 py-3 text-left text-sm text-ai-primary-text-dark font-semibold capitalize tracking-wider"
                    >
                      <span
                        className={
                          column.align === "left"
                            ? "flex justify-start cursor-pointer"
                            : "flex justify-center cursor-pointer"
                        }
                        onClick={() => sortBy(column)}
                      >
                        {column.value}
                        {column === state.selectedColumn && (
                          <IoIosArrowRoundUp
                            className={
                              state.ascending
                                ? "text-lg transform duration-200 ml-2"
                                : "text-lg transform rotate-180 duration-200 ml-2"
                            }
                          />
                        )}
                      </span>
                    </th>
                  ))}

                  {props.actions.length > 0 && (
                    <th
                      scope="col"
                      className="ml-3 overflow-hidden truncate px-3 py-3 text-left text-sm text-ai-primary-text-dark font-semibold capitalize tracking-wider"
                    >

                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="bg-white overflow-hidden divide-y divide-gray-200">
                {data
                  .slice(
                    props.paginationLimit
                      ? props.paginationLimit * state.page
                      : 0,
                    props.paginationLimit
                      ? props.paginationLimit * (state.page + 1)
                      : data.length
                  )
                  .map((row, k) => (
                    <tr key={k}>
                      {/* {console.log("This is K", row, k)} */}

                      {columns.map((column, l) => (
                        <td
                          key={l}
                          className={
                            row[column.value].align === "left"
                              ? "px-4 mx-3 max-w-sm overflow-hidden py-4 text-left whitespace-nowrap text-sm font-semibold text-black"
                              : "px-4 mx-3 max-w-sm overflow-hidden py-4 text-center whitespace-nowrap text-sm font-semibold text-black"
                          }
                          title={row[column.value].value}
                        >
                          {/* {console.log("RowStatus", row["Status"].value)} */}
                          {/* {console.log("COlumn", JSON.stringify(columns))} */}
                          {row[column.value].type === "text"
                            ? row[column.value].value
                            : row[column.value].type === "status" ?
                              <Toggle
                                key={k}
                                name="Status"
                                id={"active" + k}
                                value={row["Status"].value === "Active" ? true : false}
                                onChange={(e) => {
                                  //console.log("uWu", row["Import_id"].value);
                                  props.onUserStatusChange(e, row["Import_id"].value);
                                }}

                              // console.log("onUserStatusChange", data);
                              /> :
                              new Intl.NumberFormat().format(
                                row[column.value].value
                              )}
                          {/* onUserStatusChange(e, k)  */}
                        </td>
                      ))}
                      {props.actions.length > 0 && (

                        <td className="p-4 whitespace-nowrap text-center text-sm font-semibold z-45">
                          {/* {console.log("AnotherRow", row)} */}

                          {props.actions.map((action, i) => (

                            < button
                              key={i}
                              className={`p-2 cursor-pointer text-right text-red-600 hover:text-red-400`}
                              onClick={() => { action.action(row, k) }}

                            >

                              {action.label}
                            </button>
                          ))
                          }
                          {/* {state.dropdown &&
                            state.selected === row["Tenant ID"].value &&
                            _renderTenantsActionItem(row, k)} */}
                        </td>

                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        </div>
        {props.paginationLimit && (
          <div class="bg-white pl-4 py-3 flex items-center justify-between border-t border-gray-200 z-1">
            <div class="flex-1 flex justify-between sm:hidden z-1">
              <a
                href="#next"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                href="#next"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between z-1">
              <div>
                <p class="text-sm text-gray-700">
                  Showing
                  <span class="font-medium">
                    {" "}
                    {state.page * props.paginationLimit}{" "}
                  </span>
                  to{" "}
                  <span class="font-medium">
                    {" "}
                    {(state.page + 1) * props.paginationLimit}{" "}
                  </span>
                  of <span class="font-medium">{data.length} </span>
                  results
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px z-10"
                  aria-label="Pagination"
                >
                  <a
                    href="#next"
                    onClick={() => {
                      if (state.page > 0)
                        setState({ ...state, page: state.page - 1 });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span class="sr-only">Previous</span>
                    {/* <!-- Heroicon name: solid/chevron-left --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  {/* <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" --> */}

                  <a
                    href="#next"
                    onClick={() => {
                      if (state.page < data.length / props.paginationLimit)
                        setState({ ...state, page: state.page + 1 });
                    }}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Next</span>
                    {/* <!-- Heroicon name: solid/chevron-right --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }



  //MAIN RETURN STATEMENT
  return (
    <div className="">
      <div className="align-middle overflow-y-auto inline-block w-full max-w-full">
        {/* {_renderTable()} */}

        {window.location.pathname === "/dashboard/imports" ? _renderImportsTable() : window.location.pathname === "/dashboard/super/users" ? _renderTenantsTable() : _renderTable()}
        {/* {state.loading ? <div className="p-2">Loading...</div> : _renderTable()} */}
      </div>
      {/* <div className="border-t"> {props.children}</div> */}
      {/* {console.log("Props", props.children)} */}
    </div>
  );
}
